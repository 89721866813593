import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ButtonsLayout from "../ButtonsLayout";
import { API } from "../../shared/utility";
import Spinner from "../Spinner";
import { sammittiArray } from "../../shared/data";
import { errorToast } from "../../shared/Service";
// import YearPicker from "react-year-picker";

const SewaDetails = ({ currentTab, changeTab, setShowSuccessModal }) => {
  var today = new Date();
  var dd = today.getDate();
  var mm = today.getMonth() + 1; //January is 0!
  var yyyy = today.getFullYear();
  const [data, setData] = useState({
    isPakkiSamitiMember: false,
    pakkiSamiti: "",
    sewadarSince: "",
    skills: "",
    sewaAvailability: "",
  });

  const [isLoading, setIsLoading] = useState(false);
  const [updateData, setUpdateData] = useState({});
  const [isNewRecord, setIsNewRecord] = useState(true);

  const saveButtonDisabled = () => {
    if (
      (data.isPakkiSamitiMember && !data.pakkiSamiti && !data.sewadarSince) ||
      !data.skills ||
      !data.sewaAvailability
    ) {
      return true;
    }

    return false;
  };

  const GET_DATA_FROM_DB = async () => {
    setIsLoading(true);
    API.GET_WELFARE_DETAIL()
      .then((res) => {
        if (res.status == 200) {
          res.data.data.length === 0
            ? setIsNewRecord(true)
            : setIsNewRecord(false);
          const {
            isPakkiSamitiMember,
            pakkiSamiti,
            sewadarSince,
            skills,
            sewaAvailability,
          } = res.data.data;

          const resData = {
            isPakkiSamitiMember: isPakkiSamitiMember,
            pakkiSamiti: pakkiSamiti,
            sewadarSince: sewadarSince?.slice(0, 4),
            skills: skills,
            sewaAvailability: sewaAvailability,
          };

        
          setData(resData);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
        if (!isNewRecord) {
          errorToast(err.response.data.message);
        }
        // console.log(err ? err.response.data.message : err.message);
      });
  };
  const handleInputChange = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  };
  const HandleUpdate = (event) => {
    if (event.target.value !== "") {
      setUpdateData({ ...updateData, [event.target.name]: event.target.value });
    } else {
      delete updateData[event.target.name];
    }
    handleInputChange(event);
  };

  useEffect(() => {
    !data?.skills && GET_DATA_FROM_DB();
  }, []);
  // useEffect(() => {
  // 	if (currentTab === 3) GET_DATA_FROM_DB();
  // }, [currentTab]);

  return (
    <div className={`${currentTab === 3 ? "block" : "hidden"}`}>
      {isLoading ? (
        <div className="w-full h-[50vh] flex items-center justify-center bg-white  rounded-lg">
          <Spinner />
        </div>
      ) : (
        <>
          <div className={`w-full flex-col bg-white  flex rounded-lg`}>
            {/* Sewa Details*/}
            <div className="flex flex-col p-3 sm:p-5 shrink h-fit md:flex-1">
              <h1 className="my-3 text-xl sm:my-4 sm:text-2xl text-gray-700">
                Sewa Details
              </h1>
              <form action="#" className="flex flex-col space-y-5">
                {/* Are you already a member of any pakki sammitti/sewa group? */}
                <div className="flex flex-col space-y-1">
                  <label
                    htmlFor="sewa-memeber"
                    className="text-sm capitalize   required"
                  >
                    Are you already a member of any pakki sammitti/sewa group?
                  </label>
                  <div className="flex flex-col gap-2">
                    <span>
                      <input
                        type="radio"
                        name="isPakkiSamitiMember"
                        value="yes"
                        checked={data.isPakkiSamitiMember}
                        onChange={() => {
                          isNewRecord
                            ? setData({
                                ...data,
                                isPakkiSamitiMember: true,
                              })
                            : setUpdateData({
                                ...updateData,
                                isPakkiSamitiMember: true,
                              });
                          setData({
                            ...data,
                            isPakkiSamitiMember: true,
                          });
                        }}
                        required
                        className="px-4 py-2 mr-2 transition duration-300 border border-gray-300 rounded w-fit focus:border-transparent focus:outline-none focus:ring-blue-200"
                      />
                      <label className="text-sm capitalize">Yes</label>
                    </span>
                    <span>
                      <input
                        type="radio"
                        name="isPakkiSamitiMember"
                        value="no"
                        checked={!data.isPakkiSamitiMember}
                        onChange={() => {
                          isNewRecord
                            ? setData({
                                ...data,
                                isPakkiSamitiMember: false,
                                pakkiSamiti: null,
                                sewadarSince: null,
                              })
                            : setUpdateData({
                                ...updateData,
                                isPakkiSamitiMember: false,
                                pakkiSamiti: null,
                                sewadarSince: null,
                              });
                          setData({
                            ...data,
                            isPakkiSamitiMember: false,
                            pakkiSamiti: null,
                            sewadarSince: null,
                          });
                        }}
                        required
                        className="px-4 py-2 mr-2 transition duration-300 border border-gray-300 rounded w-fit focus:border-transparent focus:outline-none focus:ring-blue-200"
                      />
                      <label className="text-sm capitalize   ">No</label>
                    </span>
                  </div>
                </div>
                {/* If you already the member of Pakki Sammitti, Please specify the name of Sammitti. */}
                {data.isPakkiSamitiMember && (
                  <div className={`flex flex-col space-y-1`}>
                    <div className="w-full flex flex-col space-y-1 md:p-1 mb-0">
                      <label
                        htmlFor="pakki-sammitti-name"
                        className="text-sm capitalize   required"
                      >
                        If you already the member of Pakki Sammitti, Please
                        specify the name of Sammitti.
                      </label>
                      <select
                        className="text-black px-4 w-full py-2 border-gray-300 border rounded-md bg-transparent outline-none"
                        name="pakkiSamiti"
                        value={data.pakkiSamiti}
                        onChange={
                          isNewRecord ? handleInputChange : HandleUpdate
                        }
                      >
                        <option value="" defaultValue hidden>
                          Sammitti Name
                        </option>
                        {sammittiArray.sort().map((samiti) => {
                          return (
                            <option value={samiti.name} key={samiti.id}>
                              {samiti.name}
                            </option>
                          );
                        })}
                        {/* <option value="government">Government</option>
                        <option value="entrepreneur">Entrepreneur</option> */}
                      </select>
                    </div>
                  </div>
                )}

                {data.isPakkiSamitiMember && (
                  <div className="flex w-full flex-col md:p-1 mb-4 md:mb-0">
                    <label
                      htmlFor="sewadarSince"
                      className="text-sm capitalize text-gray-500   required"
                    >
                      Sewadar Since When (Year only)
                    </label>

                    <input
                      type="text"
                      name="sewadarSince"
                      maxLength={4}
                      value={data.sewadarSince}
                      // onKeyDown={(e) => {
                      // 	var key = e.key;

                      // 	console.log(key, 'sdsd');
                      // 	if (data.sewadarSince.length == 4 &&  !== 8 && e.keyCode !== 46) {
                      // 		return;
                      // 	}

                      // 	isNewRecord ? handleInputChange(e) : HandleUpdate(e);
                      // }}
                      onChange={(e) => {
                        if (e.target.value * 1 > 2022) {
                          if (!isNewRecord) {
                            setUpdateData({
                              ...updateData,
                              sewadarSince: "2022",
                            });
                          }
                          setData({ ...data, sewadarSince: "2022" });
                        } else if (!isNewRecord) {
                          setUpdateData({
                            ...updateData,
                            sewadarSince: e.target.value,
                          });
                          setData({ ...data, sewadarSince: e.target.value });
                        } else {
                          setData({ ...data, sewadarSince: e.target.value });
                        }
                      }}
                      className="w-full peer px-3 sm:px-4 py-2 transition duration-300 border border-gray-300 rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-gray-300 bg-transparent"
                    />
                    <p className="mt-2 hidden peer-invalid:block text-red-500 text-sm capitalize">
                      max year should be {yyyy}
                    </p>
                  </div>
                )}

                {/* Skills */}
                <div className="flex flex-col space-y-1">
                  <label
                    htmlFor="skills"
                    className="text-sm capitalize  required"
                  >
                    Skills
                  </label>
                  <input
                    type="text"
                    id="skills"
                    name="skills"
                    value={data.skills}
                    onChange={isNewRecord ? handleInputChange : HandleUpdate}
                    required
                    className="px-4 py-2 transition duration-300 border border-gray-300 rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-blue-200"
                  />
                </div>

                {/*  Sewa Availability  */}
                <div className="flex flex-col space-y-1">
                  <label
                    htmlFor="sewaAvailability"
                    className="text-sm capitalize  required"
                  >
                    Sewa Availability (days in Month)
                  </label>
                  <input
                    type="number"
                    min="1"
                    max="32"
                    id="sewaAvailability"
                    name="sewaAvailability"
                    value={data.sewaAvailability}
                    onChange={(e) => {
                      if (e.target.value >= 0) {
                        isNewRecord ? handleInputChange(e) : HandleUpdate(e);
                      }
                    }}
                    required
                    className="px-4 py-2 transition duration-300 border border-gray-300 rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-blue-200"
                  />
                </div>
              </form>
            </div>
          </div>
          <div className="my-5 py-1  bg-white  rounded-lg">
            <ButtonsLayout
              currentTab={currentTab}
              changeTab={changeTab}
              data={data}
              saveButtonDisabled={saveButtonDisabled}
              showSaveButton={isNewRecord}
              updateData={updateData}
              setShowSuccessModal={setShowSuccessModal}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default SewaDetails;

{
  /* Any other information you want to share! (For eg. Achievements, Research experience, Sports or other awards) */
}
// <div className='flex flex-col space-y-1'>
// 	<label htmlFor='another-info' className='text-sm capitalize   required'>
// 		Any other information you want to share! (For eg. Achievements, Research experience, Sports or other awards)
// 	</label>
// 	<input
// 		type='text'
// 		name='otherInformation'
// 		value={data.otherInformation}
// 		onChange={isNewRecord ? handleInputChange : HandleUpdate}
// 		required
// 		className='px-4 py-2 transition duration-300 border border-gray-300 rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-blue-200'
// 	/>
// </div>;
{
  /* Link to your CV/Resume/Portfolio etc. (only if you wish to. You can upload on Google Drive and share the link!) */
}
// <div className='flex flex-col space-y-1'>
// 	<label htmlFor='resume-link-label' className='text-sm capitalize  '>
// 		Link to your CV/Resume/Portfolio etc. (only if you wish to. You can upload on Google Drive and share the link!)
// 	</label>
// 	<input
// 		type='text'
// 		name='resumeLink'
// 		value={data.resumeLink}
// 		onChange={isNewRecord ? handleInputChange : HandleUpdate}
// 		className='px-4 py-2 transition duration-300 border border-gray-300 rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-blue-200'
// 	/>
// </div>;
{
  /* Suggestions for Dera/Sewa (that could be implemented) */
}
// <div className='flex flex-col space-y-1'>
// 	<div className='flex items-center justify-between'>
// 		<label htmlFor='suggestion' className='text-sm capitalize   required'>
// 			Suggestions for Dera/Sewa (that could be implemented)
// 		</label>
// 	</div>
// 	<input
// 		type='text'
// 		name='suggestion'
// 		value={data.suggestion}
// 		onChange={isNewRecord ? handleInputChange : HandleUpdate}
// 		required
// 		className='px-4 py-2 transition duration-300 border border-gray-300 rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-blue-200'
// 	/>
// </div>;
