import React, { useEffect, useState } from "react";
import ButtonsLayout from "../ButtonsLayout";
import Spinner from "../Spinner";

import { API } from "../../shared/utility";
import { successToast } from "../../shared/Service";
import { errorToast } from "../../shared/Service";
import axios from "axios";
import { envConfig } from "../../shared/config";

const COMMON_URL = envConfig.COMMON_URL;

const PersonalDetails = ({ currentTab, changeTab, setShowSuccessModal }) => {
  const [countryCode, setCountryCode] = useState("91");
  const [data, setData] = useState({
    name: "",
    email: "",
    maritalStatus: true,
    gender: "male",
    callingPhoneNo: "",
    whatsappPhoneNo: "",
    currentCountry: "",
    currentState: "",
    currentCity: "",
    currentAddress: "",
    permanentAddress: "",
    permanentCountry: "",
    permanentState: "",
    permanentCity: "",
    dob: "",
    fatherName: "",
    adhaarNo: "",
    image: null,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [updateData, setUpdateData] = useState({});
  const [isNewRecord, setIsNewRecord] = useState(true);
  const [countries, setCountries] = useState([]);
  const [currentStates, setCurrentStates] = useState([]);
  const [currentCities, setCurrentCities] = useState([]);
  const [currentCountryCode, setCurrentCountryCode] = useState("");
  const [permanentStates, setPermanentStates] = useState([]);
  const [permanentCities, setPermanentCities] = useState([]);
  const [permanentCountryCode, setPermanentCountryCode] = useState("");
  const [
    isCurrentAndPermanentAddressSame,
    setIsCurrentAndPermanentAddressSame,
  ] = useState(false);

  const handleInputChange = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  };
  const HandleUpdate = (event) => {
    console.log("handle update ");
    console.log(updateData);
    if (event.target.value !== "") {
      setUpdateData({ ...updateData, [event.target.name]: event.target.value });
    } else {
      delete updateData[event.target.name];
    }
    console.log("updateData ", updateData);
    handleInputChange(event);
  };

  const GetPersonalDetail = async () => {
    setIsLoading(true);

    const userDetail = JSON.parse(localStorage.getItem("userDetails"));
    console.log("userDetail :- ", userDetail?.user?.phoneNo);
    API.GET_PERSONAL_DETAIL()
      .then((res) => {
        res.data.data.length === 0
          ? setIsNewRecord(true)
          : setIsNewRecord(false);
        const {
          name,
          email,
          maritalStatus,
          gender,
          callingPhoneNo,
          whatsappPhoneNo,
          currentCountry,
          currentState,
          currentCity,
          currentAddress,
          permanentAddress,
          permanentCountry,
          permanentState,
          permanentCity,
          dob,
          fatherName,
          adhaarNo,
          imageUrl,
        } = res.data.data;

        console.log(res);

        const personalDetails = {
          name,
          email,
          maritalStatus,
          gender,
          callingPhoneNo:
            userDetail && res.data.data.length === 0
              ? userDetail?.user?.phoneNo
              : callingPhoneNo,
          whatsappPhoneNo: userDetail?.user?.phoneNo,
          currentCountry,
          currentState,
          currentCity,
          currentAddress,
          permanentAddress,
          permanentCountry,
          permanentState,
          permanentCity,
          fatherName,
          adhaarNo,
          dob: dob.slice(0, 10),
          image: imageUrl,
        };
        setData(personalDetails);

        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        if (isNewRecord) {
          setData({ ...data, whatsappPhoneNo: userDetail?.user?.phoneNo });
        }
        if (!isNewRecord) {
          errorToast(err.response.data.message);
        }
      });
  };

  const getCountries = () => {
    axios
      .get(COMMON_URL + `/countries`)
      .then((res) => {
        setCountries(res.data.data);
      })
      .catch((e) => {
        console.log(e.response.data.message);
      });
  };
  const getCurrentStates = (CountryCode) => {
    axios
      .get(COMMON_URL + `/states/${CountryCode}`)
      .then((res) => {
        setCurrentStates(res.data.data);
      })
      .catch((e) => {
        console.log(e.response.data.message);
      });
  };
  const getCurrentCities = (CountryCode, stateCode) => {
    axios
      .get(COMMON_URL + `/cities/${CountryCode}/${stateCode}`)
      .then((res) => {
        setCurrentCities(res.data.data);
      })
      .catch((e) => {
        console.log(e.response.data.message);
      });
  };
  const getPermanentStates = (CountryCode) => {
    axios
      .get(COMMON_URL + `/states/${CountryCode}`)
      .then((res) => {
        setPermanentStates(res.data.data);
      })
      .catch((e) => {
        console.log(e.response.data.message);
      });
  };
  const getPermanentCities = (CountryCode, stateCode) => {
    axios
      .get(COMMON_URL + `/cities/${CountryCode}/${stateCode}`)
      .then((res) => {
        setPermanentCities(res.data.data);
      })
      .catch((e) => {
        console.log(e.response.data.message);
      });
  };

  useEffect(() => {
    GetPersonalDetail();
    getCountries();
  }, []);

  return (
    <div className={`${currentTab === 0 ? "block" : "hidden"}`}>
      {isLoading ? (
        <div className="w-full h-[50vh] flex items-center justify-center  bg-white  rounded-lg">
          <Spinner />
        </div>
      ) : (
        <>
          <div className="w-full flex-col px-3 py-5 sm:p-5 bg-white  rounded-lg flex ">
            <div className="my-3 text-xs text-black bg-red-200 p-2 rounded-md">
              <span className="font-medium mr-2">Note:</span>
              The details entered will be verified internally and only verified
              super-student will be allowed to attend "Loving Child Father's
              Day"
            </div>

            <form
              action="#"
              className="flex flex-col md:grid md:grid-cols-2 md:gap-y-6 md:gap-x-2 md:items-center"
            >
              {/* Name */}
              <div className="flex flex-col md:p-1 mb-4 md:mb-0">
                <label
                  htmlFor="name"
                  className="text-sm capitalize text-black   required"
                >
                  Name
                </label>
                <input
                  type="text"
                  maxLength={20}
                  name="name"
                  pattern="[A-Za-z\\s]*"
                  value={data.name}
                  onChange={(e) => {
                    let value = e.target.value;
                    // value = value.replace(/[^A-Za-z]/gi, "");

                    if (!isNewRecord) {
                      setUpdateData({ ...updateData, name: value });
                    }
                    setData({ ...data, name: value });
                  }}
                  className="px-3 sm:px-4 py-2 transition duration-300 border border-gray-300 rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-gray-300"
                />
                <p className="mt-2 hidden peer-invalid:block text-red-500 text-sm capitalize">
                  please enter valid input
                </p>
              </div>
              {/* Father Name */}
              <div className="flex flex-col md:p-1 mb-4 md:mb-0">
                <label
                  htmlFor="fatherName"
                  className="text-sm capitalize text-black   required"
                >
                  Father Name
                </label>
                <input
                  type="text"
                  name="fatherName"
                  maxLength={20}
                  value={data.fatherName}
                  onChange={(e) => {
                    let value = e.target.value;
                    // value = value.replace(/[^A-Za-z]/gi, "");

                    if (!isNewRecord) {
                      setUpdateData({ ...updateData, fatherName: value });
                    }
                    setData({ ...data, fatherName: value });
                  }}
                  className="px-3 sm:px-4 py-2 transition duration-300 border border-gray-300 rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-gray-300"
                />
                <p className="mt-2 hidden peer-invalid:block text-red-500 text-sm capitalize">
                  please enter valid input
                </p>
              </div>
              {/* Age */}
              <div className="flex flex-col md:p-1 mb-4 md:mb-0">
                <label
                  htmlFor="dob"
                  className="text-sm capitalize text-black   required"
                >
                  Date of birth
                </label>

                <input
                  type="date"
                  name="dob"
                  value={data.dob}
                  min={
                    new Date(new Date("1947-01-01")).toISOString().split("T")[0]
                  }
                  max={
                    new Date(
                      new Date().setFullYear(new Date().getFullYear() - 5)
                    )
                      .toISOString()
                      .split("T")[0]
                  }
                  onChange={isNewRecord ? handleInputChange : HandleUpdate}
                  className="w-full px-3 sm:px-4 py-2 transition duration-300 border border-gray-300 rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-gray-300 bg-transparent"
                />
                <p className="mt-2 hidden peer-invalid:block text-red-500 text-sm capitalize">
                  please enter valid input
                </p>
              </div>
              {/* Gender And MArtial Status */}
              <span className="md:flex justify-around">
                {/* Gender */}
                <div className="flex w-full flex-col md:p-1 mb-4 md:mb-0">
                  <label
                    htmlFor="gender"
                    className="text-sm capitalize text-black   required"
                  >
                    Gender
                  </label>
                  <select
                    className="text-black px-3 sm:px-4 py-2 border-gray-300 border rounded-md bg-transparent focus:outline-none focus:ring-4 focus:ring-gray-300 bg-transparent"
                    name="gender"
                    value={data.gender}
                    onChange={isNewRecord ? handleInputChange : HandleUpdate}
                  >
                    <option value="male" defaultChecked>
                      Male
                    </option>
                    <option value="female">Female</option>
                    <option value="other">Other</option>
                  </select>
                  <p className="mt-2 hidden peer-invalid:block text-red-500 text-sm capitalize">
                    please enter valid input
                  </p>
                </div>
                <div className="flex w-full flex-col md:p-1 mb-4 md:mb-0">
                  <div className="flex items-center justify-between">
                    <label
                      htmlFor="marital-status"
                      className="text-sm capitalize text-black   required"
                    >
                      Marital Status
                    </label>
                  </div>
                  <select
                    className="text-black border-gray-300 border px-3 sm:px-4 py-2 rounded-md bg-transparent focus:outline-none focus:ring-4 focus:ring-gray-300 bg-transparent"
                    name="maritalStatus"
                    value={data.maritalStatus}
                    onChange={isNewRecord ? handleInputChange : HandleUpdate}
                  >
                    <option value={true} defaultChecked>
                      Married
                    </option>
                    <option value={false}>Unmarried</option>
                  </select>
                  <p className="mt-2 hidden peer-invalid:block text-red-500 text-sm capitalize">
                    please enter valid input
                  </p>
                </div>
              </span>
              {/* Phone Number Calling */}
              <div className="flex flex-col md:p-1 mb-4 md:mb-0">
                <label
                  htmlFor="phone-call"
                  className="text-sm capitalize text-black   required"
                >
                  Phone Number ( Calling )
                </label>

                <div className="flex row transition duration-300 border  border-gray-300 rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-gray-200 ">
                  <span className="w-[20%] md:w-[15%] peer  border-r border-gray-300">
                    <select
                      className="w-full  sm:px-0 py-2    outline-none bg-transparent  "
                      name="contryCode"
                      onChange={(e) => {
                        const country = JSON.parse(e.target.value);
                        const contryCode =
                          country?.phonecode?.substring(0, 1) === "+"
                            ? country.phonecode.substring(
                                0,
                                country.phonecode.length
                              )
                            : country?.phonecode;

                        setCountryCode(contryCode);
                      }}
                    >
                      <option hidden defaultChecked>
                        +91
                      </option>
                      {countries.map((country) => {
                        return (
                          <option
                            key={country.code}
                            value={JSON.stringify(country)}
                            selected={country.phonecode === "91"}
                            className="text-sm  cursor-pointer"
                          >
                            {country?.phonecode?.substring(0, 1) === "+"
                              ? country?.phonecode
                              : "+" + country?.phonecode}
                          </option>
                        );
                      })}
                    </select>
                  </span>
                  <span className="w-[80%] md:w-[85%] peer-focus:ring-4 peer-focus:ring-gray-200 ">
                    <input
                      type="number"
                      name="callingPhoneNo"
                      value={data.callingPhoneNo}
                      onChange={(e) => {
                        var number = e.target.value;
                        var compare = /^[0-9]{1,10}$/g;
                        console.log(number.match(compare));

                        console.log(number.length);
                        if (number.match(compare)) {
                          if (!isNewRecord) {
                            setUpdateData({
                              ...updateData,
                              callingPhoneNo: number,
                            });
                          }
                          setData({ ...data, callingPhoneNo: number });
                        } else if (number.length <= 1) {
                          if (!isNewRecord) {
                            setUpdateData({
                              ...updateData,
                              callingPhoneNo: number,
                            });
                          }
                          setData({ ...data, callingPhoneNo: number });
                        }
                      }}
                      required
                      className="w-full px-3 sm:px-4 py-2  outline-none peer"
                    />
                  </span>
                </div>

                <p className="mt-2 hidden peer-invalid:block text-red-500 text-sm capitalize">
                  invalid phone number
                </p>
              </div>
              {/* Phone Number Whatsapp */}
              {/* <div className="flex flex-col md:p-1 mb-4 md:mb-0">
                <label
                  htmlFor="phone-whatsapp"
                  className="text-sm capitalize text-black required "
                >
                  Phone Number ( Whatsapp )
                </label>

                <input
                  type="number"
                  name="whatsappPhoneNo"
                  min={"0000000000"}
                  max={"9999999999"}
                  value={data.whatsappPhoneNo}
                  disabled
                  required
                  onChange={isNewRecord ? handleInputChange : HandleUpdate}
                  className="px-3 w-full text-gray-400 rounded-md border  sm:px-4 py-2 peer  "
                />

                <p className="mt-2 hidden peer-invalid:block text-red-500 text-sm capitalize">
                  invalid phone number
                </p>
              </div> */}
              {/* Email*/}
              <div className="flex flex-col md:p-1 mb-4 md:mb-0">
                <label
                  htmlFor="email"
                  className="text-sm capitalize text-black  required"
                >
                  Email address
                </label>
                <input
                  type="email"
                  name="email"
                  value={data.email}
                  onChange={isNewRecord ? handleInputChange : HandleUpdate}
                  required
                  className="px-3 peer sm:px-4 py-2 transition duration-300 border border-gray-300 rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-gray-300"
                />
                <p className="mt-2 hidden peer-invalid:block text-red-500 text-sm capitalize">
                  {data.email.length > 0 && "please enter valid input"}
                </p>
              </div>

              {/* Passport size image */}
              <div className="flex w-full flex-col md:p-1 mb-4 md:mb-0">
                <label
                  htmlFor="image"
                  className="text-sm capitalize  required sm:whitespace-nowrap"
                >
                  Passport size image ( Face should be clearly visible )
                </label>
                <input
                  type="file"
                  id="image"
                  name="image"
                  // value={data.image}
                  onChange={(e) => {
                    console.log(e.target.files[0].size);
                    if (e.target.files[0].size > 5 * 1024 * 1024) {
                      errorToast("Image size should be less than 5 MB!");
                      return;
                    }
                    if (!isNewRecord) {
                      setUpdateData({
                        ...updateData,
                        image: e.target.files[0],
                      });
                    }
                    setData({ ...data, image: e.target.files[0] });
                  }}
                  required
                  className="px-4 py-2 transition duration-300 border border-gray-300 rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-blue-200"
                />
              </div>

              {/* Current Address */}
              <div className="flex flex-col md:p-1 mb-4 md:mb-0">
                <div className="flex items-center justify-between">
                  <label
                    htmlFor="currentAddress"
                    className="text-sm capitalize text-black   required"
                  >
                    Current Address
                  </label>
                </div>
                <input
                  type="text"
                  name="currentAddress"
                  minLength={3}
                  value={data.currentAddress}
                  onChange={(e) => {
                    if (!isNewRecord) {
                      if (isCurrentAndPermanentAddressSame) {
                        setUpdateData({
                          ...updateData,
                          currentAddress: e.target.value,
                          permanentAddress: e.target.value,
                        });
                      } else {
                        setUpdateData({
                          ...updateData,
                          currentAddress: e.target.value,
                        });
                      }
                    }
                    if (isCurrentAndPermanentAddressSame) {
                      setData({
                        ...data,
                        currentAddress: e.target.value,
                        permanentAddress: e.target.value,
                      });
                    } else {
                      setData({
                        ...data,
                        currentAddress: e.target.value,
                      });
                    }
                  }}
                  required
                  className="px-3 sm:px-4 py-2 transition duration-300 border border-gray-300 rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-gray-300"
                />
                <p className="mt-2 hidden peer-invalid:block text-red-500 text-sm capitalize">
                  please enter valid input
                </p>
              </div>

              {/* Current Country */}
              <div className="flex w-full flex-col md:p-1 mb-4 md:mb-0">
                <div className="flex items-center justify-between">
                  <label
                    htmlFor="currentCountry"
                    className="text-sm capitalize text-black   required"
                  >
                    Current Country
                  </label>
                </div>
                <select
                  className="w-full text-black border-gray-300 border px-3 sm:px-4 py-2 rounded-md bg-transparent"
                  name="currentCountry"
                  onChange={(e) => {
                    const country = JSON.parse(e.target.value);
                    setCurrentCountryCode(country.isoCode);
                    getCurrentStates(country.isoCode);
                    if (!isNewRecord) {
                      if (isCurrentAndPermanentAddressSame) {
                        setUpdateData({
                          ...updateData,
                          currentCountry: country.countryName,
                          permanentCountry: country.countryName,
                        });
                      } else {
                        setUpdateData({
                          ...updateData,
                          currentCountry: country.countryName,
                        });
                      }
                    }
                    if (isCurrentAndPermanentAddressSame) {
                      setData({
                        ...data,
                        currentCountry: country.countryName,
                        permanentCountry: country.countryName,
                      });
                    } else {
                      setData({
                        ...data,
                        currentCountry: country.countryName,
                      });
                    }
                  }}
                >
                  <option className="text-sm capitalize text-black" hidden>
                    Select Country
                  </option>
                  {countries.map((country) => {
                    return (
                      <option
                        key={country.isoCode}
                        value={JSON.stringify(country)}
                        selected={data.currentCountry === country.countryName}
                        className="text-sm capitalize cursor-pointer"
                      >
                        {country.countryName}
                      </option>
                    );
                  })}
                </select>
                <p className="mt-2 hidden peer-invalid:block text-red-500 text-sm capitalize">
                  please enter valid input
                </p>
              </div>

              {/* Current State */}
              <div className="flex w-full flex-col md:p-1 mb-4 md:mb-0">
                <div className="flex items-center justify-between">
                  <label
                    htmlFor="currentState"
                    className="text-sm capitalize text-black   required"
                  >
                    Current State
                  </label>
                </div>
                <select
                  className="w-full text-black border-gray-300 border px-3 sm:px-4 py-2 rounded-md bg-transparent"
                  name="currentState"
                  onChange={(e) => {
                    const state = JSON.parse(e.target.value);

                    getCurrentCities(currentCountryCode, state.isoCode);
                    if (!isNewRecord) {
                      if (isCurrentAndPermanentAddressSame) {
                        setUpdateData({
                          ...updateData,
                          currentState: state.stateName,
                          permanentState: state.stateName,
                        });
                      } else {
                        setUpdateData({
                          ...updateData,
                          currentState: state.stateName,
                        });
                      }
                    }
                    if (isCurrentAndPermanentAddressSame) {
                      setData({
                        ...data,
                        currentState: state.stateName,
                        permanentState: state.stateName,
                      });
                    } else {
                      setData({
                        ...data,
                        currentState: state.stateName,
                      });
                    }
                  }}
                >
                  <option className="text-sm capitalize text-black" hidden>
                    {isNewRecord ? "Select state" : data.currentState}
                  </option>
                  {currentStates.length !== 0 &&
                    currentStates.map((state) => {
                      return (
                        <option
                          key={state.isoCode}
                          value={JSON.stringify(state)}
                          selected={data.currentState === state.stateName}
                          className="text-sm capitalize cursor-pointer"
                        >
                          {state.stateName}
                        </option>
                      );
                    })}
                </select>
                <p className="mt-2 hidden peer-invalid:block text-red-500 text-sm capitalize">
                  please enter valid input
                </p>
              </div>

              {/* Current City */}
              <div className="flex w-full flex-col md:p-1 mb-4 md:mb-0">
                <div className="flex items-center justify-between">
                  <label
                    htmlFor="currentCity"
                    className="text-sm capitalize text-black   required"
                  >
                    Current City
                  </label>
                </div>
                <select
                  name="currentCity"
                  value={data.currentCity}
                  onChange={(e) => {
                    if (!isNewRecord) {
                      if (isCurrentAndPermanentAddressSame) {
                        setUpdateData({
                          ...updateData,
                          currentCity: e.target.value,
                          permanentCity: e.target.value,
                        });
                      } else {
                        setUpdateData({
                          ...updateData,
                          currentCity: e.target.value,
                        });
                      }
                    }
                    if (isCurrentAndPermanentAddressSame) {
                      setData({
                        ...data,
                        currentCity: e.target.value,
                        permanentCity: e.target.value,
                      });
                    } else {
                      setData({
                        ...data,
                        currentCity: e.target.value,
                      });
                    }
                  }}
                  required
                  className="px-3 w-full sm:px-4 py-2 transition duration-300 border border-gray-300 rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-gray-300"
                >
                  <option className="text-sm capitalize text-black" hidden>
                    {isNewRecord ? "Select city" : data.currentCity}
                  </option>
                  {currentCities.length !== 0 &&
                    currentCities.map((city) => {
                      console.log(data.currentCity);
                      return (
                        <option
                          key={city.cityName}
                          value={city.cityName}
                          className="text-sm capitalize"
                          // selected={data.currentCity === city.cityName}
                        >
                          {city.cityName}
                        </option>
                      );
                    })}
                </select>
                <p className="mt-2 hidden peer-invalid:block text-red-500 text-sm capitalize">
                  please enter valid input
                </p>
              </div>

              {/* Permanent Address */}
              <div className="flex flex-col md:p-1 mb-4 md:mb-0">
                <div className="flex items-center justify-between">
                  <label
                    htmlFor="permanentAddress"
                    className="text-sm capitalize text-black   required sm:flex"
                  >
                    Permanent Address
                    <div className="sm:px-1 flex items-center">
                      (
                      <input
                        type={"checkbox"}
                        id={"addressCheck"}
                        checked={isCurrentAndPermanentAddressSame}
                        onChange={() => {
                          setIsCurrentAndPermanentAddressSame(
                            !isCurrentAndPermanentAddressSame
                          );
                          setData({
                            ...data,
                            permanentAddress: data.currentAddress,
                            permanentCity: data.currentCity,
                            permanentCountry: data.currentCountry,
                            permanentState: data.currentState,
                          });
                        }}
                      />
                      <label
                        htmlFor="addressCheck"
                        className="text-sm capitalize text-black ml-1"
                      >
                        Same as current address
                      </label>
                      )
                    </div>
                  </label>
                </div>
                <input
                  type="text"
                  name="permanentAddress"
                  value={data.permanentAddress}
                  onChange={isNewRecord ? handleInputChange : HandleUpdate}
                  disabled={isCurrentAndPermanentAddressSame}
                  required
                  className="px-3 sm:px-4 py-2 transition duration-300 border border-gray-300 rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-gray-300"
                />
                <p className="mt-2 hidden peer-invalid:block text-red-500 text-sm capitalize">
                  please enter valid input
                </p>
              </div>

              {/* Permanent Country */}
              <div className="flex w-full flex-col md:p-1 mb-4 md:mb-0">
                <div className="flex items-center justify-between">
                  <label
                    htmlFor="permanentCountry"
                    className="text-sm capitalize text-black   required"
                  >
                    Permanent Country
                  </label>
                </div>
                <select
                  className="w-full text-black border-gray-300 border px-3 sm:px-4 py-2 rounded-md bg-transparent"
                  name="permanentCountry"
                  value={data.permanentCountry}
                  disabled={isCurrentAndPermanentAddressSame}
                  required
                  onChange={(e) => {
                    const country = JSON.parse(e.target.value);
                    setPermanentCountryCode(country.isoCode);
                    getPermanentStates(country.isoCode);
                    console.log(country);
                    !isNewRecord &&
                      setUpdateData({
                        ...updateData,
                        permanentCountry: country.countryName,
                      });
                    setData({ ...data, permanentCountry: country.countryName });
                    console.log();
                  }}
                >
                  <option className="text-sm capitalize text-black" hidden>
                    {isCurrentAndPermanentAddressSame || !isNewRecord
                      ? data.permanentCountry
                      : data.permanentCountry || "Select Country"}
                  </option>
                  {countries.map((country) => {
                    return (
                      <option
                        key={country.code}
                        value={JSON.stringify(country)}
                        // selected={data.permanentCountry === country.name}
                        className="text-sm capitalize cursor-pointer"
                      >
                        {country.countryName}
                      </option>
                    );
                  })}
                </select>
                <p className="mt-2 hidden peer-invalid:block text-red-500 text-sm capitalize">
                  please enter valid input
                </p>
              </div>

              {/* Permanent State */}
              <div className="flex w-full flex-col md:p-1 mb-4 md:mb-0">
                <div className="flex items-center justify-between">
                  <label
                    htmlFor="permanentState"
                    className="text-sm capitalize text-black   required"
                  >
                    Permanent State
                  </label>
                </div>
                <select
                  className="w-full text-black border-gray-300 border px-3 sm:px-4 py-2 rounded-md bg-transparent"
                  name="permanentState"
                  value={data.permanentState}
                  disabled={isCurrentAndPermanentAddressSame}
                  required
                  onChange={(e) => {
                    const state = JSON.parse(e.target.value);

                    getPermanentCities(permanentCountryCode, state.isoCode);
                    isNewRecord
                      ? setData({ ...data, permanentState: state.stateName })
                      : setUpdateData({
                          ...updateData,
                          permanentState: state.stateName,
                        });
                  }}
                >
                  <option className="text-sm capitalize text-black" hidden>
                    {isCurrentAndPermanentAddressSame || !isNewRecord
                      ? data.permanentState
                      : data.permanentState || "Select State"}
                  </option>
                  {permanentStates.length !== 0 &&
                    permanentStates.map((state) => {
                      return (
                        <option
                          key={state.isoCode}
                          value={JSON.stringify(state)}
                          // selected={data.permanentState === state.name}
                          className="text-sm capitalize cursor-pointer"
                        >
                          {state.stateName}
                        </option>
                      );
                    })}
                </select>
                <p className="mt-2 hidden peer-invalid:block text-red-500 text-sm capitalize">
                  please enter valid input
                </p>
              </div>

              {/* Permanent City */}
              <div className="flex w-full flex-col md:p-1 mb-4 md:mb-0">
                <div className="flex items-center justify-between">
                  <label
                    htmlFor="permanentCity"
                    className="text-sm capitalize text-black   required"
                  >
                    Permanent City
                  </label>
                </div>
                <select
                  name="permanentCity"
                  value={data.permanentCity}
                  onChange={isNewRecord ? handleInputChange : HandleUpdate}
                  disabled={isCurrentAndPermanentAddressSame}
                  required
                  className="px-3 w-full sm:px-4 py-2 transition duration-300 border border-gray-300 rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-gray-300"
                >
                  <option className="text-sm capitalize text-black" hidden>
                    {isCurrentAndPermanentAddressSame || !isNewRecord
                      ? data.permanentCity
                      : data.permanentCity || "Select City"}
                  </option>
                  {permanentCities.length !== 0 &&
                    permanentCities.map((city) => {
                      console.log(city);
                      return (
                        <option
                          key={city.cityName}
                          value={city.cityName}
                          className="text-sm capitalize"
                          // selected={data.permanentCity === city.cityName}
                        >
                          {city.cityName}
                        </option>
                      );
                    })}
                </select>
                <p className="mt-2 hidden peer-invalid:block text-red-500 text-sm capitalize">
                  please enter valid input
                </p>
              </div>

              {/* Adhaar Number */}
              <div className="flex flex-col md:p-1 mb-4 md:mb-0">
                <label
                  htmlFor="adhaarNo"
                  className="text-sm capitalize text-black   required"
                >
                  Adhaar Number
                </label>
                <input
                  type="number"
                  name="adhaarNo"
                  value={data.adhaarNo}
                  onChange={(e) => {
                    var number = e.target.value;
                    var compare = /^[0-9]{1,12}$/g;
                    console.log(number.match(compare));

                    console.log(number.length);
                    if (number.match(compare)) {
                      if (!isNewRecord) {
                        setUpdateData({
                          ...updateData,
                          adhaarNo: number,
                        });
                      }
                      setData({ ...data, adhaarNo: number });
                    } else if (number.length <= 1) {
                      if (!isNewRecord) {
                        setUpdateData({
                          ...updateData,
                          adhaarNo: number,
                        });
                      }
                      setData({ ...data, adhaarNo: number });
                    }
                  }}
                  required
                  className="px-3 sm:px-4 py-2 transition duration-300 border border-gray-300 rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-gray-300"
                />
                <p className="mt-2 hidden peer-invalid:block text-red-500 text-sm capitalize">
                  invalid adhaar number
                </p>
              </div>
            </form>
          </div>
          <div className="my-5 py-1  bg-white  rounded-lg">
            <ButtonsLayout
              currentTab={currentTab}
              changeTab={changeTab}
              data={data}
              saveButtonDisabled={false}
              showSaveButton={isNewRecord}
              updateData={updateData}
              setShowSuccessModal={setShowSuccessModal}
              countryCode={countryCode}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default PersonalDetails;
//
