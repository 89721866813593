export const profession = [
  "Businessman",
  "Government Job",
  "Other",
  "Private Job",
  "Student",
  "Unemployed",
];

export const degreeOrDiplomaName = [
  "10th",
  "12th",
  "graduate",
  "diploma",
  "postgraduate",
  "doctorate",
];

export const boysInstitutes = [
  "Shah Satnam Ji Boys School",
  "Shah Satnam Ji Boys College",
  "Shah Satnam Ji College of Education",
  "Shah Satnam Ji Institute of Technology and Mgt",
];

export const girlsInstitutes = [
  "Shah Satnam Ji Girls School",
  "Shah Satnam Ji Girls College",
  "Shah Satnam Ji College of Education",
  "Shah Satnam Ji Institute of Technology and Mgt",
];

export const institutes = [
  "Shah Satnam Ji Boys School, Sirsa",
  "Shah Satnam Ji Boys School, Sri Gurusar Modia",
  "Shah Satnam Ji Girls School, Sirsa",
  "Shah Satnam Ji Girls School, Sri Gurusar Modia",
  "Shah Satnam Ji Girls School, Budni",
  "Shah Satnam Ji Girls School, Taranagar",
  "Shah Satnam Ji Girls Noble School, Kotda",
  "Shah Satnam Ji Boys College, Sirsa",
  "Shah Satnam Ji Girls College, Sirsa",
  "Shah Satnam Ji Girls College, Sri Gurusar Modia",
  "Saint Msg Glorious International School, Sirsa",
  "Shah Satnam Ji College of Education, Sirsa",
  "Shah Satnam Ji Institute of Technology and Management, Sirsa",
  "MSG Institute of Competition",
];

export const sammittiArray = [
  {
    id: 249,
    name: "15 MEM",
  },
  {
    id: 247,
    name: "45 MEM",
  },
  {
    id: 248,
    name: "7 MEM",
  },
  {
    id: 20,
    name: "Aara Samiti",
  },
  {
    id: 55,
    name: "Aasra Ashram Samiti	",
  },
  {
    id: 142,
    name: "Aata Samiti",
  },
  {
    id: 54,
    name: "Action Comitee",
  },
  {
    id: 40,
    name: "Address Samiti",
  },
  {
    id: 113,
    name: "Adm block",
  },
  {
    id: 20,
    name: "Aara Samiti",
  },
  {
    id: 212,
    name: "ADREES",
  },
  {
    id: 213,
    name: "ADREES",
  },
  {
    id: 174,
    name: "Advocate Samiti",
  },

  {
    id: 244,
    name: "AJUBA MACHINE",
  },
  {
    id: 169,
    name: "Alovera Samiti",
  },
  {
    id: 44,
    name: "Ang Peir Samiti	",
  },
  {
    id: 89,
    name: "Animal Samiti",
  },
  {
    id: 62,
    name: "Anouncement Samiti",
  },
  {
    id: 141,
    name: "Ayurvad Samiti",
  },
  {
    id: 70,
    name: "Bag Samiti",
  },
  {
    id: 48,
    name: "Baghvani Samiti",
  },

  {
    id: 45,
    name: "Bagwani",
  },
  {
    id: 177,
    name: "Banni Samiti",
  },
  {
    id: 79,
    name: "Bartan Samiti",
  },
  {
    id: 117,
    name: "Beldar Samiti",
  },
  {
    id: 210,
    name: "Bhangidas",
  },
  {
    id: 11,
    name: "Bijali Samiti",
  },
  {
    id: 143,
    name: "Blood Donation & Eye Donation Samiti",
  },
  {
    id: 178,
    name: "Bujurag Samiti",
  },
  {
    id: 46,
    name: "Bus Samiti",
  },
  {
    id: 224,
    name: "Cabin Samiti",
  },
  {
    id: 26,
    name: "Canteen Stall Samiti",
  },
  {
    id: 116,
    name: "Cassette Samiti",
  },
  {
    id: 134,
    name: "Centre Store Samiti",
  },
  {
    id: 219,
    name: "Chakki Samiti",
  },
  {
    id: 238,
    name: "Checking Mechine Samiti",
  },
  {
    id: 5,
    name: "Chhayawan Samiti",
  },
  {
    id: 97,
    name: "Clock Samiti",
  },
  {
    id: 28,
    name: "Cobbler Samiti",
  },
  {
    id: 110,
    name: "Conducter Samiti",
  },
  {
    id: 159,
    name: "Control Samiti",
  },
  {
    id: 47,
    name: "Cycle Stand Samiti",
  },
  {
    id: 96,
    name: "Daana Samiti",
  },
  {
    id: 99,
    name: "Dabwali Canteen",
  },
  {
    id: 52,
    name: "Dairy Samiti",
  },
  {
    id: 29,
    name: "Decoration Samiti",
  },
  {
    id: 119,
    name: "Dhobi Ghat",
  },
  {
    id: 34,
    name: "Doctor Samiti",
  },
  {
    id: 92,
    name: "Dress Numbering Samiti",
  },
  {
    id: 109,
    name: "Driver Samiti",
  },

  {
    id: 27,
    name: "Dudh (Milk) Sambhal Samiti",
  },
  {
    id: 83,
    name: "Engineer Samiti",
  },
  {
    id: 17,
    name: "Enquiry Samiti",
  },
  {
    id: 148,
    name: "Factory Samiti",
  },
  { id: 172, name: "Fast Food Samiti" },
  { id: 105, name: "Fatehabad Canteen" },
  { id: 165, name: " Father Chips Samiti" },
  { id: 118, name: " Flour Mill" },
  { id: 30, name: " Fruit Samiti" },
  { id: 164, name: " Furniture House Samiti" },
  { id: 229, name: " Gate no. 3 samiti" },
  { id: 230, name: " Gate no. 8 samiti" },
  { id: 36, name: " Gate Samiti" },
  { id: 49, name: " General Store Samiti" },
  { id: 71, name: " Generator Samiti" },
  { id: 234, name: " Green S Pandal Samiti" },
  { id: 93, name: " Green S Wing Samiti" },
  { id: 207, name: " Greens Jimmewar" },
  { id: 22, name: " Guest Samiti" },
  { id: 128, name: " Hair Dress Samiti" },
  { id: 58, name: " 	Halwai Samiti" },
  { id: 147, name: " Health Care Samiti" },
  { id: 35, name: " 	Hospital Samiti" },
  { id: 139, name: " Hostel Samiti" },
  { id: 145, name: " 	Ice Samiti" },
  { id: 121, name: " Iron Samiti" },
  { id: 211, name: " IT Wing" },
  { id: 204, name: " 	Jaam-E-Insan Registration Samiti" },
  { id: 188, name: " Janch-Padtal Samiti" },
  { id: 80, name: " Janglaat Samiti" },
  { id: 73, name: " 	Jari Buti Samiti" },
  { id: 133, name: " Joda Samiti" },
  { id: 63, name: " Kabaad Samiti" },
  { id: 222, name: " 	Kadak Tadak Samiti" },
  { id: 203, name: " Kafila Samiti" },
  { id: 189, name: " kalyan samiti" },
  { id: 84, name: " Kanak Samiti" },
  { id: 195, name: " 	Kanya Bhrun Hatya Roko Samiti" },
  { id: 107, name: " Kashish Samiti" },
  { id: 50, name: " Kaviraj Samiti" },
  { id: 226, name: " 	Khadya Anaj Samiti" },
  { id: 60, name: " 	Kheti-Bari Samiti" },
  { id: 8, name: " Khuli Canteen Samiti" },
  { id: 24, name: " 	Khuli Sewa" },
  { id: 161, name: " 	Labour Department" },
  { id: 129, name: " 	Lakkar Samiti" },
  { id: 138, name: " 	Lamination Samiti" },
  { id: 2, name: " 	Langar Banane wali Samiti" },
  { id: 198, name: " 	Langar khilane wale (Bhai) Samiti" },
  { id: 199, name: " 	Langar khilane wali Samiti" },
  { id: 232, name: " 	Langar Pakane Wali Samiti" },
  { id: 243, name: " 	LANGAR SAMITI" },
  { id: 108, name: " 	Maali Samiti" },
  { id: 231, name: " 	Main Gate Samiti" },
  { id: 149, name: " 	Market Samiti" },
  { id: 155, name: " 	Masala Samiti" },
  { id: 124, name: " Mechanic Samiti" },
  { id: 157, name: " 	Medical" },
  { id: 158, name: " 	Medical Room" },
  { id: 39, name: " Mistri Samiti" },
  { id: 217, name: " 	Mobile Samiti" },
  { id: 162, name: " 	Mochi Samiti" },
  { id: 245, name: " 	MOTOR NO 17" },
  { id: 77, name: " 	Motor No. 04" },
  { id: 103, name: " 	Motor No. 05" },
  { id: 131, name: " 	Motor No. 07" },
  { id: 111, name: " 	Motor No. 08" },
  { id: 59, name: " 	Motor No. 10" },
  { id: 65, name: " 	Motor No. 15" },
  { id: 101, name: " 	Motor No. 16" },
  { id: 87, name: " 	Motor No. 20" },
  { id: 90, name: " Motor No. 22" },
  { id: 104, name: " Motor No. 23" },
  { id: 112, name: " 	Motor Samiti" },
  {
    id: 18,
    name: "MSG IT Wing",
  },
  { id: 206, name: " 	Naam Jaam Samiti" },
  { id: 202, name: " 	Nasha Chudao Bros Samiti" },
  { id: 205, name: " 	Nasha Chudao Samiti" },
  { id: 201, name: " 	Nasha Chudao Sisters Samiti" },
  { id: 23, name: " 	Naujawan Samiti" },
  { id: 69, name: " 	Newspaper Hawker Samiti" },
  { id: 31, name: " Nigrani Samiti" },
  { id: 144, name: " 	Nursary Samiti" },
  { id: 176, name: " Nurse Samiti" },
  { id: 221, name: " 	Organic Stall Samiti" },
  { id: 74, name: " P.C.O Samiti" },
  { id: 215, name: " Pahra Samiti Left" },
  { id: 7, name: " 	Pahra Samiti Right" },
  { id: 67, name: " Painter Samiti" },
  { id: 246, name: " 	PAKKI SEWA" },
  { id: 6, name: " Pandal Samiti" },
  { id: 3, name: " 	Pani Samiti" },
  { id: 94, name: " Para Medical Staff Samiti" },
  { id: 223, name: " Parattha Samiti" },
  { id: 182, name: " 	Parshad Samiti" },
  { id: 167, name: " 	Pasa Commitee" },
  { id: 114, name: " 	Pashoo Samiti" },
  { id: 218, name: " 	Peid Samiti" },
  { id: 115, name: " 	Pesh Samiti" },
  { id: 152, name: " 	Phatta Samiti" },
  { id: 135, name: " 	Phitar Samiti" },
  { id: 15, name: " 	Photo Lamination Samiti" },
  { id: 9, name: " Photo Granth Samiti" },
  { id: 106, name: " 	Plaza Samiti" },
  { id: 88, name: " 	Plumber Samiti" },
  { id: 193, name: " 	Podha-Ropan Samiti" },
  { id: 78, name: " 	Police Samiti" },
  { id: 82, name: " 	Political Wing" },
  { id: 33, name: " 	Press Samiti" },
  { id: 125, name: " 	Pump Samiti" },
  { id: 91, name: " 	Rajai Samiti" },
  { id: 200, name: " 	Rakt-Daan va Netr-Daan Samiti" },
  { id: 160, name: " 	Repair Samiti" },
  { id: 19, name: " 	Road Samiti" },
  { id: 16, name: " 	Rojgar Vibhag" },
  { id: 154, name: " 	Room No. 07" },
  { id: 153, name: " 	Room No. 10" },
  { id: 64, name: " 	Room No. 50" },
  { id: 175, name: " 	Room No. 68" },
  { id: 137, name: " 	S.S.G.Techinic" },
  { id: 85, name: " 	Sabji Fruit Canteen Samiti" },
  { id: 130, name: " 	Sach Foods" },
  { id: 179, name: " 	Sach Kahoon & Sachi Shiksha Prachar Samiti" },
  { id: 194, name: " 	Sach Kahoon va Sachi Shiksha Prachar Samiti" },
  { id: 42, name: " 	Sach Motor Garage Samiti" },
  { id: 140, name: " 	Sach Petroleum Samiti" },
  { id: 216, name: " 	Sach Petroleum Samiti" },
  { id: 57, name: " 	Sachkhand Hall Samiti" },
  { id: 56, name: " 	Sachkhand Samiti" },
  { id: 181, name: " 	Sadak Samiti" },
  { id: 250, name: " 	SAFAI ABHIYAN" },
  { id: 66, name: " 	Safai Samiti" },
  { id: 197, name: " 	Safai Samiti Bros" },
  { id: 196, name: " 	Safai Samiti Sisters" },
  { id: 100, name: " 	Safeda Samiti" },
  { id: 4, name: " 	Saman Sambhal Samiti" },
  { id: 240, name: " 	Saman Samiti" },
  { id: 168, name: " 	Sambhal Samiti" },
  { id: 41, name: " School Samiti" },
  { id: 122, name: " 	Seat Maker" },
  { id: 123, name: " 	Seat Maker Samiti" },
  { id: 163, name: " 	Security Samiti" },
  { id: 43, name: " 	Seed Samiti" },
  { id: 13, name: " 	Sewa Samiti" },
  { id: 166, name: " 	Shahbad Canteen Samiti" },
  { id: 120, name: " 	Shahi Bhajan Mandli Samiti" },
  { id: 236, name: " 	Shahi Canteen Sabji Samiti" },
  { id: 1, name: " 	Shahi Canteen Samiti" },
  { id: 95, name: " 	Shifting Samiti" },
  { id: 81, name: " Silai Samiti" },
  { id: 239, name: " 	SMJD Pahra Samiti" },
  { id: 235, name: " 	SMJD Sabji Fruit Canteen Samiti" },
  { id: 242, name: " 	SMJD Shahi Canteen Samiti" },
  { id: 227, name: " 	Snan Ghar Pehra Samiti" },
  { id: 136, name: "Sofa Samiti" },
  { id: 12, name: "Speaker Samiti" },
  { id: 171, name: "Sports Samiti" },
  { id: 102, name: "Sri Gurusarmodia Hostel" },
  { id: 233, name: "SSJD Sabji Fruit Canteen Samiti" },
  { id: 237, name: "SSJD Sadak Samiti" },
  { id: 241, name: "SSJD Shahi Canteen Samiti" },
  { id: 75, name: "Stadium Samiti" },
  { id: 10, name: "Stage Samiti" },
  { id: 127, name: "Star samiti" },
  { id: 32, name: "Sugam Construction Co." },
  { id: 214, name: "SUJAN BAHAN" },
  { id: 151, name: "Sunder Mahal Samiti" },
  { id: 225, name: "Suparbhat Samiti" },
  { id: 156, name: "Super Market" },
  { id: 51, name: "T.V Samiti" },
  { id: 38, name: "Tailor Samiti" },
  { id: 170, name: "Tank Samiti" },
  { id: 173, name: "Taps Samiti" },
  { id: 53, name: "Telephone Samiti" },
  { id: 76, name: "Tikki Samiti" },
  { id: 86, name: "Tile Marbel Samiti" },
  { id: 150, name: "Tokara Samiti" },
  { id: 21, name: "Token Samiti" },
  { id: 37, name: "Tools Samiti" },
  { id: 220, name: "Tora-Fera Samiti" },
  { id: 228, name: "Traffic Pahra Samiti" },
  { id: 68, name: "Traffic Samiti" },
  { id: 180, name: "Training" },
  { id: 14, name: "Transport Samiti" },
  { id: 126, name: "Tree Plantation Samiti" },
  { id: 25, name: "True World Samiti" },
  { id: 98, name: "Tutian Samiti" },
  { id: 146, name: "Video Samiti" },
  { id: 132, name: "Water Plant Samiti" },
  { id: 72, name: "Welding Samiti" },
  { id: 61, name: "Work Shop" },
  { id: 208, name: "Youth Virangnayein Samiti" },
  { id: 209, name: "Youth Welfare Federation" },
];
