import React, { useState, useEffect } from "react";
import PersonalDetails from "../components/details/PersonalDetails";
import EducationalDetails from "../components/details/EducationDetails";
import ProfessionalDetails from "../components/details/ProfessionalDetails";
import SewaDetails from "../components/details/SewaDetails";
import "./styles/Form.css";
import {
  FaUserAlt,
  FaBookOpen,
  FaBlackTie,
  FaPrayingHands,
  FaUsers,
} from "react-icons/fa";
import FamilyDetails from "../components/details/FamilyDetails";
import { useNavigate } from "react-router-dom";
import SuccessModal from "../components/SuccessModal";

const Details = () => {
  const navigate = useNavigate();
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  const changeTab = (name) => setCurrentTab(name);

  const getCurrentForm = () => {
    switch (currentTab) {
      case 0:
        return (
          <PersonalDetails
            currentTab={currentTab}
            changeTab={changeTab}
            setShowSuccessModal={setShowSuccessModal}
          />
        );
      case 1:
        return (
          <EducationalDetails
            currentTab={currentTab}
            changeTab={changeTab}
            setShowSuccessModal={setShowSuccessModal}
          />
        );
      case 2:
        return (
          <ProfessionalDetails
            currentTab={currentTab}
            changeTab={changeTab}
            setShowSuccessModal={setShowSuccessModal}
          />
        );
      case 3:
        return (
          <SewaDetails
            currentTab={currentTab}
            changeTab={changeTab}
            setShowSuccessModal={setShowSuccessModal}
          />
        );
      case 4:
        return (
          <FamilyDetails
            currentTab={currentTab}
            changeTab={changeTab}
            setShowSuccessModal={setShowSuccessModal}
          />
        );
      default:
        return (
          <PersonalDetails
            currentTab={currentTab}
            changeTab={changeTab}
            setShowSuccessModal={setShowSuccessModal}
          />
        );
    }
  };
  const wrapperDivClass = `flex items-center text-gray-600  relative`;
  const iconOutter = `rounded-full transition duration-500 ease-in-out h-12 w-12 py-3 border-2 border-gray-400 text-gray-600 flex justify-center items-center`;
  const stepperName = `absolute top-0 -ml-10 text-center mt-14 w-32 text-xs font-medium uppercase text-gray-600`;

  useEffect(() => {
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    if (userDetails?.user?.role === "superadmin") {
      navigate("/users");
    }
  }, []);

  return (
    <div className="min-h-screen w-full shadow  bg-red-100 py-2 rounded-md">
      {/* <!-- stepper frontend only --> */}

      <div className="p-2 sm:p-5 max-w-6xl mx-auto pt-8 ">
        <div className="p-4 pb-8 mb-5 rounded-lg bg-white  shadow-md shadow-gray-300">
          <div className="flex items-center">
            <div
              className={wrapperDivClass}
              // onClick={() => setCurrentTab(0)}
            >
              <div
                className={`${iconOutter} ${
                  currentTab > 0 && `bg-red-500 border-none text-white}`
                }  ${currentTab === 0 && `border-red-500 `}  `}
              >
                <FaUserAlt className={`${currentTab > 0 && `text-white`}`} />
              </div>
              <div className={`${stepperName} hidden md:block`}>Personal</div>
            </div>

            <div
              className={`flex-auto border-t-2 transition duration-500 ease-in-out ${
                currentTab > 0 && `border-red-500`
              }`}
            ></div>

            <div
              className={wrapperDivClass}
              // onClick={() => setCurrentTab(1)}
            >
              <div
                className={`${iconOutter} ${
                  currentTab > 1 && `bg-red-500 border-none text-white}`
                }  ${currentTab === 1 && `border-red-500 `}  `}
              >
                <FaBookOpen className={`${currentTab > 1 && `text-white`}`} />
              </div>
              <div className={`${stepperName} hidden md:block`}>Education</div>
            </div>

            <div
              className={`flex-auto border-t-2 transition duration-500 ease-in-out ${
                currentTab > 1 && `border-red-500`
              }`}
            ></div>

            <div
              className={wrapperDivClass}
              // onClick={() => setCurrentTab(2)}
            >
              <div
                className={`${iconOutter} ${
                  currentTab > 2 && `bg-red-500 border-none text-white}`
                }  ${currentTab === 2 && `border-red-500 `}  `}
              >
                <FaBlackTie className={`${currentTab > 2 && `text-white`}`} />
              </div>
              <div className={`${stepperName} hidden md:block`}>
                Professional
              </div>
            </div>

            <div
              className={`flex-auto border-t-2 transition duration-500 ease-in-out ${
                currentTab > 2 && `border-red-500`
              }`}
            ></div>

            <div
              className={wrapperDivClass}
              // onClick={() => setCurrentTab(3)}
            >
              <div
                className={`${iconOutter} ${
                  currentTab > 3 && `bg-red-500 border-none text-white}`
                }  ${currentTab === 3 && `border-red-500 `}  `}
              >
                <FaPrayingHands
                  className={`${currentTab > 3 && `text-white`}`}
                />
              </div>
              <div className={`${stepperName} hidden md:block`}>Welfare</div>
            </div>

            <div
              className={`flex-auto border-t-2 transition duration-500 ease-in-out ${
                currentTab > 3 && `border-red-500`
              }`}
            ></div>

            <div
              className={wrapperDivClass}
              // onClick={() => setCurrentTab(4)}
            >
              <div
                className={`${iconOutter} ${
                  currentTab > 3 && `bg-red-500 border-none text-white}`
                }  ${currentTab === 3 && `border-red-500 `}  `}
              >
                <FaUsers className={`${currentTab > 3 && `text-white`}`} />
              </div>
              <div className={`${stepperName} hidden md:block`}>Family</div>
            </div>
          </div>
        </div>
        <hr className="my-2" />

        {getCurrentForm()}
      </div>

      {/* Success Modal */}
      {showSuccessModal && (
        <div className="mx-auto backdrop-blur-md flex items-center justify-center fixed w-full h-full top-0 left-0">
          <SuccessModal
            showSuccessModal={true}
            setShowSuccessModal={setShowSuccessModal}
          />
        </div>
      )}
    </div>
  );
};

export default Details;
