import React, { useEffect, useState } from "react";
import ButtonsLayout from "../ButtonsLayout";
import { API } from "../../shared/utility";
import Spinner from "../Spinner";
import axios from "axios";
import { errorToast } from "../../shared/Service";
import { envConfig } from "../../shared/config";

const COMMON_URL = envConfig.COMMON_URL;

const ProfessionalDetails = ({
  currentTab,
  changeTab,
  setShowSuccessModal,
}) => {
  const [data, setData] = useState({
    currentProfession: "",
    department: "",
    sector: "",
    address: "",
    designation: "",
    state: "",
    country: "",
    city: "",
  });
  const [isCurrentProfessionOther, setIsCurrentProfessionOther] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [updateData, setUpdateData] = useState({});
  const [isNewRecord, setIsNewRecord] = useState(true);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [countryCode, setCountryCode] = useState("");

  const sectorArray = [
    "Manufacturing",
    "Sports",
    "Technology",
    "Health Care",
    "Finance",
    "Stock Market",
    "Economics",
    "Computer and IT",
    "Energy",
    "Telecommunications",
    "Agriculture",
    "Retail",
    "Communication",
    "Hospitality",
    "Financial Services",
    "Real Estate",
    "Transport",
    "Mining",
    "Food",
    "E-commerce",
    "Education",
    "Mass Communication",
    "Medical Devices",
    "Aviation",
    "Engeneering",
    "Pharmaceutical",
    "Research",
    "Health",
    "Logistics",
    "Insurance",
    "Defence/Police",
    "Judiciary",
    "Other",
  ];

  const workDomainArray = [
    "HR",
    "Manufacturing",
    "Accounting, Finance",
    "Legal",
    "Supply Chain and Procurement",
    "Business Process",
    "IT",
    "Engineering",
    "Medical",
    "Administration",
    "Other",
  ];

  const isEmploymentStatusOther = () => {
    if (
      data.employementStatus === "job" ||
      data.employementStatus === "student" ||
      data.employementStatus === "businessman" ||
      data.employementStatus === "unemployed" ||
      data.employementStatus === ""
    ) {
      return false;
    }
    return true;
  };

  const issectorOther = () => {
    return false;
  };

  const isWorkDomainOther = () => {
    return false;
  };

  const GET_DATA_FROM_DB = async () => {
    setIsLoading(true);
    API.GET_PROFESSION_DETAIL()
      .then((res) => {
        if (res.status == 200) {
          res.data.data.length === 0
            ? setIsNewRecord(true)
            : setIsNewRecord(false);
          const {
            currentProfession,
            department,
            sector,
            address,
            designation,
            country,
            city,
            state,
          } = res.data.data;

          const resData = {
            currentProfession,
            department,
            sector,
            address,
            designation,
            country,
            city,
            state,
          };

          setData(resData);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        if (!isNewRecord) {
          errorToast(err.response.data.message);
        }
        console.log(err ? err.response.data.message : err.message);
      })
      .finally(() => setIsLoading(false));
  };

  const handleInputChange = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  };
  const HandleUpdate = (event) => {
    if (event.target.value !== "") {
      setUpdateData({ ...updateData, [event.target.name]: event.target.value });
    }
    handleInputChange(event);
  };

  const getCountries = () => {
    axios
      .get(COMMON_URL + `/countries`)
      .then((res) => {
        setCountries(res.data.data);
      })
      .catch((e) => {
        console.log(e.response.data.message);
      });
  };
  const getStates = (CountryCode) => {
    axios
      .get(COMMON_URL + `/states/${CountryCode}`)
      .then((res) => {
        setStates(res.data.data);
      })
      .catch((e) => {
        console.log(e.response.data.message);
      });
  };
  const getCities = (CountryCode, stateCode) => {
    axios
      .get(COMMON_URL + `/cities/${CountryCode}/${stateCode}`)
      .then((res) => {
        setCities(res.data.data);
      })
      .catch((e) => {
        console.log(e.response.data.message);
      });
  };

  useEffect(() => {
    !data?.employementStatus && GET_DATA_FROM_DB();
    getCountries();
    getStates();
    getCities();
  }, []);

  return (
    <div className={`${currentTab === 2 ? "block" : "hidden"}`}>
      {isLoading ? (
        <div className="w-full h-[50vh] flex items-center justify-center bg-white  rounded-lg">
          <Spinner />
        </div>
      ) : (
        <>
          <div className={`w-full flex-col bg-white  rounded-lg flex`}>
            {/* CURRENT POSITION DETAILS */}
            <div className="p-3 sm:p-5 md:flex-1">
              <h1 className="my-3 text-xl sm:my-4 sm:text-2xl text-gray-700">
                Occupation Details
              </h1>
              <form action="#" className="flex flex-col space-y-5">
                {/*  currentProfession */}
                <div className="flex flex-col space-y-1">
                  <label
                    htmlFor="currentProfession"
                    className="text-sm capitalize required"
                  >
                    Current Profession
                  </label>
                  <select
                    className="text-black p-3 border-gray-300 border rounded-md bg-transparent outline-none capitalize"
                    name="currentProfession"
                    required
                    onChange={(e) => {
                      e.target.value === "other"
                        ? setIsCurrentProfessionOther(true)
                        : setIsCurrentProfessionOther(false);
                      isNewRecord ? handleInputChange(e) : HandleUpdate(e);
                    }}
                  >
                    <option value="" defaultValue hidden>
                      -None-
                    </option>
                    <option
                      value="businessman"
                      selected={
                        !isCurrentProfessionOther &&
                        data.currentProfession === "businessman"
                      }
                    >
                      Businessman
                    </option>
                    <option
                      value="government"
                      selected={
                        !isCurrentProfessionOther &&
                        data.currentProfession === "government"
                      }
                    >
                      Government Job
                    </option>
                    <option value="other" selected={isCurrentProfessionOther}>
                      Other
                    </option>
                    <option
                      value="private"
                      selected={
                        !isCurrentProfessionOther &&
                        data.currentProfession === "private"
                      }
                    >
                      Private Job
                    </option>
                    <option
                      value="student"
                      selected={
                        !isCurrentProfessionOther &&
                        data.currentProfession === "student"
                      }
                    >
                      Student
                    </option>
                    <option
                      value="unemployed"
                      selected={
                        !isCurrentProfessionOther &&
                        data.currentProfession === "unemployed"
                      }
                    >
                      Unemployed
                    </option>
                  </select>
                </div>

                {/*   Sector */}
                {(data.currentProfession == "private" ||
                  data.currentProfession == "government" ||
                  data.currentProfession == "businessman") && (
                  <div className="flex flex-col space-y-1">
                    <label
                      htmlFor="sector"
                      className="text-sm capitalize required  "
                    >
                      Sector
                    </label>
                    <select
                      className="text-black p-3 border-gray-300 border rounded-md bg-transparent outline-none capitalize"
                      name="sector"
                      onChange={isNewRecord ? handleInputChange : HandleUpdate}
                    >
                      <option value="" defaultValue hidden>
                        -none-
                      </option>
                      {sectorArray.sort().map((sector, index) => {
                        return (
                          <option key={index} selected={data.sector === sector}>
                            {sector}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                )}

                {/* If Other, Please specify  */}
                {data.sector == "other" && (
                  <div className={`flex flex-col space-y-1`}>
                    <label
                      htmlFor="other-job-sector"
                      className="text-sm capitalize   "
                    >
                      If Other, Please specify
                    </label>
                    <input
                      type="text"
                      name="sector"
                      value={data.sector}
                      onChange={isNewRecord ? handleInputChange : HandleUpdate}
                      className="px-4 py-2 transition duration-300 border border-gray-300 rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-blue-200 capitalize"
                    />
                  </div>
                )}

                {/* Designation */}
                {(data.currentProfession == "private" ||
                  data.currentProfession == "government") && (
                  <div className="flex flex-col space-y-1">
                    <div className="flex items-center justify-between">
                      <label
                        htmlFor="designation"
                        className="text-sm capitalize required  "
                      >
                        Designation
                      </label>
                    </div>
                    <input
                      type="text"
                      name="designation"
                      value={data.designation}
                      onChange={isNewRecord ? handleInputChange : HandleUpdate}
                      className="px-4 py-2 transition duration-300 border border-gray-300 rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-blue-200 capitalize"
                    />
                  </div>
                )}
                {/* Department */}
                {(data.currentProfession == "private" ||
                  data.currentProfession == "government") && (
                  <div className="flex flex-col space-y-1">
                    <div className="flex items-center justify-between">
                      <label
                        htmlFor="department"
                        className="text-sm capitalize required  "
                      >
                        Department
                      </label>
                    </div>
                    <input
                      type="text"
                      name="department"
                      value={data.department}
                      onChange={isNewRecord ? handleInputChange : HandleUpdate}
                      className="px-4 py-2 transition duration-300 border border-gray-300 rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-blue-200"
                    />
                  </div>
                )}

                {/* Address  */}
                {(data.currentProfession == "private" ||
                  data.currentProfession == "government" ||
                  data.currentProfession == "businessman") && (
                  <div className="md:flex justify-around">
                    <div className="flex w-full flex-col md:p-1 mb-4 md:mb-0">
                      <div className="flex items-center justify-between">
                        <label
                          htmlFor="address"
                          className="text-sm capitalize text-black  "
                        >
                          Any Other information you want to share! (For eg.
                          Achievements, Research Experience, Sports or other
                          awards)
                        </label>
                      </div>
                      <textarea
                        rows="2"
                        cols="50"
                        type="text"
                        name="address"
                        value={data.address}
                        onChange={
                          isNewRecord ? handleInputChange : HandleUpdate
                        }
                        className="w-full px-3 sm:px-4 py-2 transition duration-300 border border-gray-300 rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-gray-300"
                      />
                      <p className="mt-2 hidden peer-invalid:block text-red-500 text-sm capitalize">
                        please enter valid input
                      </p>
                    </div>
                  </div>
                )}

                {/* Country */}
                {(data.currentProfession == "private" ||
                  data.currentProfession == "government" ||
                  data.currentProfession == "businessman") && (
                  <div className="flex w-full flex-col md:p-1 mb-4 md:mb-0">
                    <div className="flex items-center justify-between">
                      <label
                        htmlFor="country"
                        className="text-sm capitalize text-black required  "
                      >
                        Country
                      </label>
                    </div>
                    <select
                      className="w-full text-black border-gray-300 border px-3 sm:px-4 py-2 rounded-md bg-transparent"
                      name="country"
                      //   value={data.country}
                      onChange={(e) => {
                        const country = JSON.parse(e.target.value);
                        setCountryCode(country.isoCode);
                        getStates(country.isoCode);
                        isNewRecord
                          ? setData({ ...data, country: country.countryName })
                          : setUpdateData({
                              ...updateData,
                              country: country.countryName,
                            });
                      }}
                    >
                      <option className="text-sm capitalize text-black" hidden>
                        Select Country
                      </option>
                      {countries.map((country) => {
                        return (
                          <option
                            key={country.isoCode}
                            value={JSON.stringify(country)}
                            selected={data.country === country.countryName}
                            className="text-sm capitalize cursor-pointer"
                          >
                            {country.countryName}
                          </option>
                        );
                      })}
                    </select>
                    <p className="mt-2 hidden peer-invalid:block text-red-500 text-sm capitalize">
                      please enter valid input
                    </p>
                  </div>
                )}

                {/* State */}
                {(data.currentProfession == "private" ||
                  data.currentProfession == "government" ||
                  data.currentProfession == "businessman") && (
                  <div className="flex w-full flex-col md:p-1 mb-4 md:mb-0">
                    <div className="flex items-center justify-between">
                      <label
                        htmlFor="state"
                        className="text-sm capitalize text-black required  "
                      >
                        State
                      </label>
                    </div>
                    <select
                      className="w-full text-black border-gray-300 border px-3 sm:px-4 py-2 rounded-md bg-transparent"
                      name="state"
                      //   value={data.state}
                      onChange={(e) => {
                        const state = JSON.parse(e.target.value);

                        getCities(countryCode, state.isoCode);
                        isNewRecord
                          ? setData({ ...data, state: state.stateName })
                          : setUpdateData({
                              ...updateData,
                              state: state.stateName,
                            });
                      }}
                    >
                      <option className="text-sm capitalize text-black" hidden>
                        {isNewRecord ? "Select state" : data.state}
                      </option>
                      {states.length !== 0 &&
                        states.map((state) => {
                          return (
                            <option
                              key={state.isoCode}
                              value={JSON.stringify(state)}
                              selected={data.state === state.stateName}
                              className="text-sm capitalize cursor-pointer"
                            >
                              {state.stateName}
                            </option>
                          );
                        })}
                    </select>
                    <p className="mt-2 hidden peer-invalid:block text-red-500 text-sm capitalize">
                      please enter valid input
                    </p>
                  </div>
                )}

                {/* City */}
                {(data.currentProfession == "private" ||
                  data.currentProfession == "government" ||
                  data.currentProfession == "businessman") && (
                  <div className="flex w-full flex-col md:p-1 mb-4 md:mb-0">
                    <div className="flex items-center justify-between">
                      <label
                        htmlFor="city"
                        className="text-sm capitalize text-black required  "
                      >
                        City
                      </label>
                    </div>
                    <select
                      name="city"
                      //   value={data.city}
                      onChange={isNewRecord ? handleInputChange : HandleUpdate}
                      className="px-3 w-full sm:px-4 py-2 transition duration-300 border border-gray-300 rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-gray-300"
                    >
                      <option className="text-sm capitalize text-black" hidden>
                        {isNewRecord ? "Select City" : data.city}
                      </option>
                      {cities.length !== 0 &&
                        cities.map((city) => {
                          return (
                            <option
                              key={city.cityName}
                              value={city.cityName}
                              selected={data.city === city.cityName}
                              className="text-sm capitalize"
                              default
                            >
                              {city.cityName}
                            </option>
                          );
                        })}
                    </select>
                    <p className="mt-2 hidden peer-invalid:block text-red-500 text-sm capitalize">
                      please enter valid input
                    </p>
                  </div>
                )}

                {/* other  */}
                {isCurrentProfessionOther && (
                  <div className={`flex flex-col space-y-1`}>
                    <label
                      htmlFor="currentProfession"
                      className="text-sm capitalize   "
                    >
                      Other
                    </label>
                    <input
                      type="text"
                      name="currentProfession"
                      value={data.currentProfession}
                      onChange={isNewRecord ? handleInputChange : HandleUpdate}
                      className="px-4 py-2 transition duration-300 border border-gray-300 rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-blue-200"
                    />
                  </div>
                )}
              </form>
            </div>
          </div>
          <div className="my-5 py-1  bg-white  rounded-lg">
            <ButtonsLayout
              currentTab={currentTab}
              changeTab={changeTab}
              data={data}
              saveButtonDisabled={false}
              showSaveButton={isNewRecord}
              updateData={updateData}
              setShowSuccessModal={setShowSuccessModal}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default ProfessionalDetails;
