import React from "react";

const CommonButton = ({
  text,
  bg,
  color = "White",
  action,
  disable,
  margin = "mt-4 mb-2",
  ...rest
}) => {
  return (
    <div className={margin}>
      <button
        disabled={disable}
        onClick={action && action}
        className={`${bg} ${color} transition-shadow disabled:cursor-no-drop
				disabled:bg-gray-400
   border-none w-full outline-none h-12 rounded-md capitalize text-white`}
        {...rest}
      >
        {text}
      </button>
    </div>
  );
};

export default CommonButton;
