import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../components/PrimaryButton";
import axios from "axios";
import WelcomeImg from "../assets/images/Welcome.svg";
import Spinner from "../components/Spinner";
import { envConfig } from "../shared/config";

const Register = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const [data, setData] = useState({
    name: "",
    phoneNo: "",
    email: "",
    password: "",
  });

  const isValidEmail = (email) => /\S+@\S+\.\S+/.test(email);

  const isValidPhoneNo = (phoneNo) => phoneNo.length === 10;

  const validate = () => {
    if (
      !data.name ||
      !data.email ||
      !data.phoneNo ||
      !data.password ||
      !isValidEmail(data.email) ||
      !isValidPhoneNo(data.phoneNo)
    )
      return true;
    return false;
  };

  const register = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const res = await axios({
        method: "post",
        url: envConfig.API_URL + "/user/",
        data,
      });

      if (res.status === 200) {
        setIsLoading(false);
        navigate("/login");
      }
    } catch (error) {
      setIsLoading(false);
      alert(error.response.data.message);
      console.log(error);
    }
  };

  const handleInputChange = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  };

  const inputClass =
    "px-4 py-2 transition duration-300 border text-gray-500 border-gray-300 rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-gray-200";

  return (
    <div className="bg-red-100  min-h-screen flex flex-col justify-center items-center ">
      <div className="w-[90%] md:w-[900px] shadow-sm">
        {isLoading ? (
          <div className="w-full h-[50vh] flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <>
            <p className="text-2xl md:text-3xl font-bold text-gray-600 mb-5">
              Account Register
            </p>
            <div className="   bg-white  shadow-gray-300 p-4 md:p-8 m-auto md:flex items-center ">
              <div className="w-[90%] md:m-auto mb-4 mx-auto order-1 md:w-2/4">
                <img src={WelcomeImg} className="w-[80%] m-auto" alt="" />
              </div>
              <div className="w-[90%] m-auto order-2 md:w-2/4">
                <div className="flex flex-col space-y-1 my-2">
                  <label htmlFor="name" className="text-sm  text-gray-400">
                    Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    onChange={handleInputChange}
                    autoFocus
                    value={data.name}
                    className="px-4 py-2 transition duration-300 border text-gray-400 border-gray-300 rounded focus:border-transparent focus:outline-none peer focus:ring-4 focus:ring-gray-200"
                  />
                  <p className="mt-2 hidden peer-invalid:block text-red-500 text-sm">
                    {" "}
                    invalid name.
                  </p>
                </div>
                <div className="flex flex-col space-y-1 my-2">
                  <label htmlFor="number" className="text-sm  text-gray-400">
                    Phone Number
                  </label>
                  <input
                    type="number"
                    name="phoneNo"
                    onChange={handleInputChange}
                    value={data.phoneNo}
                    className="px-4 py-2 transition duration-300 border text-gray-400 border-gray-300 rounded focus:border-transparent focus:outline-none peer focus:ring-4 focus:ring-gray-200"
                  />
                  <p
                    className={`${
                      !isValidPhoneNo(data.phoneNo) && data.phoneNo !== ""
                        ? "block"
                        : "hidden"
                    } mt-2  peer-invalid:block text-red-500 text-sm`}
                  >
                    {" "}
                    phone number must be of 10 digits.
                  </p>
                </div>
                <div className="flex flex-col space-y-1 my-2">
                  <label htmlFor="email" className="text-sm  text-gray-400">
                    Email address
                  </label>
                  <input
                    type="email"
                    name="email"
                    onChange={handleInputChange}
                    autoFocus
                    value={data.email}
                    className="px-4 py-2 transition duration-300 border text-gray-400 border-gray-300 rounded focus:border-transparent focus:outline-none peer focus:ring-4 focus:ring-gray-200"
                  />
                  <p
                    className={`${
                      !isValidEmail(data.email) && data.email !== ""
                        ? "block"
                        : "hidden"
                    } mt-2 peer-invalid:block text-red-500 text-sm`}
                  >
                    {" "}
                    invalid email address.
                  </p>
                </div>
                {/*  password */}
                <div className="flex flex-col space-y-1 my-2">
                  <label htmlFor="password" className="text-sm  text-gray-400">
                    Password
                  </label>
                  <input
                    type="password"
                    name="password"
                    onChange={handleInputChange}
                    value={data.password}
                    className="px-4 py-2 transition duration-300 border text-gray-500
     border-gray-300 rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-gray-200"
                  />
                </div>

                <PrimaryButton
                  disable={validate()}
                  action={register}
                  text="Register"
                  bg="bg-red-500"
                />
                <div className="flex justify-between">
                  <span className="text-blue-500 cursor-pointer hover:underline">
                    <Link to="/login">Already have an account?</Link>
                  </span>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Register;
