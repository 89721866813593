import React from "react";

const NotFound = () => {
  return (
    <div className="h-[100vh] flex items-center justify-center text-3xl font-semibold">
      404 Page Not Found
    </div>
  );
};

export default NotFound;
