import React, { useRef, useState, useEffect } from "react";
import { API } from "../shared/utility";
import { useParams } from "react-router-dom";
import { envConfig } from "../shared/config";

const VerifyUserDetail = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setuserData] = useState();

  const printRef = useRef();

  const GetUserDetail = async () => {
    setIsLoading(true);
    API.GET_USER_DETAIL(id)
      .then((res) => {
        setuserData(res.data.data);

        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    GetUserDetail();
  }, []);

  return userData &&
    userData.Personal != null &&
    userData.Education != null &&
    userData.Attendance != null ? (
    <div className="mt-0">
      {/* about us  */}
      <div className="w-full min-h-64 bg-red-100 h-[100vh]">
        <div className="rounded-sm h-full">
          <div className="text-gray-700 w-full px-4 flex items-start justify-start">
            <div className="flex flex-col md:flex-row text-sm">
              <div className=" mb-3 md:mb-0">
                <img
                  src={
                    envConfig.API_URL +
                    `/user-pic/` +
                    userData?.Personal.imageUrl
                  }
                  height={200}
                  width={200}
                  alt="photo"
                />
              </div>
              <div className="flex flex-col items-start">
                <div className="flex ">
                  <div className="pr-4 py-2  capitalize">Name</div>
                  <div className="pr-4 py-2  capitalize font-semibold">
                    {userData.Personal.name}
                  </div>
                </div>
                <div className="flex grid-cols-2">
                  <div className="pr-4 py-2  capitalize">Father Name</div>
                  <div className="pr-4 py-2  capitalize font-semibold">
                    {userData.Personal.fatherName}
                  </div>
                </div>
                <div className="flex grid-cols-2">
                  <div className="pr-4 py-2  capitalize">Admission No.</div>
                  <div className="pr-4 py-2  capitalize font-semibold">
                    {userData.Education.enrollmentAdmissionNumber}
                  </div>
                </div>
                <div className="flex grid-cols-2">
                  <div className="pr-4 py-2  capitalize">Spouse</div>
                  <div className="pr-4 py-2  capitalize font-semibold">
                    {userData.Attendance.isSpouseComing ? "Yes" : "No"}
                  </div>
                </div>
                <div className="flex grid-cols-2">
                  <div className="pr-4 py-2  capitalize">Children</div>
                  <div className="pr-4 py-2  capitalize font-semibold">
                    {userData.Attendance.childrenCount}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p className="rotingtxtVerifyUser">
            &#10004; <span className="text-5xl font-bold">verified</span>
          </p>
        </div>
      </div>
      <div className="my-2"></div>
    </div>
  ) : (
    <div className="flex w-full justify-center h-50">
      <p className="tracking-wide text-red-500">Loading.....</p>
    </div>
  );
};

export default VerifyUserDetail;
