import React, { useRef, useState, useEffect } from "react";
import ReactToPrint from "react-to-print";
import { API } from "../shared/utility";
import { useParams } from "react-router-dom";
import QRCode from "qrcode.react";
import { MdOutlinePeopleAlt } from "react-icons/md";
import { FaCheck } from "react-icons/fa";
import Spinner from "../components/Spinner";
import { envConfig } from "../shared/config";

const GetAllData = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setuserData] = useState();

  const printRef = useRef();

  const GetUserDetail = async () => {
    setIsLoading(true);
    API.GET_USER_DETAIL(id)
      .then((res) => {
        setuserData(res.data.data);

        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    GetUserDetail();
  }, [0]);
  return (
    <>
      {isLoading ? (
        <div className="w-full h-full fixed top-0 left-0 bg-black opacity-60 flex items-center justify-center">
          <Spinner />
        </div>
      ) : (
        <>
          {userData &&
          userData?.Personal != null &&
          userData?.Education != null &&
          userData?.Profession != null &&
          userData?.Welfare != null &&
          userData?.status === "active" ? (
            <>
              <div className="flex w-full  justify-center p-2">
                <ReactToPrint
                  trigger={() => (
                    <button className="mx-1 bg-red-500 rounded-md p-1 px-3 text-white text-md font-normal cursor-pointer">
                      Print Profile
                    </button>
                  )}
                  content={() => printRef.current}
                  documentTitle="Detail Page"
                />
              </div>

              <div className="max-w-4xl  mx-auto ">
                <div className="w-[80%] h-min-content mx-auto " ref={printRef}>
                  <div className="container mx-auto md:p-5 my-0 !py-0 ">
                    <div className="my-3 mt-8 text-md text-black bg-red-200 p-2 rounded-md capitalize item-center text-center">
                      <span>Loving Child Father’s Day E-Pass </span>
                    </div>
                    <div className="flex justify-end">
                      <span className="text-red-500 text-sm text-end">
                        Valid till:- 19th-21st Nov 22
                      </span>
                    </div>
                    {/* user & family-Pic */}
                    <div className="w-full  mx-2 min-h-64 ">
                      <div className="bg-white p-4 flex  items-center justify-between ">
                        <div className=" flex mb-4 sm:mb-0  ">
                          <img
                            src={
                              envConfig.API_URL +
                              `/family/` +
                              userData?.Attendance?.familyPicUrl
                            }
                            alt="family-pic"
                            className="object-fit h-[156px] w-[156px]"
                          />
                        </div>

                        <div className=" flex mb-4 sm:mb-0  ">
                          <img
                            src={
                              envConfig.API_URL +
                              `/user-pic/` +
                              userData?.Personal.imageUrl
                            }
                            alt="user-pic"
                            className="object-fit h-[156px] w-[156px]"
                          />
                        </div>
                      </div>
                    </div>
                    {/* about us  */}
                    <div className="w-full  mx-2 min-h-64">
                      <p className="rotingtxt !pl-0 !md:pl-[20rem]">
                        Valid till
                        <br /> 19th-21st Nov 22
                      </p>
                      <div className="bg-white p-4 shadow-sm rounded-sm h-full">
                        <div className="flex items-center space-x-2 font-semibold text-gray-900 leading-8">
                          <span clas="text-green-500">
                            <MdOutlinePeopleAlt className="h-5" />
                          </span>
                          <span className="tracking-wide">About</span>
                        </div>
                        <div className="text-gray-700">
                          <div className="flex grid grid-cols-2 text-sm">
                            <div>
                              <div className="grid grid-cols-2">
                                <div className="px-4 py-2  capitalize font-semibold">
                                  Name
                                </div>
                                <div className="px-4 py-2  capitalize">
                                  {userData?.Personal.name}
                                </div>
                              </div>
                              <div className="grid grid-cols-2">
                                <div className="px-4 py-2  capitalize font-semibold">
                                  Father Name
                                </div>
                                <div className="px-4 py-2  capitalize">
                                  {userData?.Personal.fatherName}
                                </div>
                              </div>
                              <div className="grid grid-cols-2">
                                <div className="px-4 py-2  capitalize font-semibold">
                                  Gender
                                </div>
                                <div className="px-4 py-2  capitalize">
                                  {userData?.Personal.gender}
                                </div>
                              </div>
                              <div className="grid grid-cols-2">
                                <div className="px-4 py-2  capitalize font-semibold">
                                  calling No.
                                </div>
                                <div className="px-4 py-2  capitalize">
                                  {userData?.Personal.callingPhoneNo}
                                </div>
                              </div>

                              <div className="grid grid-cols-2">
                                <div className="px-4 py-2  capitalize font-semibold">
                                  Addmission No.
                                </div>
                                <div className="px-4 py-2  capitalize">
                                  {
                                    userData?.Education
                                      .enrollmentAdmissionNumber
                                  }
                                </div>
                              </div>

                              <div className="grid grid-cols-2">
                                <div className="px-4 py-2  capitalize font-semibold">
                                  Institute Name
                                </div>
                                <div className="px-4 py-2  capitalize">
                                  {userData?.Education.enrollmentInstitute}
                                </div>
                              </div>
                              {/* alfm;,asm;lfa */}

                              <div className="grid grid-cols-2">
                                <div className="px-4 py-2  capitalize font-semibold">
                                  Spouse
                                </div>
                                <div className="px-4 py-2  capitalize">
                                  {userData.Attendance.isSpouseComing
                                    ? "Yes"
                                    : "No"}
                                </div>
                              </div>

                              <div className="grid grid-cols-2">
                                <div className="px-4 py-2  capitalize font-semibold">
                                  Children
                                </div>
                                <div className="px-4 py-2  capitalize">
                                  {userData.Attendance.childrenCount}
                                </div>
                              </div>

                              <div className="grid grid-cols-2">
                                <div className="px-4 py-2  capitalize font-semibold">
                                  Address
                                </div>
                                <div className="px-4 py-2  capitalize">
                                  {userData?.Personal.permanentAddress},{" "}
                                  {userData?.Personal.permanentCity},{" "}
                                  {userData?.Personal.permanentState},
                                  {userData?.Personal.permanentCountry}
                                </div>
                              </div>
                            </div>
                            <div className="grid ">
                              <div className="flex justify-end">
                                <QRCode
                                  className="qr-code h-[180px] w-[180px]"
                                  value={
                                    envConfig.BASE_URL +
                                    `/verify/` +
                                    userData?.userId
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="my-2"></div>
                    {/* instruvtuon */}
                    <div className="px-8">
                      <ul className="font-sm">
                        <li className="font-semibold">Instructions - </li>
                        <li>
                          1. Please take the printout of this pass before coming
                          to attend Loving child father’s day.
                        </li>
                        <li>
                          2. Please park your vehicles in the parking opposite
                          gate no. 1
                        </li>
                        <li>
                          3. Please do not stand/walk on the road in front of
                          Dera Sacha Sauda.
                        </li>
                        <li>
                          4. No electronic items are allowed. You need to
                          deposit all your electronic belongings near gate no. 1
                        </li>
                        <li>
                          5. Please maintain decorum and follow instructions by
                          on-duty Sewadars.
                        </li>
                        <li> </li>
                        <li className="font-semibold text-center mt-2">
                          Please do bring your warm clothes with you.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex w-full  justify-center p-2">
                <ReactToPrint
                  trigger={() => (
                    <button className="mx-1 bg-red-500 rounded-md p-1 px-3 text-white text-md font-normal cursor-pointer">
                      Print Profile
                    </button>
                  )}
                  content={() => printRef.current}
                  documentTitle="Detail Page"
                />
              </div>
            </>
          ) : (
            <>
              <div className="flex w-full justify-center h-50">
                {/* <p className='tracking-wide text-red-500'>Loading.....</p> */}
              </div>
              <div className="p-6 space-y-6">
                <div className="p-3 w-fit mx-auto rounded-full bg-red-500">
                  <FaCheck className="text-2xl text-white" />
                </div>
                <p className="text-lg leading-relaxed font-medium text-center text-gray-500 dark:text-gray-400">
                  Hold on,While We are Processing your Information.
                </p>
                <p className="text-base text-center leading-relaxed text-gray-500 dark:text-gray-400">
                  We are Processing your Information. Once your information is
                  verified then you will receive E-pass via SMS.
                </p>
              </div>
              {/* <!-- Modal footer --> */}
            </>
          )}{" "}
        </>
      )}
    </>
  );
};

export default GetAllData;
