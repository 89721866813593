import React, { useState, useEffect } from "react";
import ButtonsLayout from "../ButtonsLayout";
import { API } from "../../shared/utility";
import { errorToast } from "../../shared/Service";
import Spinner from "../Spinner";
import {
  degreeOrDiplomaName,
  boysInstitutes,
  girlsInstitutes,
  institutes,
} from "../../shared/data";

const EducationDetails = ({ currentTab, changeTab, setShowSuccessModal }) => {
  const [data, setData] = useState({
    enrollmentInstitute: "",
    enrollmentClass: "",
    enrollmentYear: "",
    enrollmentAdmissionNumber: "",
    passoutInstitute: "",
    passoutClass: "",
    passoutAdmissionNumber: "",
    passoutYear: "",
    instituteName: "",
    course: "",
    highestEducation: "",
    stream: "",
    highestYear: "",
  });
  const [gender, setGender] = useState("male");
  const [isLoading, setIsLoading] = useState(false);
  const [updateData, setUpdateData] = useState({});
  const [isNewRecord, setIsNewRecord] = useState(true);
  var today = new Date();
  var dd = today.getDate();
  var mm = today.getMonth() + 1; //January is 0!
  var yyyy = today.getFullYear();

  const GET_DATA_FROM_DB = async () => {
    setIsLoading(true);
    API.GET_EDUCATION_DETAIL()
      .then((res) => {
        if (res.status === 200) {
          res.data.data.length === 0
            ? setIsNewRecord(true)
            : setIsNewRecord(false);
          const {
            enrollmentInstitute,
            enrollmentClass,
            enrollmentYear,
            enrollmentAdmissionNumber,
            passoutInstitute,
            passoutClass,
            passoutAdmissionNumber,
            passoutYear,
            instituteName,
            course,
            highestEducation,
            stream,
            highestYear,
          } = res.data.data;

          const resData = {
            enrollmentInstitute,
            enrollmentClass,
            enrollmentYear,
            enrollmentAdmissionNumber,
            passoutInstitute,
            passoutClass,
            passoutAdmissionNumber,
            passoutYear,
            instituteName,
            course,
            highestEducation,
            stream,
            highestYear,
          };

          setData(resData);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);

        if (!isNewRecord) {
          errorToast(err.response.data.message);
        }

        console.log(err ? err.response.data.message : err.message);
      });
  };

  const GetPersonalDetail = async () => {
    setIsLoading(true);
    API.GET_PERSONAL_DETAIL()
      .then((res) => {
        setGender(res.data.data.gender);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);

        if (!isNewRecord) {
          errorToast(err.response.data.message);
        }
        console.log(err ? err.response.data.message : err.message);
      });
  };

  const handleInputChange = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  };

  const HandleUpdate = (event) => {
    if (event.target.value !== "") {
      setUpdateData({ ...updateData, [event.target.name]: event.target.value });
    } else {
      delete updateData[event.target.name];
    }
    handleInputChange(event);
  };

  useEffect(() => {
    !data?.exInstitute && GET_DATA_FROM_DB();
    // GetPersonalDetail();
  }, []);

  return (
    <div className={`${currentTab === 1 ? "block" : "hidden"}`}>
      {isLoading ? (
        <div className="w-full h-[50vh] flex items-center justify-center bg-white  rounded-lg">
          <Spinner />
        </div>
      ) : (
        <>
          <div className={`w-full flex-col bg-white  rounded-lg flex`}>
            <div className="p-3 sm:p-5">
              {/* Super-Student Detai ls */}
              <div className="md:flex-1">
                <h1 className="my-3 text-xl sm:my-4 sm:text-2xl text-gray-700">
                  Super-Student Details
                </h1>
                <form action="#" className="flex flex-col space-y-5">
                  {/* Enrolment */}
                  <h2 className="text-lg text-gray-700 underline">
                    Enrolment Details
                  </h2>

                  {/* Enrolment institute */}
                  <div className="flex w-full flex-col md:p-1 mb-4 md:mb-0">
                    <label
                      htmlFor="enrollmentInstitute"
                      className="text-sm capitalize   required"
                    >
                      Institute Name
                    </label>
                    <select
                      className="text-black px-3 sm:px-4 py-2 border-gray-300 border rounded-md bg-transparent"
                      name="enrollmentInstitute"
                      onChange={isNewRecord ? handleInputChange : HandleUpdate}
                    >
                      <option value="" defaultValue hidden>
                        --None--
                      </option>
                      {institutes.map((instituteName, index) => {
                        return (
                          <option
                            value={instituteName}
                            key={index}
                            selected={
                              data.enrollmentInstitute === instituteName
                            }
                          >
                            {instituteName}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  {/* Enrolment class */}
                  <div className="flex flex-col space-y-1">
                    <label
                      htmlFor="enrollmentClass"
                      className="text-sm capitalize  required"
                    >
                      Enrolment class
                    </label>
                    <input
                      type="text"
                      id="enrollmentClass"
                      name="enrollmentClass"
                      value={data.enrollmentClass}
                      onChange={isNewRecord ? handleInputChange : HandleUpdate}
                      required
                      className="px-4 py-2 transition duration-300 border border-gray-300 rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-blue-200"
                    />
                  </div>

                  {/* Enrolment year */}
                  <div className="flex flex-col space-y-1">
                    <label
                      htmlFor="enrollmentYear"
                      className="text-sm capitalize  required"
                    >
                      Enrolment year
                    </label>
                    <input
                      type="number"
                      id="enrollmentYear"
                      name="enrollmentYear"
                      min={1947}
                      max={yyyy}
                      value={data.enrollmentYear}
                      onChange={(e) => {
                        if (e.target.value * 1 > 2022) {
                          if (!isNewRecord) {
                            setUpdateData({
                              ...updateData,
                              enrollmentYear: "2022",
                            });
                          }
                          setData({ ...data, enrollmentYear: "2022" });
                        } else if (!isNewRecord) {
                          setUpdateData({
                            ...updateData,
                            enrollmentYear: e.target.value,
                          });
                          setData({ ...data, enrollmentYear: e.target.value });
                        } else {
                          setData({ ...data, enrollmentYear: e.target.value });
                        }
                      }}
                      required
                      className="px-4 peer py-2 transition duration-300 border border-gray-300 rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-blue-200"
                    />
                    <p className="mt-2 hidden peer-invalid:block text-red-500 text-sm capitalize">
                      max year should be {yyyy}
                    </p>
                  </div>

                  {/* Enrollment Admission number */}
                  <div className="flex flex-col space-y-1">
                    <label
                      htmlFor="enrollmentAdmissionNumber"
                      className="text-sm capitalize  required"
                    >
                      Admission number
                    </label>
                    <input
                      type="number"
                      id="enrollmentAdmissionNumber"
                      name="enrollmentAdmissionNumber"
                      value={data.enrollmentAdmissionNumber}
                      onChange={isNewRecord ? handleInputChange : HandleUpdate}
                      required
                      className="px-4 py-2 transition duration-300 border border-gray-300 rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-blue-200"
                    />
                  </div>

                  {/* Passout */}
                  <h2 className="text-lg text-gray-700 underline">
                    Passout Details
                  </h2>
                  {/* Name of the institute */}
                  <div className="flex w-full flex-col md:p-1 mb-4 md:mb-0">
                    <label
                      htmlFor="passoutInstitute"
                      className="text-sm capitalize   required"
                    >
                      Institute Name
                    </label>
                    <select
                      className="text-black px-3 sm:px-4 py-2 border-gray-300 border rounded-md bg-transparent"
                      name="passoutInstitute"
                      onChange={isNewRecord ? handleInputChange : HandleUpdate}
                    >
                      <option value="" defaultValue hidden>
                        --None--
                      </option>
                      {institutes.map((instituteName, index) => {
                        return (
                          <option
                            value={instituteName}
                            key={index}
                            selected={data.passoutInstitute === instituteName}
                          >
                            {instituteName}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  {/* Passout class */}
                  <div className="flex flex-col space-y-1">
                    <label
                      htmlFor="passoutClass"
                      className="text-sm capitalize  required"
                    >
                      Passout class
                    </label>
                    <input
                      type="text"
                      id="passoutClass"
                      name="passoutClass"
                      value={data.passoutClass}
                      onChange={isNewRecord ? handleInputChange : HandleUpdate}
                      required
                      className="px-4 py-2 transition duration-300 border border-gray-300 rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-blue-200"
                    />
                  </div>

                  {/* Passout year */}
                  <div className="flex flex-col space-y-1">
                    <label
                      htmlFor="passoutYear"
                      className="text-sm capitalize  required"
                    >
                      Passout year
                    </label>
                    <input
                      type="number"
                      id="passoutYear"
                      name="passoutYear"
                      min={1947}
                      max={yyyy}
                      value={data.passoutYear}
                      onChange={(e) => {
                        if (e.target.value * 1 > 2022) {
                          if (!isNewRecord) {
                            setUpdateData({
                              ...updateData,
                              passoutYear: "2022",
                            });
                          }
                          setData({ ...data, passoutYear: "2022" });
                        } else if (!isNewRecord) {
                          setUpdateData({
                            ...updateData,
                            passoutYear: e.target.value,
                          });
                          setData({ ...data, passoutYear: e.target.value });
                        } else {
                          setData({ ...data, passoutYear: e.target.value });
                        }
                      }}
                      required
                      className="px-4 peer py-2 transition duration-300 border border-gray-300 rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-blue-200"
                    />

                    <p className="mt-2 hidden peer-invalid:block text-red-500 text-sm capitalize">
                      max year should be {yyyy}
                    </p>
                  </div>

                  {/*Enrollment Admission number */}
                  <div className="flex flex-col space-y-1">
                    <label
                      htmlFor="passoutAdmissionNumber"
                      className="text-sm capitalize  required"
                    >
                      Admission number
                    </label>
                    <input
                      type="number"
                      id="passoutAdmissionNumber"
                      name="passoutAdmissionNumber"
                      value={data.passoutAdmissionNumber}
                      onChange={isNewRecord ? handleInputChange : HandleUpdate}
                      required
                      className="px-4 py-2 transition duration-300 border border-gray-300 rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-blue-200"
                    />
                  </div>

                  {/* highest/pursuing study details */}
                  <div className="md:flex-1">
                    <h1 className="my-3 text-xl sm:my-4 sm:text-2xl text-gray-700">
                      Highest/Pursuing study details
                    </h1>
                    <form action="" className="flex flex-col space-y-5">
                      {/* Which degree/diploma name you currently pursuing or
                          done? */}
                      <div className="flex flex-col space-y-1">
                        <label
                          htmlFor="highestEducation"
                          className="text-sm capitalize   "
                        >
                          Which degree/diploma name you currently pursuing or
                          done?
                        </label>
                        <select
                          className="text-black p-3 border-gray-300 border rounded-md bg-transparent outline-none"
                          name="highestEducation"
                          // value={data.highestEducation}
                          onChange={
                            isNewRecord ? handleInputChange : HandleUpdate
                          }
                        >
                          <option value="" defaultValue hidden>
                            -None-
                          </option>
                          {degreeOrDiplomaName.map((name, index) => {
                            return (
                              <option
                                value={name}
                                key={index}
                                className={"capitalize"}
                                selected={name === data.highestEducation}
                              >
                                {name}
                              </option>
                            );
                          })}
                        </select>
                      </div>

                      {/* Stream name */}
                      <div className="flex flex-col space-y-1">
                        <label
                          htmlFor="stream"
                          className="text-sm capitalize   required"
                        >
                          Stream Name
                        </label>
                        <input
                          type="text"
                          id="stream"
                          name="stream"
                          value={data.stream}
                          onChange={
                            isNewRecord ? handleInputChange : HandleUpdate
                          }
                          required
                          className="px-4 py-2 transition duration-300 border border-gray-300 rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-blue-200"
                        />
                      </div>

                      {/* Course name */}
                      <div className="flex flex-col space-y-1">
                        <label
                          htmlFor="course"
                          className="text-sm capitalize   required"
                        >
                          Course Name
                        </label>
                        <input
                          type="text"
                          id="course"
                          name="course"
                          value={data.course}
                          onChange={
                            isNewRecord ? handleInputChange : HandleUpdate
                          }
                          required
                          className="px-4 py-2 transition duration-300 border border-gray-300 rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-blue-200"
                        />
                      </div>

                      {/* Institute name */}
                      <div className="flex flex-col space-y-1">
                        <label
                          htmlFor="instituteName"
                          className="text-sm capitalize   required"
                        >
                          Name of the institute
                        </label>
                        <input
                          type="text"
                          id="instituteName"
                          name="instituteName"
                          value={data.instituteName}
                          onChange={
                            isNewRecord ? handleInputChange : HandleUpdate
                          }
                          required
                          className="px-4 py-2 transition duration-300 border border-gray-300 rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-blue-200"
                        />
                      </div>

                      {/* Year */}
                      <div className="flex flex-col space-y-1">
                        <label
                          htmlFor="highestYear"
                          className="text-sm capitalize   required"
                        >
                          Year
                        </label>
                        <input
                          type="number"
                          id="highestYear"
                          name="highestYear"
                          min={1947}
                          max={yyyy}
                          value={data.highestYear}
                          onChange={(e) => {
                            if (e.target.value * 1 > 2022) {
                              if (!isNewRecord) {
                                setUpdateData({
                                  ...updateData,
                                  highestYear: "2022",
                                });
                              }
                              setData({ ...data, highestYear: "2022" });
                            } else if (!isNewRecord) {
                              setUpdateData({
                                ...updateData,
                                highestYear: e.target.value,
                              });
                              setData({ ...data, highestYear: e.target.value });
                            } else {
                              setData({ ...data, highestYear: e.target.value });
                            }
                          }}
                          required
                          className="px-4 peer py-2 transition duration-300 border border-gray-300 rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-blue-200"
                        />

                        <p className="mt-2 hidden peer-invalid:block text-red-500 text-sm capitalize">
                          max year should be {yyyy}
                        </p>
                      </div>
                    </form>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="my-5 py-1  bg-white  rounded-lg">
            <ButtonsLayout
              currentTab={currentTab}
              changeTab={changeTab}
              data={data}
              saveButtonDisabled={false}
              showSaveButton={isNewRecord}
              updateData={updateData}
              setShowSuccessModal={setShowSuccessModal}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default EducationDetails;
