import React, { useMemo } from "react";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";

export const DOTS = "...";

const range = (start, end) => {
  let length = end - start + 1;
  return Array.from({ length }, (_, idx) => idx + start);
};

const Pagination = ({
  totalDocuments,
  size,
  setPage,
  page,
  siblingCount = 1,
}) => {
  const totalPageCount = Math.ceil(totalDocuments / size);

  const handlePrevious = () => {
    if (page !== 1) {
      setPage(page - 1);
    }
  };

  const handleNext = () => {
    if (page !== totalPageCount) {
      setPage(page + 1);
    }
  };

  const paginationRange = useMemo(() => {
    // Pages count is determined as siblingCount + firstPage + lastPage + page + 2*DOTS
    const totalPageNumbers = siblingCount + 5;

    /*
      Case 1:
      If the number of pages is less than the page numbers we want to show in our
      paginationComponent, we return the range [1..totalPageCount]
    */
    if (totalPageNumbers >= totalPageCount) {
      return range(1, totalPageCount);
    }

    /*
    	Calculate left and right sibling index and make sure they are within range 1 and totalPageCount
    */
    const leftSiblingIndex = Math.max(page - siblingCount, 1);
    const rightSiblingIndex = Math.min(page + siblingCount, totalPageCount);

    /*
      We do not show dots just when there is just one page number to be inserted between the extremes of sibling and the page limits i.e 1 and totalPageCount. Hence we are using leftSiblingIndex > 2 and rightSiblingIndex < totalPageCount - 2
    */
    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;

    const firstPageIndex = 1;
    const lastPageIndex = totalPageCount;

    /*
    	Case 2: No left dots to show, but rights dots to be shown
    */
    if (!shouldShowLeftDots && shouldShowRightDots) {
      let leftItemCount = 3 + 2 * siblingCount;
      let leftRange = range(1, leftItemCount);

      return [...leftRange, DOTS, totalPageCount];
    }

    /*
    	Case 3: No right dots to show, but left dots to be shown
    */
    if (shouldShowLeftDots && !shouldShowRightDots) {
      let rightItemCount = 3 + 2 * siblingCount;
      let rightRange = range(
        totalPageCount - rightItemCount + 1,
        totalPageCount
      );
      return [firstPageIndex, DOTS, ...rightRange];
    }

    /*
    	Case 4: Both left and right dots to be shown
    */
    if (shouldShowLeftDots && shouldShowRightDots) {
      let middleRange = range(leftSiblingIndex, rightSiblingIndex);
      return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
    }
  }, [siblingCount, page, totalDocuments, size]);

  return (
    <div className="px-4 flex items-center justify-between">
      <div className="text-sm">
        Showing {1 + (page - 1) * 10} to{" "}
        {totalDocuments < size * page ? totalDocuments : size * page} of{" "}
        {totalDocuments} entries
      </div>
      <div className="flex flex-row flex-nowrap items-center justify-end">
        <span
          className={`flex w-8 h-8 sm:w-10 sm:h-10 mr-1 justify-center items-center text-black ${
            page === 1 ? "cursor-not-allowed" : "cursor-pointer"
          }`}
          onClick={handlePrevious}
        >
          <FiChevronLeft />
        </span>
        {paginationRange.map((pageNumber) => {
          // If the pageItem is a DOT, render the DOTS unicode character
          if (pageNumber === DOTS) {
            return <span>&#8230;</span>;
          }

          // Render our Page Pills
          return (
            <span
              className={`flex w-8 h-8 sm:w-10 sm:h-10 text-sm sm:text-base mx-1 justify-center items-center rounded-md border border-gray-200  hover:border-gray-300 cursor-pointer ${
                page === pageNumber
                  ? `bg-red-600 text-white hover:bg-red-500`
                  : `bg-white text-gray-600`
              }`}
              onClick={() => {
                setPage(pageNumber);
              }}
            >
              {pageNumber}
            </span>
          );
        })}
        {/* {[...Array(totalPageCount)].map((item, index) => {
          return (
            <span
              className={`flex w-8 h-8 sm:w-10 sm:h-10 text-sm sm:text-base mx-1 justify-center items-center rounded-md border border-gray-200  hover:border-gray-300 cursor-pointer ${
                page === index + 1
                  ? `bg-red-600 text-white hover:bg-red-500`
                  : `bg-white text-gray-600`
              }`}
              onClick={() => {
                setPage(index + 1);
              }}
            >
              {index + 1}
            </span>
          );
        })} */}

        <span
          className={`flex w-8 h-8 sm:w-10 sm:h-10 mr-1 justify-center items-center text-black ${
            page === totalPageCount ? "cursor-not-allowed" : "cursor-pointer"
          }`}
          onClick={handleNext}
        >
          <FiChevronRight />
        </span>
      </div>
    </div>
  );
};

export default Pagination;
