import React from "react";
import { FaCheck } from "react-icons/fa";

const SuccessModal = ({ showSuccessModal, setShowSuccessModal }) => {
  return (
    <div className="flex items-center justify-center">
      {/* <!-- Main modal --> */}
      <div
        id="defaultModal"
        tabindex="-1"
        aria-hidden="true"
        className={`
        
        overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full justify-center items-center`}
      >
        <div className="mx-auto mt-28 relative p-4 w-full max-w-2xl h-full md:h-auto">
          {/* <!-- Modal content --> */}
          <div className="relative bg-white border rounded-lg shadow dark:bg-gray-700">
            {/* <!-- Modal header --> */}
            <div className="flex justify-between items-start p-4 rounded-t border-b dark:border-gray-600">
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                Success
              </h3>
              {/* <button
								type='button'
								className='text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white'
								data-modal-toggle='defaultModal'
								onClick={() => setShowSuccessModal(false)}>
								<svg aria-hidden='true' className='w-5 h-5' fill='currentColor' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
									<path
										fill-rule='evenodd'
										d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'
										clip-rule='evenodd'></path>
								</svg>
								<span className='sr-only'>Close modal</span>
							</button> */}
            </div>
            {/* <!-- Modal body --> */}
            <div className="p-6 space-y-6">
              <div className="p-3 w-fit mx-auto rounded-full bg-red-500">
                <FaCheck className="text-2xl text-white" />
              </div>
              <p className="text-lg leading-relaxed font-medium text-center text-gray-500 dark:text-gray-400">
                Thanks for your response.
              </p>
              <p className="text-base text-center leading-relaxed text-gray-500 dark:text-gray-400">
                Your information has been submitted successfully. Once your
                information is verified then you will receive E-pass via SMS.
              </p>
            </div>
            {/* <!-- Modal footer --> */}
            {/* <div className='flex items-center justify-end p-6 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600'>
							<button
								data-modal-toggle='defaultModal'
								type='button'
								className='text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600'
								onClick={() => setShowSuccessModal(false)}>
								Close
							</button>
						</div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessModal;
