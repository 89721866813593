import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaUserAlt, FaPowerOff } from "react-icons/fa";
import { errorToast } from "../shared/Service";
import { API } from "../shared/utility";

const Navbar = () => {
  const navigate = useNavigate();

  const [isMobileView, setIsMobileView] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isDisable, setIsDisable] = useState(true);
  const [isTradeMobileMenuOpen, setIsTradeMobileMenuOpen] = useState(false);
  const [isProfileMobileMenuOpen, setIsProfileMobileMenuOpen] = useState(false);

  const location = useLocation();

  useEffect(() => {
    window.addEventListener("resize", function () {
      if (window.innerWidth > 800) {
        setIsMobileView(false);
      } else {
        setIsMobileView(true);
      }
    });

    if (window.innerWidth > 800) setIsMobileView(false);
    else setIsMobileView(true);
    if (localStorage.getItem("Authtoken")) setIsLoggedIn(true);
    else setIsLoggedIn(false);
  }, []);

  const center = "mx-1 px-2 font-normal cursor-pointer";
  const left =
    "mx-1 bg-red-500 rounded-md p-1 px-3 text-white text-md font-normal cursor-pointer";
  const mobile = "m-2 font-normal cursor-pointer";
  const hover =
    "font-normal cursor-pointer p-3  hover:bg-gray-400 hover:bg-opacity-10 text-md grid grid-flow-col justify-start items-center gap-2";

  useEffect(() => {
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    if (userDetails?.user?.role === "superadmin") {
      setIsAdmin(true);
    }
  }, []);

  const isActiveMenu = (url) => {
    return location.pathname?.toLowerCase() == url.toLowerCase();
  };

  return (
    <div className="bg-red-100 py-4 mb-1">
      {/* <div className="bg-red-100 py-4 shadow shadow-black "> */}
      <div className="w-[90%] md:max-w-6xl m-auto nav">
        {/* {!isMobileView ? ( */}
        <div className=" text-black items-center flex justify-between">
          {/* <p className="font-bold text-2xl">Logo</p> */}
          <Link to="/" className="items-center flex">
            {/* <div className='h-10 w-10 bg-red-500 rounded-lg'></div> */}
            <p className="ml-4">
              {isAdmin
                ? "Super-Student Admin Dashboard"
                : "Super-Student Portal"}
            </p>
          </Link>

          {!isLoggedIn ? (
            <div>
              <ul className="flex items-center">
                <li className={`${left}`}>
                  <Link to="/login">Login</Link>
                </li>
                <li
                  className={`${left} rounded-md text-white bg-red-500 p-1 px-2`}
                >
                  <Link to="/register">Register</Link>
                </li>
              </ul>
            </div>
          ) : (
            <div>
              <ul className="flex items-center">
                {/* <li className={`${left}  ${isDisable && `bg-gray-400 text-white `}`} onClick={!isDisable ? () => navigate('/profile') : () => errorToast('Please fill details first')}>
									Profile
								</li> */}
                <li
                  className={`${left}`}
                  onClick={() => {
                    localStorage.clear();
                    navigate("/login");
                  }}
                >
                  Logout
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
