import React, { useEffect, useState } from "react";
import ButtonsLayout from "../ButtonsLayout";
import { API } from "../../shared/utility";
import { errorToast } from "../../shared/Service";
import Spinner from "../Spinner";
import axios from "axios";

const FamilyDetails = ({ currentTab, changeTab, setShowSuccessModal }) => {
  const [data, setData] = useState({
    isSpouseComing: false,
    childrenCount: 0,
    image: null,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [updateData, setUpdateData] = useState({});
  const [isNewRecord, setIsNewRecord] = useState(true);
  const [maritalStatus, setMaritalStatus] = useState(false);

  const GetPersonalDetail = async () => {
    setIsLoading(true);
    API.GET_PERSONAL_DETAIL()
      .then((res) => {
        // setData({ ...data, maritalStatus: res.data.data.maritalStatus });
        setMaritalStatus(res.data.data.maritalStatus);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);

        if (!isNewRecord) {
          errorToast(err.response.data.message);
        }
        console.log(err);
      });
  };

  const saveButtonDisabled = () => {
    return false;
  };

  const GET_DATA_FROM_DB = async () => {
    setIsLoading(true);
    API.GET_FAMILY_DETAIL()
      .then((res) => {
        if (res.status == 200) {
          res.data.data.length === 0
            ? setIsNewRecord(true)
            : setIsNewRecord(false);

          const { isSpouseComing, childrenCount, familyPicUrl } = res.data.data;

          const resData = {
            isSpouseComing,
            childrenCount,
            image: familyPicUrl,
          };
          setData(resData);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        if (!isNewRecord) {
          errorToast(err.response.data.message);
        }
        console.log(err);
      });
  };

  const handleInputChange = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  };
  const HandleUpdate = (event) => {
    if (event.target.value !== "") {
      setUpdateData({ ...updateData, [event.target.name]: event.target.value });
    } else {
      delete updateData[event.target.name];
    }
    handleInputChange(event);
  };

  useEffect(() => {
    GET_DATA_FROM_DB();
    GetPersonalDetail();
  }, []);

  return (
    <div className={`${currentTab === 4 ? "block" : "hidden"}`}>
      {isLoading ? (
        <div className="w-full h-[50vh] flex items-center justify-center bg-white  rounded-lg">
          <Spinner />
        </div>
      ) : (
        <>
          <div className={`w-full flex-col bg-white  rounded-lg flex`}>
            {/* CURRENT POSITION DETAILS */}
            <div className="p-3 sm:p-5 md:flex-1">
              <h1 className="mt-3 text-xl sm:text-2xl text-gray-700">
                Family Details
              </h1>

              <div className="my-3 text-xs text-black bg-red-200 p-2 rounded-md">
                <span className="font-medium mr-2">Note:</span>
                Please enter the details of family members (Spouse and Children
                only) who will be attending "Loving Child Father's Day"
              </div>
              <form action="#" className="flex flex-col space-y-5">
                {/* Spouse Name  */}
                <div className={`flex flex-row items-center space-y-1`}>
                  <label
                    htmlFor="isSpouseComing"
                    className="text-sm capitalize mr-2 "
                  >
                    Spouse
                  </label>
                  <input
                    type="checkbox"
                    name="isSpouseComing"
                    // value={data.isSpouseComing}
                    checked={data.isSpouseComing}
                    disabled={!maritalStatus}
                    onChange={(e) => {
                      //   console.log(e.target.value);
                      if (!isNewRecord) {
                        setUpdateData({
                          ...updateData,
                          isSpouseComing: !data.isSpouseComing,
                        });
                      }
                      setData({
                        ...data,
                        isSpouseComing: !data.isSpouseComing,
                      });
                    }}
                    className="text-base px-4 py-2 transition duration-300 border border-gray-300 rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-blue-200"
                  />
                </div>

                {/* Children Count  */}
                <div className={`flex flex-col space-y-1`}>
                  <label
                    htmlFor="childrenCount"
                    className="text-sm capitalize   "
                  >
                    Children Count:
                  </label>
                  <input
                    type="number"
                    name="childrenCount"
                    value={data.childrenCount}
                    onChange={isNewRecord ? handleInputChange : HandleUpdate}
                    disabled={!maritalStatus}
                    className="px-4 py-2 transition duration-300 border border-gray-300 rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-blue-200"
                  />
                </div>

                {/* Family Photograph  */}
                <div className={`flex flex-col space-y-1`}>
                  <label htmlFor="image" className="text-sm capitalize ">
                    Family photograph (Face should be clearly visible)
                  </label>
                  <input
                    type="file"
                    name="image"
                    disabled={!maritalStatus}
                    onChange={(e) => {
                      if (e.target.files[0].size > 5 * 1024 * 1024) {
                        errorToast("Image size should be less than 5 MB!");
                        return;
                      }
                      if (!isNewRecord) {
                        setUpdateData({
                          ...updateData,
                          image: e.target.files[0],
                        });
                      }
                      setData({ ...data, image: e.target.files[0] });
                    }}
                    className="px-4 py-2 transition duration-300 border border-gray-300 rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-blue-200"
                    required
                  />
                </div>
              </form>
            </div>
          </div>
          <div className="my-5 py-1  bg-white  rounded-lg">
            <ButtonsLayout
              currentTab={currentTab}
              changeTab={changeTab}
              data={data}
              saveButtonDisabled={false}
              showSaveButton={isNewRecord}
              updateData={updateData}
              maritalStatus={maritalStatus}
              setShowSuccessModal={setShowSuccessModal}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default FamilyDetails;
