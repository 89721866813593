import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Navbar from '../components/Navbar';
import Details from '../screens/Details';
import Profile from '../screens/Profile';
import GetAllData from '../screens/GetAllData';
import Home from '../screens/Home';
import Login from '../screens/Login';
import Register from '../screens/Register';
import PrivateRoutes from './PrivateRoutes';
import {Toaster} from 'react-hot-toast';
import AllUsers from '../screens/admin/AllUsers';
import UserProfile from '../screens/admin/UserProfile';
import VerifyUserDetail from '../screens/VerifyUserDetail';
import NotFound from '../screens/NotFound';

const InsideRoutes = () => {
	return (
		<>
			<Navbar />
			<Routes>
				<Route path='/' element={<PrivateRoutes Component={Details} />} />
				<Route path='/profile' element={<PrivateRoutes Component={Profile} />} />
				<Route path='/users/:id' element={<PrivateRoutes Component={UserProfile} />} />
				<Route path='/users' element={<PrivateRoutes Component={AllUsers} />} />
				<Route path='/*' element={<NotFound />} />
			</Routes>
		</>
	);
};

const MyRoutes = () => {
	return (
		<div>
			<Router>
				<Routes>
					<Route path='/login' element={<Login />} />
					<Route path='/register' element={<Register />} />
					<Route path='/me/:id' element={<GetAllData />} />
					<Route path='/verify/:id' element={<VerifyUserDetail />} />
					<Route path='/*' element={<InsideRoutes />} />
				</Routes>
			</Router>
			<Toaster
				position='top-right'
				reverseOrder={false}
				gutter={8}
				toastOptions={{
					duration: 5000,
					style: {
						background: '#363636',
						color: '#fff',
					},
					success: {
						duration: 5000,
						theme: {
							primary: 'blue',
							secondary: 'black',
						},
					},
				}}
			/>
		</div>
	);
};

export default MyRoutes;
