import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { API } from "../../shared/utility";
import Spinner from "../../components/Spinner";
import { successToast, errorToast } from "../../shared/Service";
import { envConfig } from "../../shared/config";

const UserProfile = () => {
  const { id } = useParams();
  const [selectedSection, setSelectedSection] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState("inactive");
  const [personalDetailsData, setPersonalDetailsData] = useState({
    name: "",
    email: "",
    maritalStatus: false,
    gender: "",
    callingPhoneNo: "",
    whatsappPhoneNo: "",
    currentCountry: "",
    currentState: "",
    currentCity: "",
    currentAddress: "",
    permanentAddress: "",
    permanentCountry: "",
    permanentState: "",
    permanentCity: "",
    dob: "",
    fatherName: "",
    imageUrl: null,
  });
  const [educationalDetailsData, setEducationalDetailsData] = useState({
    enrollmentInstitute: "",
    enrollmentClass: "",
    enrollmentYear: "",
    enrollmentAdmissionNumber: "",
    passoutInstitute: "",
    passoutClass: "",
    passoutAdmissionNumber: "",
    passoutYear: "",
    instituteName: "",
    course: "",
    highestEducation: "",
    stream: "",
    highestYear: "",
  });
  const [professionalDetailsData, setProfessionalDetailsData] = useState({
    currentProfession: "",
    department: "",
    sector: "",
    address: "",
    designation: "",
    state: "",
    country: "",
    city: "",
  });
  const [sewaDetailsData, setSewaDetailsData] = useState({
    isPakkiSamitiMember: "",
    pakkiSamiti: "",
    sewadarSince: "",
    skills: "",
    sewaAvailability: "",
  });

  const [familyDetailsData, setFamilyDetailsData] = useState({
    isSpouseComing: null,
    childrenCount: "",
    imageUrl: null,
  });

  const navigate = useNavigate();
  useEffect(() => {
    GetUserDetail();
  }, [0]);

  const GetUserDetail = async () => {
    setIsLoading(true);
    API.GET_USER_DETAIL(id)
      .then((res) => {
        //   setUserDetailData(userDetails);
        const data = res.data.data;
        setStatus(data.status);
        setPersonalDetailsData(data.Personal);
        setEducationalDetailsData(data.Education);
        setProfessionalDetailsData(data.Profession);
        setSewaDetailsData(data.Welfare);
        setFamilyDetailsData(data.Attendance);
        setIsLoading(false);
      })
      .catch((err) => {
        errorToast(err.response.data.message);
        setIsLoading(false);
      });
  };

  const approveUser = async () => {
    setIsLoading(true);
    const data = { userId: id };
    API.APPROVE_USER(data)
      .then((res) => {
        setStatus("active");
        successToast("User Approved Successfully!");
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);

        errorToast(err.response.data.message);
        // console.log(err ? err.response.data.message : err.message);
      });
  };
  const unapproveUser = async () => {
    setIsLoading(true);
    const data = { userId: id };
    API.UNAPPROVE_USER(data)
      .then((res) => {
        setStatus("inactive");
        successToast("User unapproved successfully!");
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);

        errorToast(err.response.data.message);
        // console.log(err ? err.response.data.message : err.message);
      });
  };

  // const GetPersonalDetail = async () => {
  //   setIsLoading(true);
  //   API.GET_PERSONAL_DETAIL()
  //     .then((res) => {
  //       if (res.status === 200) {
  //         const {
  //           name,
  //           email,
  //           maritalStatus,
  //           gender,
  //           callingPhoneNo,
  //           whatsappPhoneNo,
  //           currentCountry,
  //           currentState,
  //           currentCity,
  //           currentAddress,
  //           permanentAddress,
  //           permanentCountry,
  //           permanentState,
  //           permanentCity,
  //           dob,
  //           fatherName,
  //           image,
  //         } = res.data.data;

  //         const personalDetails = {
  //           name,
  //           email,
  //           maritalStatus,
  //           gender,
  //           callingPhoneNo,
  //           whatsappPhoneNo,
  //           currentCountry,
  //           currentState,
  //           currentCity,
  //           currentAddress,
  //           permanentAddress,
  //           permanentCountry,
  //           permanentState,
  //           permanentCity,
  //           dob,
  //           fatherName,
  //           image: image,
  //         };
  //         setPersonalDetailsData(personalDetails);
  //         GetEducationalDetail();

  //         setIsLoading(false);
  //       } else {
  //       }
  //     })
  //     .catch((err) => {
  //       setIsLoading(false);
  //       errorToast(err.response.data.message);
  //     });
  // };
  // const GetEducationalDetail = async () => {
  //   setIsLoading(true);
  //   API.GET_EDUCATION_DETAIL()
  //     .then((res) => {
  //       setEducationalDetailsData(res.data.data);
  //       GetProfessionalDetail();
  //       setIsLoading(false);
  //     })
  //     .catch((err) => {
  //       setIsLoading(false);
  //       errorToast(err.response.data.message);
  //     });
  // };
  // const GetProfessionalDetail = async () => {
  //   setIsLoading(true);
  //   API.GET_PROFESSION_DETAIL()
  //     .then((res) => {
  //       setProfessionalDetailsData(res.data.data);
  //       GetSewaDetails();
  //       setIsLoading(false);
  //     })
  //     .catch((err) => {
  //       setIsLoading(false);
  //       errorToast(err.response.data.message);
  //     });
  // };
  // const GetSewaDetails = async () => {
  //   setIsLoading(true);
  //   API.GET_WELFARE_DETAIL()
  //     .then((res) => {
  //       setSewaDetailsData(res.data.data);
  //       setIsLoading(false);
  //     })
  //     .catch((err) => {
  //       setIsLoading(false);
  //       errorToast(err.response.data.message);
  //     });
  // };
  const getSelectedTab = () => {
    switch (selectedSection) {
      case 0:
        return <Personal personalDetailsData={personalDetailsData} />;
      case 1:
        return <Education educationalDetailsData={educationalDetailsData} />;
      case 2:
        return (
          <Professional professionalDetailsData={professionalDetailsData} />
        );
      case 3:
        return <Welfare sewaDetailsData={sewaDetailsData} />;
      case 4:
        return <Family familyDetailsData={familyDetailsData} />;

      default:
        return <Personal personalDetailsData={personalDetailsData} />;
    }
  };

  const sectionSelectorStyle =
    "px-2 sm:px-3 md:px-6 pb-black text-sm whitespace-nowrap sm:text-lg md:text-xl border-black hover:border-black   cursor-pointer";
  return (
    <>
      {isLoading ? (
        <div className="w-full h-[100vh] flex items-center justify-center  bg-white  rounded-lg">
          <Spinner />
        </div>
      ) : (
        <div className=" h-full min-h-[100vh] w-full bg-red-100 py-10 rounded-lg">
          <div className="w-[90%] md:max-w-6xl m-auto">
            <div className="flex items-center justify-between mb-6">
              <h1 className="text-2xl w-fit">User Profile</h1>
              <button
                className={`mx-1 bg-red-500 rounded-md p-1 px-3 text-white text-md font-normal cursor-pointer`}
                onClick={status === "active" ? unapproveUser : approveUser}
              >
                {status === "active" ? "Unapprove" : "Approve"}
              </button>
            </div>
            {/* <div className="flex bg-white  mx-auto shadow-lg rounded-lg ">
              <div className="flex flex-col w-full">
                <div className="p-4 md:px-10 md:py-4 border-b-2 border-gray-200">
                  <div className="text-lg text-gray-700 mb-3">User Details</div>
                  <div className="md:flex items-center justify-between ">
                    <div className="flex items-center">
                      <span className="text-gray-400 text-md">Name:</span>
                      <span className="text-md text-gray-700 ml-1">
                        {personalDetailsData.name}
                      </span>
                    </div>
                    <div className="flex items-center">
                      <span className="text-gray-400 text-md">
                        Date of birth:
                      </span>
                      <span className="text-md text-gray-700 ml-1">
                        {formateDate(personalDetailsData.dob)}
                      </span>
                    </div>
                    <div className="flex items-center">
                      <span className="text-gray-400 text-md">Gender:</span>
                      <span className="text-md text-gray-700 ml-1">
                        {personalDetailsData.gender}
                      </span>
                    </div>
                    <div className="flex items-center">
                      <span className="text-gray-400 text-md">
                        Marital Status:
                      </span>
                      <span className="text-md text-gray-700 ml-1">
                        {personalDetailsData.maritalStatus}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="p-4 md:px-10 md:py-4 border-b-2 border-gray-200">
                  <div className="text-lg text-gray-700 mb-3">
                    Contact Details
                  </div>
                  <div className="md:flex items-center justify-between ">
                    <div className="flex my-1 items-center">
                      <span className="text-gray-400 text-md">
                        Phone (Calling):
                      </span>
                      <span className="text-md text-gray-700 ml-1">
                        {personalDetailsData.callingPhoneNo}
                      </span>
                    </div>
                    <div className="flex my-1 items-center">
                      <span className="text-gray-400 text-md">
                        Phone (WhatsApp):
                      </span>
                      <span className="text-md text-gray-700 ml-1">
                        {personalDetailsData.whatsappPhoneNo}
                      </span>
                    </div>
                    <div className="flex my-1 items-center justify-between">
                      <div className="flex items-center">
                        <span className="text-gray-400 text-md">
                          Email Address:
                        </span>
                        <span className="text-md text-gray-700 ml-1">
                          {personalDetailsData.email}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="p-4 md:px-10 md:py-4">
                  <div className="text-lg text-gray-700 mb-2">
                    Address Details
                  </div>
                  <div className="md:flex items-center justify-between">
                    <div className="flex items-center">
                      <span className="text-gray-400 text-md">Address:</span>
                      <span className="text-md text-gray-700 ml-1">
                        {personalDetailsData.address}
                      </span>
                    </div>
                    <div className="flex items-center">
                      <span className="text-gray-400 text-md">City:</span>
                      <span className="text-md text-gray-700 ml-1">
                        {personalDetailsData.city}
                      </span>
                    </div>
                    <div className="flex items-center">
                      <span className="text-gray-400 text-md">State:</span>
                      <span className="text-md text-gray-700 ml-1">
                        {personalDetailsData.state}
                      </span>
                    </div>
                    <div className="flex items-center">
                      <span className="text-gray-400 text-md">Country:</span>
                      <span className="text-md text-gray-700 ml-1">
                        {personalDetailsData.country}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            <div>
              <div className="flex overflow-x-scroll hide-scrollbar relative h-[50px] mt-16 mb-10">
                <div
                  className={
                    selectedSection === 0
                      ? `${sectionSelectorStyle} pl-0 sm:pl-2 border-b-4 border-blue-500`
                      : `${sectionSelectorStyle} pl-0 sm:pl-2 border-b-0`
                  }
                  onClick={() => setSelectedSection(0)}
                >
                  Personal
                </div>
                <div
                  className={
                    selectedSection === 1
                      ? `${sectionSelectorStyle} pl-0 sm:pl-2 border-b-4 border-blue-500`
                      : `${sectionSelectorStyle} pl-0 sm:pl-2 border-b-0`
                  }
                  onClick={() => setSelectedSection(1)}
                >
                  Education
                </div>
                <div
                  className={
                    selectedSection === 2
                      ? `${sectionSelectorStyle} border-b-4 border-blue-500`
                      : `${sectionSelectorStyle} border-b-0`
                  }
                  onClick={() => setSelectedSection(2)}
                >
                  Professional
                </div>
                <div
                  className={
                    selectedSection === 3
                      ? `${sectionSelectorStyle} border-b-4 border-blue-500`
                      : `${sectionSelectorStyle} border-b-0`
                  }
                  onClick={() => setSelectedSection(3)}
                >
                  Welfare
                </div>
                <div
                  className={
                    selectedSection === 4
                      ? `${sectionSelectorStyle} border-b-4 border-blue-500`
                      : `${sectionSelectorStyle} border-b-0`
                  }
                  onClick={() => setSelectedSection(4)}
                >
                  Family
                </div>
                <div className="border-b border-blue-500 mb-4 absolute bottom-[-15.4px] w-full"></div>
              </div>

              {getSelectedTab()}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const Personal = ({ personalDetailsData }) => {
  return (
    <div className={`flex bg-white  mx-auto shadow-lg rounded-lg `}>
      <div className="flex flex-col sm:flex-row justify-between w-full p-4 md:px-10 md:py-4 ">
        {/* Ex student details */}
        <div className="">
          <div className="text-xl text-gray-700 font-medium mb-2">
            Personal Details
          </div>

          <div className="flex items-center justify-between mb-2">
            <div className="flex items-center">
              <span className="text-gray-400 text-md font-medium">Name:</span>
              <span className="text-md text-gray-700 ml-1">
                {personalDetailsData.name}
              </span>
            </div>
          </div>
          <div className="flex items-center justify-between mb-2">
            <div className="flex items-center">
              <span className="text-gray-400 text-md font-medium">Email:</span>
              <span className="text-md text-gray-700 ml-1">
                {personalDetailsData.email}
              </span>
            </div>
          </div>
          <div className="flex items-center justify-between mb-2">
            <div className="flex items-center">
              <span className="text-gray-400 text-md font-medium">
                Married:
              </span>
              <span className="text-md text-gray-700 ml-1">
                {personalDetailsData.maritalStatus ? "married" : "unmarried"}
              </span>
            </div>
          </div>
          <div className="flex items-center justify-between mb-2">
            <div className="flex items-center">
              <span className="text-gray-400 text-md font-medium">Gender</span>
              <span className="text-md text-gray-700 ml-1">
                {personalDetailsData.gender}
              </span>
            </div>
          </div>
          <div className="flex items-center justify-between mb-2">
            <div className="flex items-center">
              <span className="text-gray-400 text-md font-medium">
                Date of birth:
              </span>
              <span className="text-md text-gray-700 ml-1">
                {personalDetailsData.dob.substring(0, 10)}
              </span>
            </div>
          </div>
          <div className="flex items-center justify-between mb-2">
            <div className="flex items-center">
              <span className="text-gray-400 text-md font-medium">
                Father's Name:
              </span>
              <span className="text-md text-gray-700 ml-1">
                {personalDetailsData.fatherName}
              </span>
            </div>
          </div>

          <div className="flex items-center justify-between mb-2">
            <div className="flex items-center">
              <span className="text-gray-400 text-md font-medium">
                Phone number (calling):
              </span>
              <span className="text-md text-gray-700 ml-1">
                {personalDetailsData.callingPhoneNo}
              </span>
            </div>
          </div>
          <div className="flex items-center justify-between mb-2">
            <div className="flex items-center">
              <span className="text-gray-400 text-md font-medium">
                Phone number (whatsapp):
              </span>
              <span className="text-md text-gray-700 ml-1">
                {personalDetailsData.whatsappPhoneNo}
              </span>
            </div>
          </div>
          <div className="flex items-center justify-between mb-2">
            <div className="flex items-center">
              <span className="text-gray-400 text-md font-medium">
                Current Address:
              </span>
              <span className="text-md text-gray-700 ml-1">
                {personalDetailsData.currentAddress}
              </span>
            </div>
          </div>
          <div className="flex items-center justify-between mb-2">
            <div className="flex items-center">
              <span className="text-gray-400 text-md font-medium">
                Current Country:
              </span>
              <span className="text-md text-gray-700 ml-1">
                {personalDetailsData.currentCountry}
              </span>
            </div>
          </div>
          <div className="flex items-center justify-between mb-2">
            <div className="flex items-center">
              <span className="text-gray-400 text-md font-medium">
                Current State:
              </span>
              <span className="text-md text-gray-700 ml-1">
                {personalDetailsData.currentState}
              </span>
            </div>
          </div>
          <div className="flex items-center justify-between mb-2">
            <div className="flex items-center">
              <span className="text-gray-400 text-md font-medium">
                Current City:
              </span>
              <span className="text-md text-gray-700 ml-1">
                {personalDetailsData.currentCity}
              </span>
            </div>
          </div>
          <div className="flex items-center justify-between mb-2">
            <div className="flex items-center">
              <span className="text-gray-400 text-md font-medium">
                Permanent Address:
              </span>
              <span className="text-md text-gray-700 ml-1">
                {personalDetailsData.permanentAddress}
              </span>
            </div>
          </div>
          <div className="flex items-center justify-between mb-2">
            <div className="flex items-center">
              <span className="text-gray-400 text-md font-medium">
                Permanent Country:
              </span>
              <span className="text-md text-gray-700 ml-1">
                {personalDetailsData.permanentCountry}
              </span>
            </div>
          </div>
          <div className="flex items-center justify-between mb-2">
            <div className="flex items-center">
              <span className="text-gray-400 text-md font-medium">
                Permanent State:
              </span>
              <span className="text-md text-gray-700 ml-1">
                {personalDetailsData.permanentState}
              </span>
            </div>
          </div>
          <div className="flex items-center justify-between mb-2">
            <div className="flex items-center">
              <span className="text-gray-400 text-md font-medium">
                Permanent City:
              </span>
              <span className="text-md text-gray-700 ml-1">
                {personalDetailsData.permanentCity}
              </span>
            </div>
          </div>
        </div>
        <div className="mt-3 sm:mt-0">
          <img
            src={
              envConfig.API_URL + `/user-pic/` + personalDetailsData.imageUrl
            }
            height={100}
            width={100}
            alt="user-profile"
          />
        </div>
      </div>
    </div>
  );
};

const Education = ({ educationalDetailsData }) => {
  return (
    <div className={`flex bg-white  mx-auto shadow-lg rounded-lg `}>
      <div className="flex flex-col w-full">
        {/* Ex student details */}
        <div className="p-4 md:px-10 md:py-4 border-b-2 border-gray-200">
          <div className="text-xl text-gray-700 font-medium mb-2">
            Ex Student Details
          </div>

          <div className="text-base text-gray-700 font-medium mb-2 underline">
            Enrolment Details
          </div>

          <div className="flex items-center justify-between mb-2">
            <div className="flex items-center">
              <span className="text-gray-400 text-md font-medium">
                Name of the institute:
              </span>
              <span className="text-md text-gray-700 ml-1">
                {educationalDetailsData.enrollmentInstitute}
              </span>
            </div>
          </div>
          <div className="flex items-center justify-between mb-2">
            <div className="flex items-center">
              <span className="text-gray-400 text-md font-medium">
                Enrolment Class:
              </span>
              <span className="text-md text-gray-700 ml-1">
                {educationalDetailsData.enrollmentClass}
              </span>
            </div>
          </div>
          <div className="flex items-center justify-between mb-2">
            <div className="flex items-center">
              <span className="text-gray-400 text-md font-medium">
                Enrollment Year:
              </span>
              <span className="text-md text-gray-700 ml-1">
                {educationalDetailsData.enrollmentYear}
              </span>
            </div>
          </div>
          <div className="flex items-center justify-between mb-2">
            <div className="flex items-center">
              <span className="text-gray-400 text-md font-medium">
                Admission number:
              </span>
              <span className="text-md text-gray-700 ml-1">
                {educationalDetailsData.enrollmentAdmissionNumber}
              </span>
            </div>
          </div>

          <div className="text-base text-gray-700 font-medium mb-2 underline">
            Passout Details
          </div>

          <div className="flex items-center justify-between mb-2">
            <div className="flex items-center">
              <span className="text-gray-400 text-md font-medium">
                Name of the institute:
              </span>
              <span className="text-md text-gray-700 ml-1">
                {educationalDetailsData.passoutInstitute}
              </span>
            </div>
          </div>
          <div className="flex items-center justify-between mb-2">
            <div className="flex items-center">
              <span className="text-gray-400 text-md font-medium">
                Passout Class:
              </span>
              <span className="text-md text-gray-700 ml-1">
                {educationalDetailsData.passoutClass}
              </span>
            </div>
          </div>
          <div className="flex items-center justify-between mb-2">
            <div className="flex items-center">
              <span className="text-gray-400 text-md font-medium">
                Passout Year:
              </span>
              <span className="text-md text-gray-700 ml-1">
                {educationalDetailsData.passoutYear}
              </span>
            </div>
          </div>
          <div className="flex items-center justify-between mb-2">
            <div className="flex items-center">
              <span className="text-gray-400 text-md font-medium">
                Admission number:
              </span>
              <span className="text-md text-gray-700 ml-1">
                {educationalDetailsData.passoutAdmissionNumber}
              </span>
            </div>
          </div>
        </div>

        <div className="p-4 md:px-10 md:py-4">
          <div className="text-xl text-gray-700 font-medium mb-2">
            Highest/Pursuing study details:
          </div>
          <div className="flex items-center justify-between mb-2">
            <div className="flex items-center">
              <span className="text-gray-400 text-md font-medium">
                Highest Education:
              </span>
              <span className="text-md text-gray-700 ml-1">
                {educationalDetailsData.highestEducation}
              </span>
            </div>
          </div>
          <div className="flex items-center justify-between mb-2">
            <div className="flex items-center">
              <span className="text-gray-400 text-md font-medium">
                Name of the institute:
              </span>
              <span className="text-md text-gray-700 ml-1">
                {educationalDetailsData.instituteName}
              </span>
            </div>
          </div>
          <div className="flex items-center justify-between mb-2">
            <div className="flex items-center">
              <span className="text-gray-400 text-md font-medium">
                Stream Name:
              </span>
              <span className="text-md text-gray-700 ml-1">
                {educationalDetailsData.stream}
              </span>
            </div>
          </div>
          <div className="flex items-center justify-between mb-2">
            <div className="flex items-center">
              <span className="text-gray-400 text-md font-medium">
                Course Name:
              </span>
              <span className="text-md text-gray-700 ml-1">
                {educationalDetailsData.course}
              </span>
            </div>
          </div>
          <div className="flex items-center justify-between mb-2">
            <div className="flex items-center">
              <span className="text-gray-400 text-md font-medium">Year:</span>
              <span className="text-md text-gray-700 ml-1">
                {educationalDetailsData.highestYear}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Professional = ({ professionalDetailsData }) => {
  return (
    <div className={`flex bg-white  mx-auto shadow-lg rounded-lg `}>
      <div className="flex flex-col w-full">
        <div className="p-4 md:px-10 md:py-4">
          <div className="text-xl text-gray-700 font-medium mb-3">
            Occupation Details
          </div>
          <div className="flex items-center justify-between mb-3">
            <div className="flex items-center">
              <span className="text-gray-400 text-md font-medium">
                Current Profession:
              </span>
              <span className="text-md text-gray-700 ml-3">
                {professionalDetailsData.currentProfession}
              </span>
            </div>
          </div>

          {(professionalDetailsData.currentProfession == "private" ||
            professionalDetailsData.currentProfession == "government") && (
            <div className="flex items-center justify-between mb-3">
              <div className="flex items-center">
                <span className="text-gray-400 text-md font-medium">
                  Department:
                </span>
                <span className="text-md text-gray-700 ml-3">
                  {professionalDetailsData.department}
                </span>
              </div>
            </div>
          )}

          {(professionalDetailsData.currentProfession == "private" ||
            professionalDetailsData.currentProfession == "government" ||
            professionalDetailsData.currentProfession == "businessman") && (
            <div className="flex items-center justify-between mb-3">
              <div className="flex items-center">
                <span className="text-gray-400 text-md font-medium">
                  Sector:
                </span>
                <span className="text-md text-gray-700 ml-3">
                  {professionalDetailsData.sector}
                </span>
              </div>
            </div>
          )}

          {(professionalDetailsData.currentProfession == "private" ||
            professionalDetailsData.currentProfession == "government") && (
            <div className="flex items-center justify-between mb-3">
              <div className="flex items-center">
                <span className="text-gray-400 text-md font-medium">
                  Designation:
                </span>
                <span className="text-md text-gray-700 ml-3">
                  {professionalDetailsData.designation}
                </span>
              </div>
            </div>
          )}

          {(professionalDetailsData.currentProfession == "private" ||
            professionalDetailsData.currentProfession == "government" ||
            professionalDetailsData.currentProfession == "businessman") && (
            <>
              <div className="flex items-center justify-between mb-3">
                <div className="flex items-center">
                  <span className="text-gray-400 text-md font-medium">
                    Address:
                  </span>
                  <span className="text-md text-gray-700 ml-3">
                    {professionalDetailsData.address}
                  </span>
                </div>
              </div>
              <div className="flex items-center justify-between mb-3">
                <div className="flex items-center">
                  <span className="text-gray-400 text-md font-medium">
                    Country:
                  </span>
                  <span className="text-md text-gray-700 ml-3">
                    {professionalDetailsData.country}
                  </span>
                </div>
              </div>
              <div className="flex items-center justify-between mb-3">
                <div className="flex items-center">
                  <span className="text-gray-400 text-md font-medium">
                    State:
                  </span>
                  <span className="text-md text-gray-700 ml-3">
                    {professionalDetailsData.state}
                  </span>
                </div>
              </div>
              <div className="flex items-center justify-between mb-3">
                <div className="flex items-center">
                  <span className="text-gray-400 text-md font-medium">
                    City:
                  </span>
                  <span className="text-md text-gray-700 ml-3">
                    {professionalDetailsData.city}
                  </span>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const Welfare = ({ sewaDetailsData }) => {
  return (
    <div className={`flex bg-white  mx-auto shadow-lg rounded-lg `}>
      <div className="flex flex-col w-full">
        <div className="p-4 md:px-10 md:py-4">
          <div className="text-xl text-gray-700 font-medium mb-3">
            Sewa Details
          </div>
          {sewaDetailsData.isPakkiSamitiMember && (
            <>
              <div className="flex items-center justify-between mb-3">
                <div className="flex items-center">
                  <span className="text-gray-400 text-md font-medium">
                    Pakki sammitti:
                  </span>
                  <span className="text-lg text-gray-700 ml-3">
                    {sewaDetailsData.pakkiSamiti}
                  </span>
                </div>
              </div>
              <div className="flex items-center justify-between mb-3">
                <div className="flex items-center">
                  <span className="text-gray-400 text-md font-medium">
                    Sewadar Since:
                  </span>
                  <span className="text-lg text-gray-700 ml-3">
                    {sewaDetailsData.sewadarSince.substring(0, 10)}
                  </span>
                </div>
              </div>
            </>
          )}

          <div className="flex items-center justify-between mb-3">
            <div className="flex items-center">
              <span className="text-gray-400 text-md font-medium">Skills:</span>
              <span className="text-md text-gray-700 ml-3">
                {sewaDetailsData.skills}
              </span>
            </div>
          </div>

          <div className="flex items-center justify-between mb-3">
            <div className="flex items-center">
              <span className="text-gray-400 text-md font-medium">
                Sewa Availability:
              </span>
              <span className="text-md text-gray-700 ml-3">
                {sewaDetailsData.sewaAvailability}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Family = ({ familyDetailsData }) => {
  return (
    <div className={`flex flex-col bg-white  mx-auto shadow-lg rounded-lg `}>
      <div className="flex flex-col sm:flex-row justify-between w-full p-4 md:px-10 md:py-4 ">
        {/* Ex student details */}
        <div className="">
          <div className="text-xl text-gray-700 font-medium mb-2">
            Family Details
          </div>
          <div className="flex items-center justify-between mb-2">
            <div className="flex items-center">
              <span className="text-gray-400 text-md font-medium">Spouse:</span>
              <span className="text-md text-gray-700 ml-1">
                {familyDetailsData?.isSpouseComing ? "Yes" : "No"}
              </span>
            </div>
          </div>
          <div className="flex items-center justify-between mb-2">
            <div className="flex items-center">
              <span className="text-gray-400 text-md font-medium">
                Children Count:
              </span>
              <span className="text-md text-gray-700 ml-1">
                {familyDetailsData?.childrenCount}
              </span>
            </div>
          </div>
        </div>
        <div className="mt-3 sm:mt-0">
          <img
            src={
              envConfig.API_URL + `/family/` + familyDetailsData?.familyPicUrl
            }
            height={100}
            width={100}
            alt="family-photo"
          />
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
