import axios from "axios";
import { token } from "./Service";
import { envConfig } from "./config";

const instance = axios.create({
  baseURL: envConfig.API_URL,
  timeout: 500000,
  headers: {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

// Add a request interceptor
// instance.interceptors.request.use(function (config) {
// 	// Do something before request is sent
// 	let AuthToken;
// 	// here also
// 	if (localStorage.get('Authtoken')) {
// 		AuthToken = JSON.parse(localStorage.get('Authtoken'));
// 	}
// 	console.log('AuthToken', AuthToken);

// 	return {
// 		...config,
// 		headers: {
// 			authorization: AuthToken ? `Bearer ${AuthToken}` : null,
// 		},
// 	};
// });

const responseBody = (response) => response;

const requests = {
  get: (url, body, headers) =>
    instance.get(url, body, headers).then(responseBody),

  post: (url, body, headers) =>
    instance.post(url, body, headers).then(responseBody),

  put: (url, body, headers) =>
    instance.put(url, body, headers).then(responseBody),

  patch: (url, body, headers) =>
    instance.patch(url, body, headers).then(responseBody),

  delete: (url, headers) => instance.delete(url, headers).then(responseBody),
};

const API = {
  //Personal get & post
  GET_PERSONAL_DETAIL() {
    return requests.get(`/personal`);
  },
  // GET_USER_DETAIL() {
  // 	return requests.get(`/user/profile`);
  // },
  POST_PERSONAL_DETAIL(body) {
    return requests.post(`/personal`, body);
  },
  PUT_PERSONAL_DETAIL(body) {
    console.log(body);
    return requests.put(`/personal`, body);
  },

  //Education get & post
  GET_EDUCATION_DETAIL() {
    return requests.get(`/education`);
  },
  POST_EDUCATION_DETAIL(body) {
    return requests.post(`/education`, body);
  },
  PUT_EDUCATION_DETAIL(body) {
    return requests.put(`/education`, body);
  },
  //profession get & post
  GET_PROFESSION_DETAIL() {
    return requests.get(`/profession`);
  },
  POST_PROFESSION_DETAIL(body) {
    return requests.post(`/profession`, body);
  },
  PUT_PROFESSION_DETAIL(body) {
    return requests.put(`/profession`, body);
  },
  //Welfare get & post
  GET_WELFARE_DETAIL() {
    return requests.get(`/welfare`);
  },
  POST_WELFARE_DETAIL(body) {
    return requests.post(`/welfare`, body);
  },
  PUT_WELFARE_DETAIL(body) {
    return requests.put(`/welfare`, body);
  },
  //family get & post
  GET_FAMILY_DETAIL() {
    return requests.get(`/attendance`);
  },
  POST_FAMILY_DETAIL(body) {
    return requests.post(`/attendance`, body);
  },
  PUT_FAMILY_DETAIL(body) {
    return requests.put(`/attendance`, body);
  },
  GET_ALL_USERS(query) {
    return requests.get(`/user/all${query}`);
  },
  APPROVE_USER(body) {
    return requests.put(`/user/approve`, body);
  },
  UNAPPROVE_USER(body) {
    return requests.put(`/user/unapprove`, body);
  },

  GET_USER_DETAIL(userId) {
    return requests.get(`/user/detail/${userId}`);
  },
};

function formateDate(date) {
  let today = new Date(date);
  let dd = String(today.getDate() + 1).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  let yyyy = today.getFullYear();

  today = mm + "/" + dd + "/" + yyyy;
  return today;
}
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
// export default Services;
export { requests, API, instance, formateDate, capitalizeFirstLetter };
