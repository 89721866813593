import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import Pagination from "../../components/admin/Pagination";
import Spinner from "../../components/Spinner";
import { institutes } from "../../shared/data";
import { successToast, errorToast } from "../../shared/Service";
import { API } from "../../shared/utility";

const AllUsers = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [name, setName] = useState("");
  const [onlyApproved, setOnlyApproved] = useState(null);
  const [institute, setInstitute] = useState(null);
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [oldResults, setOldResults] = useState([]);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [totalDocuments, setTotalDocuments] = useState("");

  const queryString = ({ email, name, phoneNo, size, page, onlyApproved }) => {
    var query = "?";

    // if (name) query = `${query}name=${name}&`;
    // if (email) query = `${query}email=${email}&`;
    if (phoneNo) query = `${query}phoneNo=${phoneNo}&`;
    query = `${query}size=${size}&`;
    query = `${query}page=${page}&`;
    query = `${query}onlyApproved=${onlyApproved}&`;
    //    if (institute) query = `${query}instituteName=${institute}&`;
    if (institute && institute !== "All")
      query = `${query}instituteName=${institute}&`;

    return query.substring(0, query.length - 1);
  };

  const handleLoadUsers = async () => {
    setIsLoading(true);

    const query = queryString({
      phoneNo: phoneNo.trim(),
      onlyApproved,
      institute,
      size,
      page,
    });

    API.GET_ALL_USERS(query)
      .then((response) => {
        setUsers(response.data.data.users);
        setTotalDocuments(response.data.data.totalDocuments);
        // if (!name && !email && !phoneNo) setOldResults(response.data.data);
        if (!phoneNo) setOldResults(response.data.data);
      })
      .catch((err) => {
        console.log(err);
        errorToast(err.response.data.message);
      })
      .finally(() => setIsLoading(false));
  };

  const handleResetRecords = () => {
    // setEmail("");
    // setName("");
    setPhoneNo("");
    setUsers(oldResults.users);
  };

  const approveUser = async (userId, index) => {
    setIsLoading(true);
    const data = { userId: userId };
    API.APPROVE_USER(data)
      .then((res) => {
        setUsers((prev) =>
          Object.values({
            ...prev,
            [index]: { ...prev[index], status: "active" },
          })
        );
        successToast("User Approved Successfully!");
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);

        errorToast(err.response.data.message);
        // console.log(err ? err.response.data.message : err.message);
      });
  };
  const unapproveUser = async (userId, index) => {
    setIsLoading(true);
    const data = { userId: userId };
    API.UNAPPROVE_USER(data)
      .then((res) => {
        setUsers((prev) =>
          Object.values({
            ...prev,
            [index]: { ...prev[index], status: "inactive" },
          })
        );
        successToast("User unapproved successfully!");
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);

        errorToast(err.response.data.message);
        // console.log(err ? err.response.data.message : err.message);
      });
  };

  useEffect(() => {
    handleLoadUsers();
  }, [page]);

  useEffect(() => {
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    if (userDetails?.user?.role !== "superadmin") {
      navigate("/");
    }
  }, []);

  const heading = [
    "S.No",
    "Name",
    "Father Name",
    "Phone Number",
    "Admission No.",
    "Enrollment Year",
    "Enrollment Class",
    "Institute",
    "Action",
  ];

  const tdClass =
    "md:px-6 md:py-4 p-3 text-left text-sm text-gray-800 whitespace-nowrap";

  return (
    <div className="bg-red-100 py-6 min-h-screen">
      <div className="py-4 m-auto w-[90%] md:max-w-6xl px-4  bg-white shadow-md shadow-gray-300 rounded-md mt-10">
        <p className="text-gray-700 text-2xl md:text-3xl">All Users</p>
        <div className="flex flex-wrap items-end">
          {/* <div className="flex flex-col w-[90%] md:w-[18%] m-auto md:m-2 space-y-1 my-2">
            <label htmlFor="email" className="text-sm  text-gray-400">
              Email address
            </label>
            <input
              type="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="px-4 py-2 transition duration-300 border text-gray-400 border-gray-300 rounded focus:border-transparent focus:outline-none peer focus:ring-4 focus:ring-gray-200"
            />
          </div>
          <div className="flex flex-col w-[90%] md:w-[18%] m-auto md:m-2 space-y-1 my-2">
            <label htmlFor="email" className="text-sm  text-gray-400">
              Name{" "}
            </label>
            <input
              type="text"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="px-4 py-2 transition duration-300 border text-gray-400 border-gray-300 rounded focus:border-transparent focus:outline-none peer focus:ring-4 focus:ring-gray-200"
            />
          </div> */}
          <div className="flex flex-col w-[90%] md:w-[18%] m-auto md:m-2 space-y-1 my-2">
            <label htmlFor="email" className="text-sm  text-gray-400">
              Phone Number
            </label>
            <input
              type="number"
              name="phoneNo"
              value={phoneNo}
              onChange={(e) => setPhoneNo(e.target.value)}
              className="px-4 py-2 transition duration-300 border text-gray-400 border-gray-300 rounded focus:border-transparent focus:outline-none peer focus:ring-4 focus:ring-gray-200"
            />
          </div>
          <div className="flex flex-col w-[90%] md:w-[18%] m-auto md:m-2 space-y-1 my-2">
            <label htmlFor="onlyApproved" className="text-sm  text-gray-400">
              Institute
            </label>
            <select
              className="text-black px-3 sm:px-4 py-2 border-gray-300 border rounded-md bg-transparent"
              name="enrollmentInstitute"
              value={institute}
              onChange={(e) => setInstitute(e.target.value)}
            >
              <option value={null} defaultValue>
                All
              </option>
              {institutes.map((instituteName, index) => {
                return (
                  <option value={instituteName} key={index}>
                    {instituteName}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="flex flex-col w-[90%] md:w-[18%] m-auto md:m-2 space-y-1 my-2">
            <label htmlFor="onlyApproved" className="text-sm  text-gray-400">
              Status
            </label>
            <select
              className="text-black px-3 sm:px-4 py-2 border-gray-300 border rounded-md bg-transparent"
              name="onlyApproved"
              value={onlyApproved}
              onChange={(e) => {
                setOnlyApproved(e.target.value);
              }}
            >
              <option value={null}>All Users</option>
              <option value={true}>Approved Users</option>
              <option value={false}>Unapprove Users</option>
            </select>
          </div>
          <div className="flex flex-col w-[90%] md:w-[18%] m-auto md:m-2 my-2">
            <button
              className="bg-red-500 rounded-md shadow-sm
            text-white 
            shadow-gray-700 p-2 px-4"
              onClick={handleLoadUsers}
            >
              Search
            </button>
          </div>
          {/* <div className="flex flex-col w-[90%] md:w-[18%] m-auto md:m-2 my-2">
            <button
              className="bg-gray-500 rounded-md shadow-sm
            text-white 
            shadow-gray-700 p-2 px-4"
              onClick={handleResetRecords}
            >
              Reset
            </button>
          </div> */}
        </div>
        <div className="overflow-scroll">
          <table
            className="my-4 shadow-md bg-red-100
         shadow-gray-300 table-fixed 
         min-w-full divide-y divide-gray-200"
          >
            <thead className="">
              <tr>
                {heading &&
                  heading.map((tr) => {
                    return (
                      <th
                        scope="col"
                        className="p-3 md:px-6 md:py-3 text-xs font-bold text-left text-gray-500 uppercase whitespace-nowrap"
                      >
                        {tr}
                      </th>
                    );
                  })}
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {isLoading ? (
                <td colSpan={8}>
                  <Spinner css={"my-10"} />
                </td>
              ) : (
                users &&
                users.map((user, index) => {
                  return (
                    user.Personal?.name && (
                      <tr key={index}>
                        <td className={tdClass}>
                          {size * (page - 1) + index + 1}
                        </td>
                        <td className={tdClass}>
                          {user.Personal?.name || "NA"}
                        </td>
                        <td className={tdClass}>
                          {user.Personal?.fatherName || "NA"}
                        </td>
                        <td className={tdClass}>{user.phoneNo || "NA"}</td>
                        <td className={tdClass}>
                          {user.Education?.enrollmentAdmissionNumber || "NA"}
                        </td>
                        <td className={tdClass}>
                          {user.Education?.enrollmentYear || "NA"}
                        </td>
                        <td className={tdClass}>
                          {user.Education?.enrollmentClass || "NA"}
                        </td>
                        <td className={tdClass}>
                          {user.Education?.enrollmentInstitute || "NA"}
                        </td>
                        {/* <td className={tdClass}>
                        {user.Personal?.state ? user.Personal?.state : "NA"}
                      </td>
                      <td className={tdClass}>
                        {user.Personal?.city ? user.Personal?.city : "NA"}
                      </td> */}
                        <td className={tdClass}>
                          <button
                            className="bg-red-500 rounded-md shadow-sm text-white shadow-gray-700 p-2 px-4 text-sm"
                            onClick={() => navigate(`/users/${user.userId}`)}
                          >
                            View
                          </button>
                          <button
                            className="ml-3 bg-red-500 rounded-md shadow-sm text-white shadow-gray-700 p-2 px-4 text-sm"
                            onClick={() => {
                              if (user.status === "active") {
                                unapproveUser(user.userId, index);
                              } else {
                                approveUser(user.userId, index);
                              }
                            }}
                          >
                            {user.status === "active" ? "Unapprove" : "Approve"}
                          </button>
                        </td>
                      </tr>
                    )
                  );
                })
              )}
            </tbody>
          </table>
        </div>
        {/* {!isLoading ? ( */}
        <Pagination
          totalDocuments={totalDocuments}
          size={size}
          setPage={setPage}
          page={page}
        />
        {/* ) : null} */}
      </div>
    </div>
  );
};

export default AllUsers;
