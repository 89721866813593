import './App.css';
import {Button} from '@material-tailwind/react';

import MyRoutes from './shared/MyRoutes';

function App() {
	return <MyRoutes />;
}

export default App;
