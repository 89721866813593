import React, {useState} from 'react';
import Spinner from './Spinner';
import {API} from '../shared/utility';
import {successToast, errorToast} from '../shared/Service';

const ButtonsLayout = ({currentTab, changeTab, data, updateData, maritalStatus, saveButtonDisabled, showSaveButton, setShowSuccessModal, countryCode}) => {
	const [isLoading, setIsLoading] = useState(false);

	const handlePrevious = () => {
		setIsLoading(false);
		switch (currentTab) {
			case 1:
				return changeTab(0);
			case 2:
				return changeTab(1);
			case 3:
				return changeTab(2);
			case 4:
				return changeTab(3);
			default:
				return changeTab(0);
		}
	};
	const handleNext = () => {
		switch (currentTab) {
			case 0:
				return changeTab(1);
			case 1:
				return changeTab(2);
			case 2:
				return changeTab(3);
			case 3:
				return changeTab(4);
			default:
				setShowSuccessModal(true);
				return changeTab(0);
		}
	};
	const handleSave = () => {
		switch (currentTab) {
			case 0:
				return addPersonalDetails();
			case 1:
				return addEducationDetails();
			case 2:
				return addProfessionalDetails();
			case 3:
				return addWelfareDetials();
			case 4:
				return addFamilyDetails();
			default:
				return alert('Please Refresh And Try Agian');
		}
	};
	const handleUpdate = () => {
		if (JSON.stringify(updateData) === '{}') {
			handleNext();
			return;
		}
		console.log(updateData);
		switch (currentTab) {
			case 0:
				return JSON.stringify(updateData) !== '{}' && UPDATE_PersonalDetails();
			case 1:
				return JSON.stringify(updateData) !== '{}' && UPDATE_EducationDetails();
			case 2:
				return JSON.stringify(updateData) !== '{}' && UPDATE_ProfessionalDetails();
			case 3:
				return JSON.stringify(updateData) !== '{}' && UPDATE_WelfareDetials();
			case 4:
				return JSON.stringify(updateData) !== '{}' && UPDATE_FamilyDetails();
			default:
				return alert('Please Refresh And Try Agian');
		}
	};

	var PhoneRegex = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
	const EmailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

	// api
	const addPersonalDetails = async () => {
		const {
			name,
			email,
			maritalStatus,
			gender,
			callingPhoneNo,
			whatsappPhoneNo,
			currentCountry,
			currentState,
			currentCity,
			currentAddress,
			permanentAddress,
			permanentCountry,
			permanentState,
			permanentCity,
			dob,
			fatherName,
			image,
			adhaarNo,
		} = data;

		if (!name) {
			errorToast('Please enter valid Name  !!');

			return;
		}

		if (!fatherName) {
			errorToast('Please enter valid Father Name  !! ');
			return;
		}
		if (dob == '') {
			errorToast('Please enter valid Date of birth  !! ');

			return;
		}
		if (gender === '' || gender === null) {
			errorToast('Please enter valid gender  !! ');
			return;
		}

		if (maritalStatus === '' || maritalStatus === null) {
			errorToast('Please enter valid maritalStatus !! ');

			return;
		}
		if (!PhoneRegex.test(callingPhoneNo)) {
			errorToast('Please enter valid Phone Number !! ');
			return;
		}
		if (!EmailRegex.test(email)) {
			errorToast("Please enter valid E-mail I'd !! ");
			return;
		}

		if (!image || image === null) {
			errorToast('Please Upload Profile Picture');
			return;
		}

		// current address
		if (!currentAddress) {
			errorToast('Please Enter valid current Address !! ');
			return;
		}
		if (!currentCountry) {
			errorToast('Please Enter valid current Country !! ');
			return;
		}
		if (!currentState) {
			errorToast('Please Enter valid current State !! ');
			return;
		}
		if (!currentCity) {
			errorToast('Please Enter valid current City !! ');
			return;
		}
		// permant address
		if (!permanentAddress) {
			errorToast('Please Enter valid current Address !! ');
			return;
		}
		if (!permanentCountry) {
			errorToast('Please Enter valid current Country !! ');
			return;
		}
		if (!permanentState) {
			errorToast('Please Enter valid current State !! ');
			return;
		}
		if (!permanentCity) {
			errorToast('Please Enter valid current City !! ');
			return;
		}

		if (adhaarNo.length !== 12) {
			errorToast('Please Enter Valid Adhaar Number !! ');
			return;
		}

		// if (image.size > 5 * 1024 * 1024) {
		//   errorToast("Image size should be less than 5 MB!");
		//   return;
		// }

		setIsLoading(true);
		let personalDetails = new FormData();

		personalDetails.append('name', name);
		personalDetails.append('email', email);
		personalDetails.append('maritalStatus', maritalStatus);
		personalDetails.append('gender', gender);
		personalDetails.append('callingPhoneNo', countryCode + callingPhoneNo);
		personalDetails.append('whatsappPhoneNo', whatsappPhoneNo);
		personalDetails.append('currentCountry', currentCountry);
		personalDetails.append('currentState', currentState);
		personalDetails.append('currentCity', currentCity);
		personalDetails.append('currentAddress', currentAddress);
		personalDetails.append('permanentAddress', permanentAddress);
		personalDetails.append('permanentCountry', permanentCountry);
		personalDetails.append('permanentCity', permanentCity);
		personalDetails.append('permanentState', permanentState);
		personalDetails.append('dob', dob);
		personalDetails.append('fatherName', fatherName);
		personalDetails.append('adhaarNo', adhaarNo);
		personalDetails.append('image', image);

		console.log('personal ');
		API.POST_PERSONAL_DETAIL(personalDetails)
			.then((res) => {
				setIsLoading(false);
				console.log('in res personal');
				if (res.status === 200) {
					successToast('Data Save  ');
					handleNext();
				}
			})
			.catch((err) => {
				setIsLoading(false);
				console.log('in catch personal');
				errorToast(err?.response?.data?.message ? err.response.data.message : 'oops! something went wrong!');
				console.log(err?.response?.data?.message ? err.response.data.message : err.message);
			});
	};
	const addEducationDetails = async () => {
		const {
			enrollmentInstitute,
			enrollmentClass,
			enrollmentYear,
			enrollmentAdmissionNumber,
			passoutInstitute,
			passoutClass,
			passoutAdmissionNumber,
			passoutYear,
			instituteName,
			course,
			highestEducation,
			stream,
			highestYear,
		} = data;

		if (passoutYear * 1 < 1947 || passoutYear * 1 > 2022) {
			errorToast('Please enter valid passout year!');
			return;
		}

		if (enrollmentYear * 1 < 1947 || enrollmentYear * 1 > 2022) {
			errorToast('Please enter valid enrolment year!');
			return;
		}

		if (highestYear * 1 < 1947 || highestYear * 1 > 2022) {
			errorToast('Please enter valid highest year!');
			return;
		}

		if (!enrollmentInstitute || !enrollmentClass || !enrollmentYear || !enrollmentYear || !enrollmentAdmissionNumber) {
			errorToast('invalid Enrollment Details');
			return;
		}

		if (!passoutInstitute || !passoutClass || !passoutAdmissionNumber || !passoutYear) {
			errorToast('invalid Passout Details');
			return;
		}
		if (passoutYear < enrollmentYear) {
			errorToast('Enrolment year should be less than passout year!');
			return;
		}

		if (!instituteName || !course || !highestEducation || !stream || !highestYear || !highestYear) {
			errorToast('invalid Highest Educ. Details');
			return;
		}

		setIsLoading(true);
		const educationDetails = {
			enrollmentInstitute,
			enrollmentClass,
			enrollmentYear,
			enrollmentAdmissionNumber,
			passoutInstitute,
			passoutClass,
			passoutAdmissionNumber,
			passoutYear,
			instituteName,
			course,
			highestEducation,
			stream,
			highestYear,
		};

		API.POST_EDUCATION_DETAIL(educationDetails)
			.then((res) => {
				setIsLoading(false);
				if (res.status === 200) {
					successToast('Data Save  ');
					handleNext();
				}
			})
			.catch((err) => {
				setIsLoading(false);
				errorToast(err?.response?.data?.message ? err.response.data.message : 'oops! something went wrong!');
				console.log(err?.response?.data?.message ? err.response.data.message : 'oops! something went wrong!');
			});
	};
	const addProfessionalDetails = async () => {
		const {currentProfession, department, sector, address, designation, state, country, city} = data;

		if (!currentProfession) {
			errorToast('invalid Input !!');
			return;
		}

		if ((currentProfession == 'private' || currentProfession == 'government') && !data.designation) {
			errorToast('Please enter designation!');
			return;
		}
		if ((currentProfession == 'private' || currentProfession == 'government') && !data.department) {
			errorToast('Please enter department!');
			return;
		}

		if ((currentProfession == 'private' || currentProfession == 'government' || currentProfession == 'businessman') && !data.sector) {
			errorToast('Please enter sector!');
			return;
		}
		if ((currentProfession == 'private' || currentProfession == 'government' || currentProfession == 'businessman') && !data.country) {
			errorToast('Please enter country!');
			return;
		}
		if ((currentProfession == 'private' || currentProfession == 'government' || currentProfession == 'businessman') && !data.state) {
			errorToast('Please enter state!');
			return;
		}
		if ((currentProfession == 'private' || currentProfession == 'government' || currentProfession == 'businessman') && !data.city) {
			errorToast('Please enter city!');
			return;
		}
		setIsLoading(true);
		const professionalDetails = {
			currentProfession,
			department,
			sector,
			address,
			designation,
			state,
			country,
			city,
		};

		API.POST_PROFESSION_DETAIL(professionalDetails)
			.then((res) => {
				setIsLoading(false);
				if (res.status === 200) {
					successToast('Data Save  ');
					handleNext();
				}
			})
			.catch((err) => {
				setIsLoading(false);
				errorToast(err?.response?.data?.message ? err.response.data.message : 'oops! something went wrong!');
				console.log(err?.response?.data?.message ? err.response.data.message : err.message);
			});
	};
	const addWelfareDetials = async () => {
		const {isPakkiSamitiMember, pakkiSamiti, sewadarSince, skills, sewaAvailability} = data;

		if (isPakkiSamitiMember && (sewadarSince * 1 < 1947 || sewadarSince * 1 > 2022)) {
			errorToast('Please enter valid year!');
			return;
		}

		if ((isPakkiSamitiMember && !pakkiSamiti) || (isPakkiSamitiMember && !sewadarSince) || !skills || !sewaAvailability) {
			errorToast('All fields are required!');
			return;
		}
		setIsLoading(true);

		const welfareDetails = {
			isPakkiSamitiMember,
			pakkiSamiti: isPakkiSamitiMember ? pakkiSamiti : null,
			sewadarSince: isPakkiSamitiMember ? sewadarSince : null,
			skills,
			sewaAvailability,
		};

		API.POST_WELFARE_DETAIL(welfareDetails)
			.then((res) => {
				setIsLoading(false);
				if (res.status === 200) {
					successToast('Data Save ');
					handleNext();
				}
			})
			.catch((err) => {
				setIsLoading(false);
				errorToast(err?.response?.data?.message ? err.response.data.message : 'oops! something went wrong!');
				console.log(err?.response?.data?.message ? err.response.data.message : err.message);
			});
	};
	const addFamilyDetails = async () => {
		const {isSpouseComing, childrenCount, image} = data;

		const familyDetails = new FormData();

		if (isSpouseComing && image === null) {
			errorToast('upload family image !!!');
			return;
		}

		if (childrenCount > 0 && image === null) {
			errorToast('upload family image !!!');
			return;
		}

		if (isSpouseComing && childrenCount > 0 && image === null) {
			errorToast('upload family image !!!');
			return;
		}

		if (isSpouseComing === null) {
			errorToast('Please verify spouse coming or not!');
			return;
		}

		if (childrenCount === '') {
			errorToast('Please enter children count!');
			return;
		}
		familyDetails.append('isSpouseComing', maritalStatus === false ? null : isSpouseComing);
		familyDetails.append('childrenCount', maritalStatus === false ? null : childrenCount);
		familyDetails.append('image', maritalStatus === false ? null : image);
		setIsLoading(true);
		API.POST_FAMILY_DETAIL(familyDetails)
			.then((res) => {
				setIsLoading(false);
				if (res.status === 200) {
					successToast('Data Save ');
					// setShowSuccessModal(true);
					handleNext();
				}
			})
			.catch((err) => {
				setIsLoading(false);
				errorToast(err?.response?.data?.message ? err.response.data.message : 'oops! something went wrong!');
				console.log(err?.response?.data?.message ? err.response.data.message : err.message);
			});
	};

	// put
	const UPDATE_PersonalDetails = async () => {
		const formData = new FormData();

		const mobileNoWithoutCode = updateData.callingPhoneNo;
		updateData['callingPhoneNo'] = countryCode + mobileNoWithoutCode;

		const {
			name,
			email,
			maritalStatus,
			gender,
			callingPhoneNo,
			whatsappPhoneNo,
			currentCountry,
			currentState,
			currentCity,
			currentAddress,
			permanentAddress,
			permanentCountry,
			permanentState,
			permanentCity,
			dob,
			fatherName,
			image,
			adhaarNo,
		} = data;

		if (!name) {
			errorToast('Please enter valid Name  !!');

			return;
		}

		if (!fatherName) {
			errorToast('Please enter valid Father Name  !! ');
			return;
		}
		if (dob == '') {
			errorToast('Please enter valid Date of birth  !! ');

			return;
		}
		if (gender === '' || gender === null) {
			errorToast('Please enter valid gender  !! ');
			return;
		}

		if (maritalStatus === '' || maritalStatus === null) {
			errorToast('Please enter valid maritalStatus !! ');

			return;
		}
		if (!PhoneRegex.test(callingPhoneNo)) {
			errorToast('Please enter valid Phone Number !! ');
			return;
		}
		if (!EmailRegex.test(email)) {
			errorToast("Please enter valid E-mail I'd !! ");
			return;
		}

		if (!image || image === null) {
			errorToast('Please Upload Profile Picture');
			return;
		}

		// current address
		if (!currentAddress) {
			errorToast('Please Enter valid current Address !! ');
			return;
		}
		if (!currentCountry) {
			errorToast('Please Enter valid current Country !! ');
			return;
		}
		if (!currentState) {
			errorToast('Please Enter valid current State !! ');
			return;
		}
		if (!currentCity) {
			errorToast('Please Enter valid current City !! ');
			return;
		}
		// permant address
		if (!permanentAddress) {
			errorToast('Please Enter valid current Address !! ');
			return;
		}
		if (!permanentCountry) {
			errorToast('Please Enter valid current Country !! ');
			return;
		}
		if (!permanentState) {
			errorToast('Please Enter valid current State !! ');
			return;
		}
		if (!permanentCity) {
			errorToast('Please Enter valid current City !! ');
			return;
		}

		if (adhaarNo.length !== 12) {
			errorToast('Please Enter Valid Adhaar Number !! ');
			return;
		}

		// if (updateData.image.size > 5 * 1024 * 1024) {
		//   errorToast("Image size should be less than 5 MB!");
		//   return;
		// }
		for (const [key, value] of Object.entries(updateData)) {
			if (value !== null) {
				formData.append(key, value);
			}
		}

		console.log('form data', formData);
		setIsLoading(true);

		API.PUT_PERSONAL_DETAIL(formData)
			.then((res) => {
				setIsLoading(false);
				if (res.status === 200) {
					successToast('Data Updated ');
					handleNext();
				}
			})
			.catch((err) => {
				setIsLoading(false);
				errorToast(err?.response?.data?.message ? err.response.data.message : 'oops! something went wrong!');
				console.log(err?.response?.data?.message ? err.response.data.message : err.message);
			});
	};
	const UPDATE_EducationDetails = async () => {
		// const { passoutAdmissionNumber, enrollmentAdmissionNumber } = data;

		// if (passoutAdmissionNumber !== enrollmentAdmissionNumber) {
		//   errorToast("Passout and enrollment year should be same!");
		//   return;
		// }

		const {
			enrollmentInstitute,
			enrollmentClass,
			enrollmentYear,
			enrollmentAdmissionNumber,
			passoutInstitute,
			passoutClass,
			passoutAdmissionNumber,
			passoutYear,
			instituteName,
			course,
			highestEducation,
			stream,
			highestYear,
		} = data;

		if (passoutYear * 1 < 1947 || passoutYear * 1 > 2022) {
			errorToast('Please enter valid passout year!');
			return;
		}

		if (enrollmentYear * 1 < 1947 || enrollmentYear * 1 > 2022) {
			errorToast('Please enter valid enrolment year!');
			return;
		}

		if (highestYear * 1 < 1947 || highestYear * 1 > 2022) {
			errorToast('Please enter valid highest year!');
			return;
		}

		if (!enrollmentInstitute || !enrollmentClass || !enrollmentYear || !enrollmentYear || !enrollmentAdmissionNumber) {
			errorToast('invalid Enrollment Details');
			return;
		}

		if (!passoutInstitute || !passoutClass || !passoutAdmissionNumber || !passoutYear) {
			errorToast('invalid Passout Details');
			return;
		}
		if (passoutYear < enrollmentYear) {
			errorToast('Enrolment year should be less than passout year!');
			return;
		}

		if (!instituteName || !course || !highestEducation || !stream || !highestYear || !highestYear) {
			errorToast('invalid Highest Educ. Details');
			return;
		}
		if (data.passoutYear * 1 < 1947 || data.passoutYear * 1 > 2022) {
			errorToast('Please enter valid passout year!');
			return;
		}

		if (data.enrollmentYear * 1 < 1947 || data.enrollmentYear * 1 > 2022) {
			errorToast('Please enter valid enrolment year!');
			return;
		}

		if (data.highestYear * 1 < 1947 || data.highestYear * 1 > 2022) {
			errorToast('Please enter valid highest year!');
			return;
		}

		if (!data.passoutYear) {
			errorToast('Please enter passout year');
			return;
		}
		if (!data.enrollmentYear) {
			errorToast('Please enter enrolment year');
			return;
		}
		if (!data.highestYear) {
			errorToast('Please enter highest study year');
			return;
		}

		setIsLoading(true);
		API.PUT_EDUCATION_DETAIL(updateData)
			.then((res) => {
				setIsLoading(false);
				if (res.status === 200) {
					successToast('Data Updated ');
					handleNext();
				}
			})
			.catch((err) => {
				setIsLoading(false);
				errorToast(err?.response?.data?.message ? err.response.data.message : 'oops! something went wrong!');
				console.log(err?.response?.data?.message ? err.response.data.message : err.message);
			});
	};
	const UPDATE_ProfessionalDetails = async () => {
		const {currentProfession, department, sector, address, designation, state, country, city} = data;

		if (!currentProfession) {
			errorToast('invalid Input !!');
			return;
		}

		if ((currentProfession == 'private' || currentProfession == 'government') && !data.designation) {
			errorToast('Please enter designation!');
			return;
		}
		if ((currentProfession == 'private' || currentProfession == 'government') && !data.department) {
			errorToast('Please enter department!');
			return;
		}

		if ((currentProfession == 'private' || currentProfession == 'government' || currentProfession == 'businessman') && !data.sector) {
			errorToast('Please enter sector!');
			return;
		}
		if ((currentProfession == 'private' || currentProfession == 'government' || currentProfession == 'businessman') && !data.country) {
			errorToast('Please enter country!');
			return;
		}
		if ((currentProfession == 'private' || currentProfession == 'government' || currentProfession == 'businessman') && !data.state) {
			errorToast('Please enter state!');
			return;
		}
		if ((currentProfession == 'private' || currentProfession == 'government' || currentProfession == 'businessman') && !data.city) {
			errorToast('Please enter city!');
			return;
		}

		setIsLoading(true);

		API.PUT_PROFESSION_DETAIL(updateData)
			.then((res) => {
				setIsLoading(false);
				if (res.status === 200) {
					successToast('Data Updated ');
					handleNext();
				}
			})
			.catch((err) => {
				setIsLoading(false);
				errorToast(err?.response?.data?.message ? err.response.data.message : 'oops! something went wrong!');
				console.log(err?.response?.data?.message ? err.response.data.message : err.message);
			});
	};
	const UPDATE_WelfareDetials = async () => {
		if (
			(data.isPakkiSamitiMember && !data.pakkiSamiti && data.pakkiSamiti !== undefined) ||
			(data.isPakkiSamitiMember && !data.sewadarSince && data.sewadarSince !== undefined) ||
			!data.skills ||
			!data.sewaAvailability
		) {
			errorToast('All fields are required!');
			return;
		}

		if (data.isPakkiSamitiMember && (data.sewadarSince * 1 < 1947 || data.sewadarSince * 1 > 2022)) {
			errorToast('Please enter valid year!');
			return;
		}

		setIsLoading(true);

		API.PUT_WELFARE_DETAIL(updateData)
			.then((res) => {
				setIsLoading(false);
				if (res.status === 200) {
					successToast('Data Updated ');
					handleNext();
				}
			})
			.catch((err) => {
				setIsLoading(false);
				errorToast(err?.response?.data?.message ? err.response.data.message : 'oops! something went wrong!');
				console.log(err?.response?.data?.message ? err.response.data.message : err.message);
			});
	};
	const UPDATE_FamilyDetails = async () => {
		setIsLoading(true);
		// const {skills, isPakkiSamitiMember, pakkiSamiti, otherInformation, resumeLink, suggestion} = updateData;
		const formData = new FormData();

		const {isSpouseComing, childrenCount, image} = data;

		const familyDetails = new FormData();

		if (isSpouseComing && image === null) {
			errorToast('upload family image !!!');
			return;
		}

		if (childrenCount > 0 && image === null) {
			errorToast('upload family image !!!');
			return;
		}

		if (isSpouseComing && childrenCount > 0 && image === null) {
			errorToast('upload family image !!!');
			return;
		}

		if (isSpouseComing === null) {
			errorToast('Please verify spouse coming or not!');
			return;
		}

		if (childrenCount === '') {
			errorToast('Please enter children count!');
			return;
		}

		for (const [key, value] of Object.entries(updateData)) {
			if (value !== null) {
				formData.append(key, value);
			}
		}

		API.PUT_FAMILY_DETAIL(formData)
			.then((res) => {
				setIsLoading(false);
				if (res.status === 200) {
					successToast('Data Updated ');
					// setShowSuccessModal(true);
					handleNext();
				}
			})
			.catch((err) => {
				setIsLoading(false);
				errorToast(err?.response?.data?.message ? err.response.data.message : 'oops! something went wrong!');
				console.log(err?.response?.data?.message ? err.response.data.message : err.message);
			});
	};

	return (
		<div className='flex justify-between w-full m-auto my-2 md:my-4 text-black px-3 sm:px-6'>
			{isLoading ? (
				<div className='w-full h-full fixed top-0 left-0 bg-black opacity-60 flex items-center justify-center'>
					<Spinner />
				</div>
			) : null}
			<div>
				<div className='flex items-center justify-center'>
					<button
						onClick={handlePrevious}
						type='submit'
						className='flex p-1 px-4 text-sm md:text-lg
            text-center transition-colors text-white 
			duration-300 bg-red-500 rounded-md shadow btns w-max hover:bg-red-600 focus:outline-none focus:ring-gray-200 focus:ring-4'>
						Previous
					</button>
				</div>
			</div>
			<div className='flex'>
				<div className='flex items-center justify-center'>
					{showSaveButton ? (
						<button
							onClick={handleSave}
							type='submit'
							className='flex p-1 px-4 text-sm md:text-lg text-center transition-colors duration-300 bg-red-500 text-white  disabled:cursor-no-drop
				disabled:bg-gray-400
							   rounded-md shadow btns w-max  focus:outline-none focus:ring-gray-200 focus:ring-4'>
							{currentTab === 4 ? 'Save ' : 'Next'}
						</button>
					) : (
						<button
							onClick={JSON.stringify(updateData) !== '{}' ? handleUpdate : handleNext}
							type='submit'
							className='flex  p-1 px-4 text-sm md:text-lg text-center transition-colors duration-300 bg-red-500 text-white rounded-md shadow btns w-max hover:bg-red-600 focus:outline-none disabled:cursor-no-drop
				disabled:bg-gray-400 focus:ring-gray-200 focus:ring-4'>
							{JSON.stringify(updateData) !== '{}' && 'Update & '} Next
						</button>
					)}
				</div>
			</div>
		</div>
	);
};

export default ButtonsLayout;
