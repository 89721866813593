import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../components/PrimaryButton";
import { errorToast, successToast } from "../shared/Service";
import axios from "axios";
import LoginImg from "../assets/images/Secure.svg";
import Spinner from "../components/Spinner";
import { envConfig } from "../shared/config";

const Login = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const [countryCode, setCountryCode] = useState("91");
  const [data, setData] = useState({
    phoneNo: ``,
    otp: "",
  });
  const [otpSent, setOtpSent] = useState(false);

  const validate = () => {
    if (data.phoneNo.length < 7 || data.phoneNo.length > 13) return true;

    return false;
  };

  const handleInputChange = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  };

  const getCountries = () => {
    axios
      .get(envConfig.COMMON_URL + `/countries`)
      .then((res) => {
        setCountries(res.data.data);
      })
      .catch((e) => {
        console.log(e.response.data.message);
      });
  };
  const getOtp = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const payload = { phoneNo: countryCode + data.phoneNo };
    try {
      const res = await axios({
        method: "post",
        url: envConfig.API_URL + "/user/",
        data: payload,
      });

      if (res.status === 200) {
        setIsLoading(false);
        successToast("OTP Successfully Sent To Your Mobile Number!");
        setOtpSent(true);
      }
    } catch (error) {
      setIsLoading(false);
      errorToast(error.response.data.message);
      console.log(error);
    }
  };

  //Hit Login api
  const handleLoginClick = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    axios
      .post(envConfig.API_URL + "/user/login", {
        phoneNo: countryCode + data.phoneNo,
        otp: data.otp,
      })
      .then((res) => {
        if (res.data?.data) {
          localStorage.clear();

          localStorage.setItem("Authtoken", res.data.data.token);
          localStorage.setItem("userDetails", JSON.stringify(res.data.data));
          if (res.data.data?.user?.role === "superadmin") {
            navigate("/users");
          } else {
            navigate("/");
          }
          window.location.reload();
        }
        successToast("Login Successfull !!");
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
        errorToast(e.response.data.message);
      });
  };
  useEffect(() => {
    getCountries();
  }, []);

  return (
    <div className="bg-red-100  min-h-screen flex flex-col justify-center items-center ">
      <div className="w-[90%] md:w-[900px] shadow-sm">
        {isLoading ? (
          <div className="w-full h-[50vh] flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <>
            <p className="text-2xl md:text-3xl font-bold text-gray-600 mb-5">
              Welcome to Shah Satnam Ji Institutes
            </p>
            {/* <div className="my-3 text-xs text-black bg-red-200 p-2 rounded-md capitalize">
              <span className="font-medium mr-2">Note:</span>
              OTP will be sent to your mobile number
            </div> */}
            <div className="   bg-white  shadow-gray-300 p-4 md:p-8 m-auto md:flex items-center ">
              <div className="w-[90%] md:m-auto mb-4 mx-auto order-1 md:w-2/4">
                <img src={LoginImg} className="w-[60%] m-auto" alt="" />
              </div>
              <form
                onSubmit={otpSent ? handleLoginClick : getOtp}
                className="w-[90%] m-auto order-2 md:w-2/4"
              >
                <div className="flex flex-col space-y-1 my-2">
                  <label htmlFor="phoneNo" className="text-sm  text-gray-400">
                    Phone Number
                  </label>
                  <div className="flex row transition duration-300 border text-gray-400 border-gray-300 rounded focus:border-transparent focus:outline-nonefocus:ring-4 focus:ring-gray-200">
                    <span className="w-[25%] md:w-[20%]  border-r border-gray-300">
                      <select
                        className="w-full text-black text-gray-400  sm:px-0 py-2  rounded-md  outline-none bg-transparent  "
                        name="contryCode"
                        onChange={(e) => {
                          const country = JSON.parse(e.target.value);
                          const contryCode =
                            country?.phonecode?.substring(0, 1) === "+"
                              ? country.phonecode.substring(
                                  0,
                                  country.phonecode.length
                                )
                              : country?.phonecode;

                          setCountryCode(contryCode);
                        }}
                      >
                        <option hidden defaultChecked>
                          +91
                        </option>
                        {countries.map((country) => {
                          return (
                            <option
                              key={country.code}
                              value={JSON.stringify(country)}
                              selected={country.phonecode === "91"}
                              className="text-sm  cursor-pointer"
                            >
                              {country?.phonecode?.substring(0, 1) === "+"
                                ? country?.phonecode
                                : "+" + country?.phonecode}
                            </option>
                          );
                        })}
                      </select>
                    </span>
                    <span className="w-[80%]  ">
                      <input
                        type="number"
                        name="phoneNo"
                        min={"0000000000"}
                        max={"9999999999"}
                        onChange={(e) => handleInputChange(e)}
                        autoFocus
                        value={data.phoneNo}
                        className="w-full px-4 py-2      rounded  outline-none  peer "
                      />
                    </span>
                  </div>
                  <p className="mt-2 hidden peer-invalid:block text-red-500 text-sm">
                    {" "}
                    invalid phone number.
                  </p>
                </div>

                {otpSent && (
                  <div className="flex flex-col space-y-1 my-2">
                    <label htmlFor="otp" className="text-sm  text-gray-400">
                      OTP
                    </label>
                    <input
                      type="password"
                      name="otp"
                      // min={000000}
                      // max={999999}
                      onChange={handleInputChange}
                      autoFocus
                      value={data.otp}
                      className="px-4 py-2 transition duration-300 border text-gray-400 border-gray-300 rounded  focus:outline-none peer  focus:ring-gray-300"
                    />
                    <p className="mt-2 hidden peer-invalid:block text-red-500 text-sm">
                      {" "}
                      invalid otp.
                    </p>
                  </div>
                )}

                {/* {isLoading && <FullScreenSpinner />} */}
                <PrimaryButton
                  type="submit"
                  disable={validate()}
                  text={otpSent ? "Submit" : "Get OTP"}
                  bg="bg-red-500"
                />
              </form>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Login;
