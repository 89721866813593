import React, { useEffect, useState } from "react";
import { FaUserAlt } from "react-icons/fa";
// import Education from '../components/profile/Education';
// import Professional from '../components/profile/Professional';
// import Welfare from '../components/profile/Welfare';
import { Link, useNavigate } from "react-router-dom";
import { API, formateDate } from "../shared/utility";
import Spinner from "../components/Spinner";
import { errorToast } from "../shared/Service";

const Profile = () => {
  const [selectedSection, setSelectedSection] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [personalDetailsData, setPersonalDetailsData] = useState({
    name: "",
    email: "",
    maritalStatus: "",
    gender: "",
    callingPhoneNo: "",
    whatsappPhoneNo: "",
    country: "",
    state: "",
    city: "",
    address: "",
    dob: "",
  });
  const [educationalDetailsData, setEducationalDetailsData] = useState({
    exInstitute: "",
    exCourse: "",
    exDuration: "",
    exAdmissionNumber: "",
    currentInstitute: "",
    currentCourse: "",
  });
  const [professionalDetailsData, setProfessionalDetailsData] = useState({
    employementStatus: "",
    jobType: "",
    department: "",
    workDomain: "",
    workSector: "",
    designation: "",
    organizationType: "",
    workCountry: "",
    workState: "",
    workCity: "",
    totalExperience: "",
  });
  const [sewaDetailsData, setSewaDetailsData] = useState({
    skills: "",
    isPakkiSamitiMember: "",
    resumeLink: "",
    pakkiSamiti: "",
    suggestion: "",
    otherInformation: "",
  });

  const navigate = useNavigate();
  useEffect(() => {
    GetPersonalDetail();
  }, [0]);

  const GetPersonalDetail = async () => {
    setIsLoading(true);
    API.GET_PERSONAL_DETAIL()
      .then((res) => {
        if (res.status === 200) {
          const {
            maritalStatus,
            gender,
            callingPhoneNo,
            whatsappPhoneNo,
            country,
            state,
            city,
            address,
            dob,
          } = res.data.data;

          const { name, email } = res.data.data.User;

          const personalDetails = {
            name: name,
            email: email,
            maritalStatus: maritalStatus,
            gender: gender,
            callingPhoneNo: callingPhoneNo,
            whatsappPhoneNo: whatsappPhoneNo,
            country: country,
            state: state,
            city: city,
            address: address,
            dob: dob,
          };
          setPersonalDetailsData(personalDetails);
          GetEducationalDetail();

          setIsLoading(false);
        } else {
        }
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.request.status === 400) {
          navigate("/");
          errorToast("Please Fill Details First");
        } else {
          errorToast(err.response.data.message);
        }
      });
  };
  const GetEducationalDetail = async () => {
    setIsLoading(true);
    API.GET_EDUCATION_DETAIL()
      .then((res) => {
        if (res.status === 200) {
          const {
            exInstitute,
            exCourse,
            exDuration,
            exAdmissionNumber,
            currentInstitute,
            currentCourse,
          } = res.data.data;

          const resData = {
            exInstitute: exInstitute,
            exCourse: exCourse,
            exDuration: exDuration,
            exAdmissionNumber: exAdmissionNumber,
            currentInstitute: currentInstitute,
            currentCourse: currentCourse,
          };

          setEducationalDetailsData(resData);
          GetProfessionalDetail();
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.request.status === 400) {
          navigate("/");
          errorToast("Please Fill Details First");
        } else {
          errorToast(err.response.data.message);
        }
      });
  };
  const GetProfessionalDetail = async () => {
    setIsLoading(true);
    API.GET_PROFESSION_DETAIL()
      .then((res) => {
        if (res.status == 200) {
          const {
            department,
            designation,
            employementStatus,
            jobType,
            organizationType,
            totalExperience,
            workCity,
            workCountry,
            workDomain,
            workSector,
            workState,
          } = res.data.data;

          const resData = {
            employementStatus: employementStatus,
            jobType: jobType,
            workSector: workSector,
            workDomain: workDomain,
            designation: designation,
            department: department,
            organizationType: organizationType,
            workCity: workCity,
            workState: workState,
            workCountry: workCountry,
            totalExperience: totalExperience,
          };

          setProfessionalDetailsData(resData);
          GetSewaDetails();
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.request.status === 400) {
          navigate("/");
          errorToast("Please Fill Details First");
        } else {
          errorToast(err.response.data.message);
        }
      });
  };
  const GetSewaDetails = async () => {
    setIsLoading(true);
    API.GET_WELFARE_DETAIL()
      .then((res) => {
        if (res.status == 200) {
          const {
            isPakkiSamitiMember,
            otherInformation,
            pakkiSamiti,
            resumeLink,
            skills,
            suggestion,
          } = res.data.data;

          const resData = {
            skills: skills,
            isPakkiSamitiMember: isPakkiSamitiMember,
            pakkiSamiti: pakkiSamiti,
            otherInformation: otherInformation,
            resumeLink: resumeLink,
            suggestion: suggestion,
          };

          setSewaDetailsData(resData);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.request.status === 400) {
          navigate("/");
          errorToast("Please Fill Details First");
        } else {
          errorToast(err.response.data.message);
        }
      });
  };
  const getSelectedTab = () => {
    switch (selectedSection) {
      case 0:
        return <Education educationalDetailsData={educationalDetailsData} />;
      case 1:
        return (
          <Professional professionalDetailsData={professionalDetailsData} />
        );
      case 2:
        return <Welfare sewaDetailsData={sewaDetailsData} />;

      default:
        return <Education educationalDetailsData={educationalDetailsData} />;
    }
  };

  const sectionSelectorStyle =
    "px-2 sm:px-3 md:px-6 pb-black text-sm whitespace-nowrap sm:text-lg md:text-xl border-black hover:border-black   cursor-pointer";
  return (
    <>
      {isLoading ? (
        <div className="w-full h-[100vh] flex items-center justify-center  bg-white  rounded-lg">
          <Spinner />
        </div>
      ) : (
        <div className=" h-full w-full bg-red-100 py-10 rounded-lg">
          <div className="w-[90%] md:max-w-6xl m-auto">
            <div className="flex items-center justify-between mb-6">
              <h1 className="text-2xl w-fit">User Profile</h1>
              <button
                className={`mx-1 bg-red-500 rounded-md p-1 px-3 text-white text-md font-normal cursor-pointer`}
              >
                <Link to="/">View Details</Link>
              </button>
            </div>
            <div className="flex bg-white  mx-auto shadow-lg rounded-lg ">
              <div className="flex flex-col w-full">
                <div className="p-4 md:px-10 md:py-4 border-b-2 border-gray-200">
                  <div className="text-lg text-gray-700 mb-3">User Details</div>
                  <div className="md:flex items-center justify-between ">
                    <div className="flex items-center">
                      <span className="text-gray-400 text-md">Name:</span>
                      <span className="text-md text-gray-700 ml-1">
                        {personalDetailsData.name}
                      </span>
                    </div>
                    <div className="flex items-center">
                      <span className="text-gray-400 text-md">
                        Date of birth:
                      </span>
                      <span className="text-md text-gray-700 ml-1">
                        {formateDate(personalDetailsData.dob)}
                      </span>
                    </div>
                    <div className="flex items-center">
                      <span className="text-gray-400 text-md">Gender:</span>
                      <span className="text-md text-gray-700 ml-1">
                        {personalDetailsData.gender}
                      </span>
                    </div>
                    <div className="flex items-center">
                      <span className="text-gray-400 text-md">
                        Marital Status:
                      </span>
                      <span className="text-md text-gray-700 ml-1">
                        {personalDetailsData.maritalStatus}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="p-4 md:px-10 md:py-4 border-b-2 border-gray-200">
                  <div className="text-lg text-gray-700 mb-3">
                    Contact Details
                  </div>
                  <div className="md:flex items-center justify-between ">
                    <div className="flex my-1 items-center">
                      <span className="text-gray-400 text-md">
                        Phone (Calling):
                      </span>
                      <span className="text-md text-gray-700 ml-1">
                        {personalDetailsData.callingPhoneNo}
                      </span>
                    </div>
                    <div className="flex my-1 items-center">
                      <span className="text-gray-400 text-md">
                        Phone Number:
                      </span>
                      <span className="text-md text-gray-700 ml-1">
                        {personalDetailsData.whatsappPhoneNo}
                      </span>
                    </div>
                    <div className="flex my-1 items-center justify-between">
                      <div className="flex items-center">
                        <span className="text-gray-400 text-md">
                          Email Address:
                        </span>
                        <span className="text-md text-gray-700 ml-1">
                          {personalDetailsData.email}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="p-4 md:px-10 md:py-4">
                  <div className="text-lg text-gray-700 mb-2">
                    Address Details
                  </div>
                  <div className="md:flex items-center justify-between">
                    <div className="flex items-center">
                      <span className="text-gray-400 text-md">Address:</span>
                      <span className="text-md text-gray-700 ml-1">
                        {personalDetailsData.address}
                      </span>
                    </div>
                    <div className="flex items-center">
                      <span className="text-gray-400 text-md">City:</span>
                      <span className="text-md text-gray-700 ml-1">
                        {personalDetailsData.city}
                      </span>
                    </div>
                    <div className="flex items-center">
                      <span className="text-gray-400 text-md">State:</span>
                      <span className="text-md text-gray-700 ml-1">
                        {personalDetailsData.state}
                      </span>
                    </div>
                    <div className="flex items-center">
                      <span className="text-gray-400 text-md">Country:</span>
                      <span className="text-md text-gray-700 ml-1">
                        {personalDetailsData.country}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div className="flex relative h-[50px] mt-16 mb-10">
                <div
                  className={
                    selectedSection === 0
                      ? `${sectionSelectorStyle} pl-0 sm:pl-2 border-b-4 border-blue-500`
                      : `${sectionSelectorStyle} pl-0 sm:pl-2 border-b-0`
                  }
                  onClick={() => setSelectedSection(0)}
                >
                  Education
                </div>
                <div
                  className={
                    selectedSection === 1
                      ? `${sectionSelectorStyle} border-b-4 border-blue-500`
                      : `${sectionSelectorStyle} border-b-0`
                  }
                  onClick={() => setSelectedSection(1)}
                >
                  Professional
                </div>
                <div
                  className={
                    selectedSection === 2
                      ? `${sectionSelectorStyle} border-b-4 border-blue-500`
                      : `${sectionSelectorStyle} border-b-0`
                  }
                  onClick={() => setSelectedSection(2)}
                >
                  Welfare
                </div>
                <div className="border-b border-blue-500 mb-4 absolute bottom-[-15.4px] w-full"></div>
              </div>

              {getSelectedTab()}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const Education = ({ educationalDetailsData }) => {
  return (
    <div className={`flex bg-white  mx-auto shadow-lg rounded-lg `}>
      <div className="flex flex-col w-full">
        <div className="p-4 md:px-10 md:py-4 border-b-2 border-gray-200">
          <div className="text-xl text-gray-700 font-medium mb-2">
            Ex Student Details
          </div>
          <div className="flex items-center justify-between mb-2">
            <div className="flex items-center">
              <span className="text-gray-400 text-md font-medium">
                Name of the institute:
              </span>
              <span className="text-md text-gray-700 ml-1">
                {educationalDetailsData.exInstitute}
              </span>
            </div>
          </div>
          <div className="flex items-center justify-between mb-2">
            <div className="flex items-center">
              <span className="text-gray-400 text-md font-medium">
                Course you were enrolled in
              </span>
              <span className="text-md text-gray-700 ml-1">
                {educationalDetailsData.exCourse}
              </span>
            </div>
          </div>
          <div className="flex items-center justify-between mb-2">
            <div className="flex items-center">
              <span className="text-gray-400 text-md font-medium">
                Duration during which you studied at a institute
              </span>
              <span className="text-md text-gray-700 ml-1">
                {educationalDetailsData.exDuration}
              </span>
            </div>
          </div>
          <div className="flex items-center justify-between mb-2">
            <div className="flex items-center">
              <span className="text-gray-400 text-md font-medium">
                Admission number
              </span>
              <span className="text-md text-gray-700 ml-1">
                {educationalDetailsData.exAdmissionNumber}
              </span>
            </div>
          </div>
        </div>
        {/* <div className="p-2 md:px-10 md:py-4 border-b-2 border-gray-200">
          <div className="text-xl text-gray-700 font-medium mb-2">
            Qualifiacation Details
          </div>
          <div className="flex items-center justify-between mb-2">
            <div className="flex items-center">
              <span className="text-gray-400 text-md font-medium">
                Qualifiacations Completed
              </span>
              <span className="text-md text-gray-700 ml-1">
                Secondry Education
              </span>
            </div>
          </div>
          <div className="flex items-center justify-between mb-2">
            <div className="flex items-center">
              <span className="text-gray-400 text-md font-medium">
                Institute where you completed your most recent qualification
                from
              </span>
              <span className="text-md text-gray-700 ml-1">
                Shah Satnam Ji Boys School
              </span>
            </div>
          </div>
        </div> */}
        <div className="p-4 md:px-10 md:py-4 border-b-2 border-gray-200">
          <div className="text-xl text-gray-700 font-medium mb-2">
            Ongoing Study/Qualifiacation Details
          </div>
          <div className="flex items-center justify-between mb-2">
            <div className="flex items-center">
              <span className="text-gray-400 text-md font-medium">
                Currently enrolled degree / diploma
              </span>
              <span className="text-md text-gray-700 ml-1">
                {educationalDetailsData.currentCourse}
              </span>
            </div>
          </div>
          {/* <div className="flex items-center justify-between mb-2">
            <div className="flex items-center">
              <span className="text-gray-400 text-md font-medium">
                Area of study
              </span>
              <span className="text-md text-gray-700 ml-1">
                Computer science & engineering
              </span>
            </div>
          </div> */}
          <div className="flex items-center justify-between mb-2">
            <div className="flex items-center">
              <span className="text-gray-400 text-md font-medium">
                Institution/University currently enrolled at
              </span>
              <span className="text-md text-gray-700 ml-1">
                {educationalDetailsData.currentInstitute}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Professional = ({ professionalDetailsData }) => {
  return (
    <div className={`flex bg-white  mx-auto shadow-lg rounded-lg `}>
      <div className="flex flex-col w-full">
        <div className="p-4 md:px-10 md:py-4 border-b-2 border-gray-200">
          <div className="text-xl text-gray-700 font-medium mb-3">
            Current Position Details
          </div>
          <div className="flex items-center justify-between mb-3">
            <div className="flex items-center">
              <span className="text-gray-400 text-md font-medium">
                Current position
              </span>
              <span className="text-md text-gray-700 ml-3">
                {professionalDetailsData.employementStatus}
              </span>
            </div>
          </div>
          <div className="flex items-center justify-between mb-3">
            <div className="flex items-center">
              <span className="text-gray-400 text-md font-medium">
                Job type
              </span>
              <span className="text-md text-gray-700 ml-3">
                {professionalDetailsData.jobType}
              </span>
            </div>
          </div>
          <div className="flex items-center justify-between mb-3">
            <div className="flex items-center">
              <span className="text-gray-400 text-md font-medium">
                Job sector
              </span>
              <span className="text-md text-gray-700 ml-3">
                {professionalDetailsData.workSector}
              </span>
            </div>
          </div>
          <div className="flex items-center justify-between mb-3">
            <div className="flex items-center">
              <span className="text-gray-400 text-md font-medium">
                Job domain
              </span>
              <span className="text-md text-gray-700 ml-3">
                {professionalDetailsData.workDomain}
              </span>
            </div>
          </div>
          <div className="flex items-center justify-between mb-3">
            <div className="flex items-center">
              <span className="text-gray-400 text-md font-medium">
                Designation
              </span>
              <span className="text-md text-gray-700 ml-3">
                {professionalDetailsData.designation}
              </span>
            </div>
          </div>
          <div className="flex items-center justify-between mb-3">
            <div className="flex items-center">
              <span className="text-gray-400 text-md font-medium">
                Department
              </span>
              <span className="text-md text-gray-700 ml-3">
                {professionalDetailsData.department}
              </span>
            </div>
          </div>
          <div className="flex items-center justify-between mb-3">
            <div className="flex items-center">
              <span className="text-gray-400 text-md font-medium">
                Company/Firm/Organization
              </span>
              <span className="text-md text-gray-700 ml-3">
                {professionalDetailsData.organizationType}
              </span>
            </div>
          </div>
          <div className="flex items-center justify-between mb-3">
            <div className="flex items-center">
              <span className="text-gray-400 text-md font-medium">
                Working in (city)
              </span>
              <span className="text-md text-gray-700 ml-3">
                {professionalDetailsData.workCity}
              </span>
            </div>
          </div>
          <div className="flex items-center justify-between mb-3">
            <div className="flex items-center">
              <span className="text-gray-400 text-md font-medium">
                Working in (state)
              </span>
              <span className="text-md text-gray-700 ml-3">
                {professionalDetailsData.workState}
              </span>
            </div>
          </div>
          <div className="flex items-center justify-between mb-3">
            <div className="flex items-center">
              <span className="text-gray-400 text-md font-medium">
                Working in (country)
              </span>
              <span className="text-md text-gray-700 ml-3">
                {professionalDetailsData.workCountry}
              </span>
            </div>
          </div>
          <div className="flex items-center justify-between mb-3">
            <div className="flex items-center">
              <span className="text-gray-400 text-md font-medium">
                Experience
              </span>
              <span className="text-md text-gray-700 ml-3">
                {professionalDetailsData.totalExperience}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const Welfare = ({ sewaDetailsData }) => {
  return (
    <div className={`flex bg-white  mx-auto shadow-lg rounded-lg `}>
      <div className="flex flex-col w-full">
        <div className="p-4 md:px-10 md:py-4 border-b-2 border-gray-200">
          <div className="text-xl text-gray-700 font-medium mb-3">
            Sewa Details
          </div>
          <div className="flex items-center justify-between mb-3">
            <div className="flex items-center">
              <span className="text-gray-400 text-md font-medium">Skills</span>
              <span className="text-md text-gray-700 ml-3">
                {sewaDetailsData.skills}
              </span>
            </div>
          </div>
          <div className="flex items-center justify-between mb-3">
            <div className="flex items-center">
              <span className="text-gray-400 text-md font-medium">
                Pakki sammitti
              </span>
              <span className="text-lg text-gray-700 ml-3">
                {sewaDetailsData.isPakkiSamitiMember
                  ? sewaDetailsData.pakkiSamiti
                  : "No"}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
